/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Delivery Slip Layer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import { executeGetData, executePostData, executePostImage, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";

export const getReceiptCountByprojectId= async (projectId:any, searchParams:any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/receipt/getReceiptCount/projectId/${projectId}/searchParams/${searchParams}`;
    return await executeGetData(url, accessToken);
};

export const getReceiptByprojectId= async (projectId:any, searchParams:any, page:any, size:any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/receipt/getReceiptList/projectId/${projectId}/searchParams/${searchParams}?page=${page}&size=${size}`;
    return await executeGetData(url, accessToken);
};

export const getReceipt= async (projectId:any, receiptId:any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/receipt/getReceipts/projectId/${projectId}/receiptId/${receiptId}`;
    return await executeGetData(url, accessToken);
};

export const uploadReceipt = async (projectId:any, purchaseId:any, payload:any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/receipt/receiptupload/projectId/${projectId}/purchaseId/${purchaseId}`;
    return await executePostImage(url, payload, accessToken);
}

export const uploadBill = async (orderno:any, payload:any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/receipt/billupload/orderno/${orderno}`;
    return await executePostImage(url, payload, accessToken);
}
export const confirmDeliveyBill = async (receiptId:any, payload:any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/receipt/deliveryConfirm/receiptId/${receiptId}`;
    return await executePostData(url, payload, accessToken);
}