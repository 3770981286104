// * Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
// * -----------------------------------------------------------------------------
//  1764        |  2/5/24    |   Nandha      | Create assset No of area and stories TextField value Zero or negative not accepted to create asset
//  2007        | 22/5/24    |   Vanitha      | Addnew properety  implementation for redirect the blank page  
//  2008        | 22/5/24    |   Vanitha      | checkbox implementation for update

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Alert, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Snackbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { GoogleMap, useJsApiLoader, MarkerF, StandaloneSearchBox } from '@react-google-maps/api';
import { Stack } from '@mui/system';
import './Asset.scss'
import { editAsset, saveAsset } from '../../services/AssetService';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useNavigate } from 'react-router';
import { tokens } from '../../hooks/theme';
import { useLocation } from 'react-router-dom';
import { editAssetDocument, saveAssetDocument } from '../../services/AssetDocumentService';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import "../createbid/selectorComponent/TaskSelector.scss";
import { getUserSession, useHandleUnauthorized } from '../../hooks/UserSession';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { getpersonalInfo } from '../../services/EmployeeService';
import QuestionRenderer from '../../pages/QuestionRenderer/QuestionRenderer';

const containerStyle = {
    width: '100%',
    height: '300px'
};
const libraries: any = ['places'];

const CreateAsset: React.FC = () => {
    let location = useLocation()
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBHfzWHT7qgElExVxQzVRhCJtW1Z6YRILw",
        libraries: libraries
    })

    const [map, setMap] = React.useState(null);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [role, setRole] = React.useState<any>([] as any[]);
    const [open, setOpen] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [showBillingAddress, setShowBillingAddress] = React.useState(true);
    const [assetTemplatedata, setAssetTemplateData] = React.useState();
    const { handleUnauthorized } = useHandleUnauthorized();
    const [message, setMessage] = React.useState<any>({
        name: '',
        country: '',
        zipCode: '',
        state: '',
        address: '',
        city: '',
        stories: null,
        area: '',
        checkbox: false,
    });
    const [show, setShow] = React.useState(false);
    const { action, customerUid } = location.state || {};
    // //console.log("action", action);
    // //console.log("selected", customerUid);
    const [assetData, setAssetData] = React.useState<any>({
        address: "",
        name: "",
        country: "",
        zipCode: '',
        state: "",
        city: "",
        location: "",
        assetType: "Residential",
        stories: 0,
        status: "Active",
        customerUid: accessTokenPayload?.oid,
        area: 0,
        checkbox: false,
        createdAt: new Date(),
        updatedAt: new Date(),
        isBilling: false,
        assetTemplateData: ''
    });

    const componentData = React.useRef<any>()
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const colors = tokens(theme.palette.mode);
    let navigate = useNavigate()
    const [selectedFile, setSelectedFile] = React.useState<File | string>();
    const [image, setImage] = React.useState<any>(null);
    const [toastMessage, setToastMessage] = React.useState('');
    const assetDataObject = location.state?.assetObjectData;
    const inputRef = React.useRef<any>();
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [noChange, setNoChange] = React.useState(false);
    const [isBillingAddress, setIsBillingAdress] = React.useState(false);
    const [hasData, setHasData] = React.useState(false);
    const questionRendererRef = React.useRef<any>(null);
    const [odmData, setOdmData] = React.useState<any>(null);

    const [isUpdateBillingAddress, setIsUpdateBillingAdress] = React.useState(false);
    const [update, setUpdate] = React.useState(false);

    const [assetIds, setAssetIds] = React.useState<any>({});
    const [completed, setCompleted] = React.useState<any>(false);

    const assetType = "Billing Address"
    const [successType, setSuccessType] = React.useState('');
    const convertLocation = (locationString: any) => {
        let locationObject: any = {}
        let string = locationString;
        let st = string.split(",");
        locationObject['lat'] = Number(st[0]);
        locationObject['lng'] = Number(st[1]);
        return locationObject;
    }

    const onLoad = React.useCallback(function callback(map: any) {
        const bounds = new window.google.maps.LatLngBounds(componentData.current);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    // const onClick = (e: google.maps.MapMouseEvent) => {
    //     componentData.current = { lat: e.latLng?.lat(), lng: e.latLng?.lng() }
    //     let locationObject: any = {}
    //     const geocoder = new google.maps.Geocoder();
    //     const fulladdress: any[] = [];
    //     geocoder
    //         .geocode({ location: componentData.current })
    //         .then((response) => {
    //             if (response.results[0]) {
    //                 //console.log(response.results[0]);
    //                 // eslint-disable-next-line array-callback-return
    //                 response.results[0].address_components.map((address: any) => {
    // if (address.types.includes("street_number") || address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route") ||address.types.includes("plus_code") || address.types.includes("landmark") || address.types.includes("premise")) {
    //     fulladdress.push(address.long_name);
    //     // setLocation({...location,address:fulladdress.join(",")});
    //     // Object.assign(location,{address:fulladdress.join(",")});
    //     locationObject['address'] = fulladdress.join(",");
    // }
    //                     else if (address.types.includes("locality")) {
    //                         // setLocation({...location,city:address.long_name});
    //                         locationObject['city'] = address.long_name;
    //                     }
    //                     else if (address.types.includes("administrative_area_level_1")) {
    //                         // setLocation({...location,state:address.long_name});
    //                         locationObject['state'] = address.long_name
    //                     }
    //                     else if (address.types.includes("country")) {
    //                         // setLocation({...location,country:address.long_name});
    //                         locationObject['country'] = address.long_name
    //                     }
    //                     else if (address.types.includes("postal_code")) {
    //                         // setLocation({...location,zipCode:address.long_name});
    //                         locationObject['zipCode'] = address.long_name
    //                     }
    //                 })
    //             }
    // setAssetData({ ...assetData, zipCode: locationObject.zipCode, country: locationObject.country, address: locationObject.address, city: locationObject.city, state: locationObject.state, location: Object.values(componentData.current).join(",") });
    //             // Object.assign(location,locationObject);
    //             // setLocation(locationObject);
    //         })
    // }

    const onClick = (e: google.maps.MapMouseEvent) => {
        componentData.current = { lat: e.latLng?.lat(), lng: e.latLng?.lng() };
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: componentData.current })
            .then((response) => {
                if (response.results[0]) {
                    const locationObject: any = {};
                    response.results[0].address_components.forEach((component) => {
                        const { long_name, types } = component;
                        if (types.includes("street_number") || types.includes("sublocality_level_1") || types.includes("sublocality_level_2") || types.includes("route") || types.includes("plus_code") || types.includes("landmark") || types.includes("premise")) {
                            locationObject.address = long_name + (locationObject.address ? ', ' + locationObject.address : '');
                        }
                        else if (types.includes("locality")) {
                            locationObject.city = long_name;
                        } else if (types.includes("administrative_area_level_1")) {
                            locationObject.state = long_name;
                        } else if (types.includes("country")) {
                            locationObject.country = long_name;
                        } else if (types.includes("postal_code")) {
                            locationObject.zipCode = long_name;
                        }
                    });
                    locationObject.location = Object.values(componentData.current).join(",");
                    setAssetData({ ...assetData, ...locationObject });
                }
            });
    }
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                componentData.current = { lat: position.coords.latitude, lng: position.coords.longitude };
            });
        } else {
            // //console.log("Geolocation is not supported by this browser.");
        }
    }
    // const showPosition = (position: any) => {
    //     //console.log(componentData.current);
    //     componentData.current = { lat: position.coords.latitude, lng: position.coords.longitude };
    //     //console.log(componentData.current);
    //     //console.log({ lat: position.coords.latitude, lng: position.coords.longitude });
    //     // assetData['location'] = componentData.current;
    //     // setAssetData({ ...assetData,  location: componentData.current });
    // }

    // React.useEffect(() => {
    //     //getLocation();
    //     getUserSession(accessToken)
    //         .then(res => {

    //             setRole(res.role.name);
    //             //  //console.log(res);
    //         })
    //     const assetDataObject = location.state?.assetObjectData;
    //     if(assetDataObject.assetType==='Billing Address' || assetDataObject.assetType==='Site Address'){
    //         setAssetData({
    //             ...assetData,
    //             assetType: assetDataObject.assetType
    //         });
    //     }
    //     //console.log(assetDataObject)
    //     if (assetDataObject !== undefined) {
    //         setAssetData(assetDataObject);
    //         componentData.current = convertLocation(assetDataObject.location);
    //     }

    // }, []);
    React.useEffect(() => {
        getUserSession(accessToken).then(res => {
            setRole(res.role.name);
            getpersonalInfo(res.email, accessToken).then(response => {
                //console.log(response);
                handleUnauthorized(response)
                if (response.status === 200 || response.status === 201) {
                    if (response.data === true) {
                        setHasData(true)
                    }
                }
            })

        });
        // callParentComponent();
        const assetDataObject = location.state?.assetObjectData;
        // console.log(JSON.parse(assetDataObject?.assetTemplateData));

        if (assetDataObject) {
            if (assetDataObject?.isBilling === true) {
                setShowBillingAddress(false);
            }
            setAssetData(assetDataObject);
            componentData.current = convertLocation(assetDataObject.location);
        }
        if (assetData.isBilling === true) {
            if (!update) {
                handleSubmit();

            }
            else {
                updateHandleSubmit(assetIds.assetId);
            }
        }
    }, [accessToken, location.state, assetData.isBilling])

    const inputchange = (e: any) => {
        if (e.target.name === "stories") {
            const enteredValue = parseFloat(e.target.value);
            if (enteredValue >= 0) {
                setAssetData({
                    ...assetData,
                    [e.target.name]: enteredValue

                });
            }
        }
        // else if(e.target.name === "area"){
        //     setAssetData({
        //         ...assetData,
        //         [e.target.name]: parseFloat(e.target.value)

        //     });
        // }

        else if (e.target.name === "area") {
            const enteredValue = parseFloat(e.target.value);
            // Check if the entered value is a valid non-negative number
            if (enteredValue >= 0 && enteredValue <= 1000) {
                // If it's valid, update the state
                setAssetData({
                    ...assetData,
                    [e.target.name]: enteredValue
                });
            }
        }
        else if (e.target.name === "checkbox") {

            setAssetData({
                ...assetData,
                [e.target.name]: e.target.checked


            });
        }

        else {
            setAssetData({
                ...assetData,
                [e.target.name]: e.target.value

            });
        }
    };



    const handleClick = (message: any) => {
        setOpen(true);
        setToastMessage(message);
    };


    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };



    const validation = () => {
        let validated: any = true;
        //console.log(assetData);
        if (assetData.name === '') {
            // setMessage({...message,propertyName:'Enter Property Name'});
            message.propertyName = 'Enter Property Name';
            validated = false;

        }
        if (assetData.state === '') {
            // setMessage({...message,state:'Enter Your State'});
            message.state = 'Enter Your State';
            validated = false;

        }
        if (assetData.city === '') {
            // setMessage({...message,city:'Enter Your City'});
            message.city = 'Enter Your City';
            validated = false;

        }
        if (assetData.country === '') {
            //setMessage({...message,country:'Enter Your Country'});
            message.country = 'Enter Your Country';
            validated = false;

        }
        if (assetData.stories === null || assetData.stories === 0) {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.stories = 'Enter Your Stories';
            validated = false;

        }
        if (assetData.zipCode === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.zipCode = 'Enter Your ZipCode';
            validated = false;

        }
        if (assetData.address === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.address = 'Enter Your address';
            validated = false;

        }
        if (assetData.area === null || assetData.area === 0) {
            message.area = 'Enter Your area valid (e.g 0 to 1000 sq)';
            validated = false;

        }
        // if (assetData.checkbox === false) {
        //     message.checkbox = 'Click the Yes Checkbox';
        //     validated = false;

        // }
        return validated;

    }
    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setSuccess(false);
        setError(false);
        setNoChange(false)
        onNextPage();
    }
    const handleCancelBillingAddress = (event?: React.SyntheticEvent | Event, reason?: string) => {

        if (assetData.isBilling === null) {
            setAssetData(prevState => ({ ...prevState, isBilling: false }));
        }
        else {
            setAssetData(prevState => ({ ...prevState, isBilling: false }));

        }
        setIsBillingAdress(false);
        handleSubmit();

    }
    const handleBillingAddress = (event?: React.SyntheticEvent | Event, reason?: string) => {
        //console.log(assetData)
        setUpdate(false);
        if (assetData.isBilling === null) {
            setAssetData(prevState => ({ ...prevState, isBilling: true }));
        }
        else if (assetData.isBilling === false) {
            setAssetData(prevState => ({ ...prevState, isBilling: true }));

        }
        else {
            setAssetData(prevState => ({ ...prevState, isBilling: true }));

        }
        setIsBillingAdress(false);
        // Use a callback to handleSubmit to ensure it runs after state updates
        // setTimeout(() => {
        //     handleSubmit();
        // }, 0);

    }

    const handleCancelUpdateBillingAddress = (event?: React.SyntheticEvent | Event, reason?: string) => {
        // setAssetData(prevState => Object.assign({}, prevState, { isBilling: false }));
        // const updatedAssetData = {
        //     ...assetData,
        //     isBilling: true
        // };

        // // Update the state
        // setAssetData(updatedAssetData);
        if (assetData.isBilling === null) {
            setAssetData(prevState => ({ ...prevState, isBilling: false }));
        }
        else {
            setAssetData(prevState => ({ ...prevState, isBilling: false }));

        }
        setIsBillingAdress(false);
        updateHandleSubmit(assetIds.assetId);

    }
    const handleUpdateBillingAddress = (event?: React.SyntheticEvent | Event, reason?: string) => {
        Object.assign(assetData, { isBilling: true });
        setUpdate(true);

        if (assetData.isBilling === null) {
            setAssetData(prevState => ({ ...prevState, isBilling: true }));
        }
        else {
            setAssetData(prevState => ({ ...prevState, isBilling: true }));

        }
        // Update the state
        // setAssetData(updatedAssetData);
        setIsBillingAdress(false);
        // Use a callback to handleSubmit to ensure it runs after state updates
        // setTimeout(async () => {
        //     updateHandleSubmit(assetIds.assetId);
        // }, 0);

    }
    const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

        setNoChange(false)

    }
    const handleSave = () => {
        //console.log(hasData,role==="customer")

        if (role === "customer") {
            if (hasData) {
                handleSubmit();

            } else {
                setIsBillingAdress(true);

            }

        }
        else {
            handleSubmit();

        }

    }
    const handleUpdate = (assetId: any) => {
        if (role === 'customer') {
            //console.log(hasData)
            if (hasData) {
                updateHandleSubmit(assetId);
            }
            else {
                //console.log(assetDataObject, assetData)
                if (
                    assetDataObject.address !== assetData.address ||
                    assetDataObject.country !== assetData.country ||
                    assetDataObject.zipCode !== assetData.zipCode ||
                    assetDataObject.state !== assetData.state ||
                    assetDataObject.city !== assetData.city ||
                    assetDataObject.isBilling !== assetData.isBilling
                ) {
                    setIsBillingAdress(true);
                    setAssetIds(prevState => Object.assign({}, prevState, { assetId: assetId }));
                }
                else {
                    updateHandleSubmit(assetId);
                }
            }
        }
        else {
            updateHandleSubmit(assetId);

        }
    }

    const handleSubmit = async () => {
        //setAssetData({ ...assetData, customerUid: role === "customer" ? accessTokenPayload.oid : customerUid });
        Object.assign(assetData, { customerUid: role === "customer" ? accessTokenPayload.oid : customerUid });
        setCount(count + 1);
        let formData = new FormData();
        formData.append("file", image);
        let validated = validation();
        if (validated === true) {
            let result = await saveAsset(assetData, accessToken);
            let uploadResult = await saveAssetDocument(formData, result.data, accessToken);
            if (result.status === 201) {
                // handleClick('Inserted Successfully');
                setSuccessType('save');
                setSuccess(true);
                // setTimeout(() => {
                //     onNextPage();
                // }, 2000);
            }
            else {
                // handleClick('Not Inserted');

                setError(true);
            }
        }
        else {
            // handleClick('Not Inserted');
        }
    };
    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setShowBillingAddress(!isChecked);
        if (isChecked) {
            Object.assign(assetData, { isBilling: false });
        }
    };
    const updateHandleSubmit = async (assetId: any) => {
        setCount(count + 1);
        let validated = validation();
        let formData = new FormData();
        formData.append("file", image);
        console.log(assetData?.assetTemplateData);

        if (assetDataObject.address === assetData.address &&
            assetDataObject.name === assetData.name &&
            assetDataObject.country === assetData.country &&
            assetDataObject.zipCode === assetData.zipCode &&
            assetDataObject.state === assetData.state &&
            assetDataObject.city === assetData.city &&
            assetDataObject.stories === assetData.stories &&
            assetDataObject.area === assetData.area &&
            assetDataObject.checkbox === assetData.checkbox &&
            assetDataObject.assetType === assetData.assetType &&
            assetDataObject?.isBilling === assetData.isBilling

        ) {
            // handleClick('No valued Changed');
            setNoChange(true);

        }
        else {
            if (validated === true) {
                if (typeof (assetData.location) != "string") {
                    let data = assetData;
                    data.location = Object.values(assetData.location).join(",")
                    setAssetData(data);
                }
                console.log(assetId);
                let result = await editAsset(assetId, assetData, accessToken);
                console.log(result);
                let uploadResult = await editAssetDocument(formData, assetId, accessToken);
                if (result.status === 200 || result.status === 201) {
                    // handleClick('Updated Successfully');
                    setSuccessType('update');
                    setSuccess(true);
                    // setTimeout(() => {
                    //     onNextPage();
                    // }, 2000);
                }
                else {
                    // handleClick('Not Updated');

                    setError(true);
                }
            }

        }
    };
    const onNextPage = () => {
        if (action === "addProperty") {
            navigate("/createbid");
        } else {
            navigate("/assetList");
        }
    };
    // const callParentComponent = () => {
    //     if (questionRendererRef.current && typeof questionRendererRef.current.handleSaveFunctionality === 'function') {
    //         console.log("hii")

    //         questionRendererRef.current.handleSaveFunctionality();
    //         console.log(odmData)
    //     }
    // };
    const Goback = () => {
        navigate("/assetList");
    }
    const getFile = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(URL.createObjectURL(file));
        setImage(file);
    };
    const handleCardClick = () => {
        const fileInput = document.getElementById("image-upload-input") as HTMLInputElement;
        fileInput.click();
    };

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        const fulladdress: any[] = [];
        let locationObject: any = {}
        if (place) {
            place.address_components.map((address: any) => {
                if (address.types.includes("street_number") || address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route") || address.types.includes("landmark") || address.types.includes("premise")) {
                    fulladdress.push(address.long_name);
                    // setLocation({...location,address:fulladdress.join(",")});
                    // Object.assign(location,{address:fulladdress.join(",")});
                    locationObject['address'] = fulladdress.join(",");
                }
                else if (address.types.includes("locality")) {
                    // setLocation({...location,city:address.long_name});
                    locationObject['city'] = address.long_name;
                }
                else if (address.types.includes("administrative_area_level_1")) {
                    // setLocation({...location,state:address.long_name});
                    locationObject['state'] = address.long_name;
                }
                else if (address.types.includes("country")) {
                    // setLocation({...location,country:address.lon;g_name});
                    locationObject['country'] = address.long_name;
                }
                else if (address.types.includes("postal_code")) {
                    // setLocation({...location,zipCode:address.long_name});
                    locationObject['zipCode'] = address.long_name;
                }
                else {
                    locationObject['zipCode'] = '';
                    locationObject['address'] = fulladdress.join(",");

                }
            })

            componentData.current = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
            setAssetData({ ...assetData, zipCode: locationObject.zipCode, country: locationObject.country, address: locationObject.address, city: locationObject.city, checkbox: locationObject.checkbox, state: locationObject.state, location: Object.values(componentData.current).join(",") });
        }
    }

    const isSuccessMessage = toastMessage === 'Inserted Successfully' || toastMessage === 'Updated Successfully';

    const handleSaveFunctionality = async (savedData: any, tradeData: any) => {
        // console.log("handleSaveFunctionality called in parent");
        console.log(savedData);

        if (savedData !== undefined) {
            // setCompleted(false);
            // Update assetData correctly
            setAssetData(prevData => ({
                ...prevData,
                assetTemplateData: savedData
            }));
            Object.assign(assetData, { assetTemplateData: savedData })

            if (assetDataObject !== (null || undefined)) {
                try {
                    let result = await editAsset(assetDataObject.id, assetData, accessToken);
                    if (result.status === 201 || result.status === 200) {
                        setSuccessType('update');
                        setSuccess(true);
                    } else {
                        setError(true);
                    }
                } catch (error) {
                    console.error("Error saving asset:", error);
                    setError(true);
                }
            } else {
                // Perform async operation
                try {
                    console.log(assetData)
                    if (assetData?.assetTemplateData !== undefined) {
                    }
                    let result = await saveAsset(assetData, accessToken); // Ensure saveAsset and accessToken are defined
                    if (result.status === 201) {
                        setSuccessType('save');
                        setSuccess(true);
                    } else {
                        setError(true);
                    }

                    // console.log(result);
                } catch (error) {
                    console.error("Error saving asset:", error);
                    setError(true);
                }
            }
        } else {
            // setCompleted(true);
        }
    };

    const callParentComponent = () => {
        if (questionRendererRef.current && typeof questionRendererRef.current.handleSaveFunctionality === 'function') {
            console.log("Calling handleSaveFunctionality from parent");
            questionRendererRef.current.handleSaveFunctionality();
            //questionRendererRef.current.validate();
        } else {
            console.error("Ref or handleSaveFunctionality is not set correctly");
        }
    };

    return (
        <>
            <Grid container justifyContent="center" alignItems="center" >
                <Grid item xs={12} sm={10}>
                    <Box
                        display={smScreen ? "flex" : "block"}
                        flexDirection={smScreen ? "row" : "column"}
                        alignItems={smScreen ? "center" : "start"}
                        m="1px 0"
                    >
                        <Grid item sm={6}>
                            {assetDataObject ? <Header title="Edit Asset" subtitle={""} /> : <Header title="Create New Asset" subtitle={""} />}
                        </Grid>
                        <Grid item sm={6} sx={{ display: 'flex', justifyContent: 'flex-end', gap: '15px' }}>
                            {/* {assetDataObject ? (
                                <Button variant="contained" className='sucessButton' size="medium" onClick={() => handleUpdate(assetDataObject.id)}>
                                    Update
                                </Button>
                            ) : (
                                <Button className='sucessButton' variant="contained" size="medium" onClick={handleSave}>
                                    Save
                                </Button>
                            )} */}
                            <Button
                                variant="contained"
                                className='primaryTonalButton'
                                size="medium"
                                onClick={Goback}
                                startIcon={<ArrowBackIcon className='personIcon' />}
                            >
                                Go Back to My Assets
                            </Button>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>


            {/* </Stack> */}


            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ paddingTop: 6 }}>
                <Alert onClose={handleClose} severity={isSuccessMessage ? 'success' : 'warning'} sx={{ width: '100%' }} >
                    {toastMessage}
                </Alert>
            </Snackbar>
            <Grid container justifyContent="center" alignItems="center" rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={10}>
                    {/* <Box> */}
                    { /*  <Card className='POCard' variant="outlined" sx={{ p: 3, maxWidth: "1500px" }}>
                            <Grid container spacing={2} columns={13}>
                                <Grid item xs={13} sm={13} md={3} lg={3} xl={3}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>

                                            <Card variant="outlined" className='assetImageUpload'
                                                onClick={handleCardClick}
                                            >
                                                <label>
                                                    {typeof selectedFile === "string" ? (
                                                        <img src={selectedFile} alt="Selected" />
                                                    ) : (
                                                        <Stack sx={{ textAlign: 'center', cursor: "pointer", }}>
                                                            <Typography>
                                                                <AddIcon />
                                                            </Typography>
                                                            <Typography>
                                                                Click to Upload Your Picture
                                                            </Typography>
                                                        </Stack>
                                                    )}
                                                </label>
                                                <input
                                                    type="file"
                                                    id="image-upload-input"
                                                    onChange={(e) => getFile(e)}
                                                    style={{ display: "none" }}
                                                    accept="image/*"
                                                />

                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}> <Typography className='assetSubHeader' sx={{ color: colors.greenAccent[1100] }}>Enter Your Asset's Information</Typography></Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(assetData.name === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="Property Name"
                                                name="name"
                                                size="small"
                                                fullWidth
                                                value={assetData.name}
                                                // value={assetDataObject ? assetDataObject.propertyName : assetData.propertyName}
                                                required
                                                helperText={(assetData.propertyName === '' && count !== 0) ? message.propertyName : null}
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            {isLoaded
                                                &&
                                                <StandaloneSearchBox
                                                    onLoad={ref => inputRef.current = ref}
                                                    onPlacesChanged={handlePlaceChanged}
                                                >
                                                    <TextField
                                                        error={(assetData.address === '' && count !== 0)}
                                                        id="Street address"
                                                        label="Street address"
                                                        name="address"
                                                        size="small"
                                                        fullWidth
                                                        value={assetData.address}
                                                        // value={assetDataObject ? assetDataObject.address : assetData.address}
                                                        required
                                                        helperText={(assetData.address === '' && count !== 0) ? message.address : null}
                                                        // helperText={message.address}
                                                        onChange={(e) => inputchange(e)}
                                                    />
                                                </StandaloneSearchBox>}
                                        </Grid>
                                        <Grid item xs={6}>

                                            <TextField
                                                error={(assetData.city === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="City"
                                                size="small"
                                                fullWidth
                                                required
                                                name="city"
                                                value={assetData.city}
                                                // value={assetDataObject ? assetDataObject.city : assetData.city}
                                                helperText={(assetData.city === '' && count !== 0) ? message.city : null}
                                                onChange={(e) => inputchange(e)}

                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(assetData.state === '' && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="State"
                                                size="small"
                                                fullWidth
                                                name="state"
                                                required
                                                value={assetData.state}
                                                // value={assetDataObject ? assetDataObject.state : assetData.state}
                                                onChange={(e) => inputchange(e)}
                                                helperText={(assetData.state === '' && count !== 0) ? message.state : null}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(assetData.country === '' && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Country"
                                                size="small"
                                                fullWidth
                                                value={assetData.country}
                                                // value={assetDataObject ? assetDataObject.country : assetData.country}
                                                helperText={(assetData.country === '' && count !== 0) ? message.country : null}
                                                name="country"
                                                required
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(assetData.zipCode === '' && count !== 0)}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Zip Code"
                                                size="small"
                                                name="zipCode"
                                                fullWidth
                                                value={assetData.zipCode}
                                                // value={assetDataObject ? assetDataObject.zipCode : assetData.zipCode}
                                                required
                                                helperText={(assetData.zipCode === '' && count !== 0) ? message.zipCode : null}
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(assetData.stories === 0 || assetData.stories === null) && count !== 0}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Number of stories"
                                                size="small"
                                                fullWidth
                                                name="stories"
                                                type='number'
                                                // required
                                                helperText={(assetData.stories === '' && count !== 0) ? message.stories : null}
                                                // value={assetDataObject ? assetDataObject.stories : assetData.stories}
                                                value={assetData.stories}
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                error={(assetData.area === 0 || assetData.area === null) && count !== 0}
                                                id="demo-helper-text-aligned-no-helper"
                                                label="Area in sq"
                                                size="small"
                                                fullWidth
                                                name="area"
                                                type='number'
                                                // required
                                                helperText={(assetData.area === 0  && count !== 0) ? message.area : null}
                                                // value={assetDataObject ? assetDataObject.stories : assetData.stories}
                                                value={assetData.area}
                                                onChange={(e) => inputchange(e)}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={6}>

                                            <Typography>Is this asset your Billing Address?</Typography>
                                            <FormControlLabel
                                                value="end"
                                                control={
                                                    <Checkbox
                                                        name="checkbox"
                                                        checked={!showBillingAddress}
                                                        onChange={handleCheckboxChange}
                                                        size='small'
                                                        disabled={(assetDataObject?.isBilling === true)}
                                                    />
                                                }
                                                label="Yes"
                                                labelPlacement="end"
                                            />

                                        </Grid> 
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} sx={{ marginTop: 1 }}>
                                <Grid item xs={12}>
                                    <Stack direction={'row'}>
                                        <Typography><MapOutlinedIcon fontSize='small' /></Typography>
                                        <Typography>Choose from MAP</Typography>
                                    </Stack>
                                </Grid>

                            </Grid>
                            <Grid container spacing={1} sx={{ marginTop: 1 }}>

                                <Grid item xs={12}>
                                    {isLoaded ? (
                                        <GoogleMap
                                            mapContainerStyle={containerStyle}
                                            center={componentData.current}
                                            zoom={10}
                                            onLoad={onLoad}
                                            onUnmount={onUnmount}
                                            onClick={onClick}
                                        >
                                            <MarkerF position={componentData.current} />
                                        </GoogleMap>
                                    ) : <></>}
                                </Grid>
                            </Grid>
                        </Card>
                        /*}
                    </Box>
                    {/* <Box m={2} className="poFooter">
                        {assetDataObject ? <Button className="button" variant="contained" size="medium" onClick={() => updateHandleSubmit(assetDataObject.id)}>Update</Button>
                            : <Button className="button" variant="contained" size="medium" onClick={handleSubmit}>Save</Button>}


                    </Box> */}
                    <Dialog open={success} >
                        <DialogTitle className='success'>
                            <Box paddingLeft={8} paddingRight={8}>
                                <Typography textAlign={'center'}>
                                    <CheckCircleOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                                <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                                    Success
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                            <DialogContent >
                                <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>   {successType === 'save' ? 'Asset added Successfully' : 'Asset Updated Successfully'}</DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                <Button variant='contained' className="successButton" onClick={handleAlertClose}>Ok</Button>
                            </DialogActions>
                        </Box>
                    </Dialog>

                    <Dialog open={error} >
                        <DialogTitle className='error'>
                            <Box paddingLeft={8} paddingRight={8}>
                                <Typography textAlign={'center'}>
                                    <CancelOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                                <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                                    Error
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                            <DialogContent >
                                <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>{successType === 'save' ? 'Error Couldnot  Inserted' : 'Error Couldnot  Updated'}</DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleAlertClose}>Close</Button>
                            </DialogActions>
                        </Box>

                    </Dialog>

                    <Dialog open={isBillingAddress} >
                        <DialogTitle >

                        </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                            <DialogContent >
                                <Box paddingLeft={8} paddingRight={8}>
                                    {/* <Typography textAlign={'center'}> */}
                                    {/* <AttachMoneyIcon sx={{ fontSize: "50px", color: "white" }} /></Typography> */}
                                    <Typography textAlign={'center'} color='black' fontSize={'20px'} fontWeight={'400'} >
                                        Your billing address is empty ,Set this as your billing address ?                              </Typography>
                                </Box>
                                {/* <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>{successType === 'save' ? 'Error Couldnot  Inserted' : 'Error Couldnot  Updated'}</DialogContentText> */}
                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                {assetDataObject ? (
                                    <>
                                        <Button variant='contained' onClick={handleUpdateBillingAddress}>Yes</Button>
                                        <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleCancelUpdateBillingAddress}>No</Button></>
                                ) : (
                                    <>  <Button variant='contained' onClick={handleBillingAddress}>Yes</Button>
                                        <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleCancelBillingAddress}>No</Button></>
                                )}



                            </DialogActions>
                        </Box>

                    </Dialog>


                    <Dialog open={noChange} >
                        <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                            <Box paddingLeft={8} paddingRight={8}>
                                <Typography textAlign={'center'}>
                                    <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                                {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
                            </Box>
                        </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                            <DialogContent >
                                <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
                            </DialogContent>
                            <DialogActions style={{ justifyContent: "center" }}>
                                <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
                            </DialogActions>
                        </Box>
                    </Dialog>


                    <QuestionRenderer state={null} data={assetDataObject} ref={questionRendererRef} onSave={handleSaveFunctionality} />
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        {assetDataObject ? (
                            <Button variant="contained" className='sucessButton' size="medium" onClick={() => callParentComponent()}>
                                Update
                            </Button>
                        ) : (
                            <Button variant='contained' className='mt-2' onClick={() => callParentComponent()} autoFocus>
                                Save
                            </Button>

                            // <Button disabled={!completed} className={completed === true ? "primaryTonalButton" : ""} onClick={() => callParentComponent()} autoFocus>
                            //     Save
                            // </Button>
                        )}
                    </Box>

                </Grid>
            </Grid>

        </>
    );
}

export default CreateAsset;