import * as React from 'react';
import {
    Box, Button, Typography, TextField, Grid,
    FormControl,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import CustomDialog from './Dialog';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getProjectTax, updateProjectMarginAndOverHead, updateProjectTax } from '../services/ProjectService';

const ProfitMarginSettings: React.FC<any> = (props) => {

    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    const [data, setData] = React.useState<any>(
        {
            margin: '',
            overHead: '',
        }
    );
    const [oldData, setOldData] = React.useState<any>();
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [noChange, setNoChange] = React.useState(false);
    const [count, setCount] = React.useState<any>(0);

    const validation = () => {
        let validated: any = true;
        if (data?.margin === '') {
            validated = false;
        }
        if (data?.overHead === '') {
            validated = false;
        }
        return validated;
    }

    const handleInputChange = (e: any) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        setCount(count + 1);
        let validated = validation();
        if (validated) {
            let hasChanges = anyChanges();
            if (hasChanges || oldData === undefined) {
                const result = await updateProjectMarginAndOverHead(props?.projectId, data, accessToken);
                if (result.status === 200 || result.status === 201) {
                    setOpenDialogs(true);
                    setSuccess(true);
                    // setReadOnly(true);
                    fetchData();
                } else {
                    setOpenDialogs(true);
                    setSuccess(false);
                }
            } else {
                setNoChange(true);
            }
        }
    }

    let objectData: any = {};

    const anyChanges = () => {
        let hasChanges = false;
        if (oldData !== undefined) {
            if (Number(oldData?.margin) !== Number(data?.margin) || Number(oldData?.overHead) !== Number(data?.overHead)) {
                hasChanges = true;
            }
        }
        return hasChanges;
    }


    const fetchData = async () => {
        const response = await getProjectTax(props?.projectId, accessToken);
        if (response?.data?.margin !== undefined && response?.data?.margin !== null && response?.data?.overHead !== undefined && response?.data?.overHead !== null) {
            setData({ ...data, overHead: response?.data?.overHead, margin: response?.data?.margin });
            Object.assign(data, { overHead: response?.data?.overHead, margin: response?.data?.margin });
            objectData = structuredClone({ overHead: response?.data?.overHead, margin: response?.data?.margin });
            setOldData(objectData);
            // setReadOnly(true);
        }
    }

    const handleClose = () => {
        setOpenDialogs(false);
        setSuccess(false);
        setNoChange(false);
    }

    React.useEffect(() => {
        fetchData();
    }, []);


    return (


        <Box>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Adjust Profit Margin And Over Head
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth size='small'>
                        <TextField
                            error={data?.margin === ''  && count !== 0}
                            id="demo-helper-text-aligned-no-helper"
                            label="Profit Margin"
                            size="small"
                            fullWidth
                            name="margin"
                            type="number"
                            // required
                            value={data?.margin}
                            onChange={(e) => handleInputChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth size='small'>
                        <TextField
                            error={data?.overHead === ''  && count !== 0}
                            id="demo-helper-text-aligned-no-helper"
                            label="Over Head"
                            size="small"
                            fullWidth
                            type="number"
                            name="overHead"
                            // required
                            value={data?.overHead}
                            onChange={(e) => handleInputChange(e)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={9}>
                    <Box>
                        <Button
                            className="sucessButton"
                            variant="contained"
                            size="small"
                            onClick={handleSubmit}
                        >
                            Adjust Margins
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <CustomDialog
                open={openDialogs}
                onClose={handleClose}
                success={success}
                error={!success}
                Content={success ? "Updated Successfully" : "Error Couldnot Update"} />

            <Dialog open={noChange} >
                <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent >
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' className="NoChangeButton" onClick={handleClose}>Ok</Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Box >
    )
};

export default ProfitMarginSettings;
