/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   :22-apr-2024
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for listing    customer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 2196           |  06/06/24   | Vanitha       |  Pagination implementation for Customer Management
*
*
*/
import React, { useEffect, useState } from 'react'
import { visuallyHidden } from '@mui/utils';
import Autocomplete from '@mui/material/Autocomplete';
import { Alert, Button, CircularProgress, Grid, Paper, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/system/Box/Box';
import { useOidc, useOidcAccessToken } from '@axa-fr/react-oidc';
import CustomDialog from '../../../pages/Dialog';
import Header from '../../Header';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getAllLeaves, getCountOfAllLeaves, rejectLeave } from '../../../services/ResourceService';
import { parseISO, differenceInCalendarDays } from 'date-fns';
import { useHandleUnauthorized } from '../../../hooks/UserSession';

interface Data {
  name: number;
  startDate: string;
  endDate: string;
  duration: string;

  location: string;
  status: string
}




function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [

  // {
  //   id: 'name',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Name',
  // },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Employer Name',
  },
  {
    id: 'startDate',
    numeric: true,
    disablePadding: false,
    label: 'Start Date',
  },
  {
    id: 'endDate',
    numeric: true,
    disablePadding: false,
    label: 'End Date',
  },
  {
    id: 'duration',
    numeric: true,
    disablePadding: false,
    label: 'Number of leave days',
  },

  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  }
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead >
      <TableRow className='tableHead'>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel

              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Action</TableCell>
      </TableRow>
    </TableHead>
  );
}


const LeaveList = () => {
  const ROLES = "customer"
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [dense, setDense] = React.useState(false);
  const EMPLOYEETIME = "Awaiting Approval";
  const ADMINAPPROVE = 'Approve';
  const ADMINREJECT = 'Reject';
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [rows, setRows] = React.useState<any>([]);
  const [count, setCount] = React.useState(Number);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [vendorId, setVendorId] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState('');
  const VENDORACTIVESTATUS = "Active";
  const VENDORINACTIVESTATUS = "Inactive";
  const [searchParams, setSearchParams] = React.useState('null');
  const [openDialogs, setOpenDialogs] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [showAnotherComponent, setShowAnotherComponent] = useState(false);
  const [rowData, setRowData] = useState();
  const [alignment, setAlignment] = React.useState('week');
  const [loading, setLoading] = React.useState(false);
  const { logout } = useOidc();

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const handleEditCustomer = (row: any) => {
    setRowData(row)
    setShowAnotherComponent(true);
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const { handleUnauthorized } = useHandleUnauthorized();
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };



  const closeConfirmation = () => {
    setShowAnotherComponent(false);
  }

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };


  const handleChangePage = async (event: unknown, newPage: number) => {
    // const newRowsPerPage = parseInt(event.target.value);
    // setRowsPerPage(newRowsPerPage);
    // setPage(0)
    setPage(newPage);
    fetchLeave();
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowsPerPage);
    setPage(0)
    fetchLeave();


  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setLoading(true)
    console.log(newAlignment)
    setAlignment(newAlignment);
    if (newAlignment === "day") {
      Day();
    }
    else if (newAlignment === "week") {
      Week();
    }
    else {
      Month();
    }
  };
  const calculateDaysBetween = (startDate: string, endDate: string): number => {
    const start = parseISO(startDate);
    const end = parseISO(endDate);

    const daysDifference = differenceInCalendarDays(end, start);

    // If the difference is 0, return 1 (consider the same date as 1 day)
    return daysDifference === 0 ? 1 : daysDifference;
  };
  const dateFormatter = (value: any, isEndOfDay: boolean = false) => {
    const date = new Date(value);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    let formattedDate = `${date.getFullYear()}-${month}-${day}`;

    if (isEndOfDay) {
      formattedDate += "T23:59:59.999Z";
    } else {
      formattedDate += "T00:00:00.000Z";
    }

    return formattedDate;
  };
  const fetchLeave = async () => {
    getCountOfAllLeaves("leave", accessToken).then((res) => {
   handleUnauthorized(res); // Handle unauthorized access

      setCount(res.data)
      console.log(res.data)
    })
    getAllLeaves("leave", accessToken, page, rowsPerPage).then((res) => {
     handleUnauthorized(res); // Handle unauthorized access

      console.log(res)
      if (res.status === 200 || res.status === 201 || res.ok === true) {
        setRows(res.data)

      }
    })
  };


  useEffect(() => {
    fetchLeave();
  }, []);
  const handleCloseDialog = () => {
    fetchLeave();
    setOpenDialog(false)
  }

  const dateFormatterDetails = (value: any) => {
    const date = new Date(value);
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const formattedDate = ((date.getDate()).toString().length == 1 ? "0" + (date.getDate()).toString() : date.getDate()) + " " + months[date.getMonth()] + " " + date.getFullYear();
    const formattedTime = (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":" + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
    return formattedDate + " " + formattedTime;
  }


  const Month = async () => {

  };
  const Day = async () => {
    setLoading(true); // Set loading to false when data is fetched

    // Start of the current day at 12:00 AM local time


  };

  const Week = async () => {
    setLoading(true); // Set loading to false when data is fetched

    // Define your local timezone
    const localTimezone = dayjs.tz.guess();


  }
  const rejectLeaveDays = (rsId: any) => {
    console.log(rsId)
    rejectLeave(rsId, "rejected", accessToken).then((res) => {
      handleUnauthorized(res); // Handle unauthorized access
      if (res.status === 200 || res.status === 201 || res.ok === true) {
        setOpenDialog(true)
        setSuccess(true);
      }
      else {
        setOpenDialog(true)

        setError(true)
      }
    })
  }

  let navigate = useNavigate();
  return (
    // <div >
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ paddingTop: 6 }}>
          <Alert onClose={handleClose} security='success' sx={{ width: '100%' }}>
            {toastMessage}
          </Alert>
        </Snackbar>

        <Box sx={{ width: '100%', typography: 'body1', marginLeft: "0px" }}>
          <Header title="Time Sheet View" subtitle="" />
        </Box>
        {loading ?
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box> :
          <Box sx={{ width: '100%' }}>
            <Paper >
              <div className='searchBar'>
                <Grid item xs={12} >
                  {/* <Paper
                      variant="outlined"
                      component="form"
                      className='searchField'
                      sx={{ p: "2px 4px", display: "flex", alignItems: "center", maxWidth: 300 }}
                    > */}
                  <Autocomplete
                    size='small'
                    disablePortal
                    id="combo-box-demo"
                    options={rows}
                    getOptionLabel={(option: any) => `${option.trade}${option.customerName}`} // Customize as needed
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Project Name" />}
                  />        {/* </Paper> */}
                </Grid>

                {/* <Grid item xs={2}><Tooltip title="Print">
        <IconButton>
          <PrintIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
        <Tooltip title="Share">
          <IconButton>
            <ShareIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Settings">
          <IconButton>
            <SettingsIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      </Grid> */}
              </div>
              <TableContainer component={Paper} style={{ width: '100%', overflowX: 'auto' }}>
                <Table
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}

                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows?.length}
                  />
                  {rows?.length === 0 ? <Typography textAlign={'center'}>Employee Leave are empty</Typography> :
                    <TableBody>
                      {rows?.map((row, index) => {
                        const isItemSelected = isSelected(Number(row?.id));
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, Number(row?.id))}
                            role="checkbox"
                            //                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            //  selected={isItemSelected}
                            sx={{ cursor: 'pointer' }}
                          >
                            {/* <TableCell padding="checkbox" size='small'>
                                <CustomCheckbox
                                  className='check'
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    'aria-labelledby': labelId,
                                  }}
                                />
                              </TableCell> */}
                            {/* <TableCell
                                size='small'
                                // className='tablerows'
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row?.id}
                              </TableCell> */}
                            <TableCell className='tableTimeSheet' size='small' style={{ textAlign: 'center' }} >
                              <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                                {/* <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                  >
                                    <Avatar src={`${row.avatar}`} />
                                  </StyledBadge>&nbsp; */}
                                {row?.name}
                              </Stack></TableCell>
                            <TableCell className='tableTimeSheet' size='small'>  <Stack className='timeSheetStart'>{dateFormatterDetails(row?.startDate)}</Stack></TableCell>
                            <TableCell className='tableTimeSheet' size='small'><Stack className='timeSheetEnd'>{dateFormatterDetails(row?.endDate)}</Stack></TableCell>
                            <TableCell className='tableTimeSheet' size="small">
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Stack>{calculateDaysBetween(row?.startDate, row?.endDate)}&nbsp;days</Stack>

                              </Stack>
                            </TableCell>
                            <TableCell className='tableTimeSheet' size="small">
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Stack>{row.status}</Stack>

                              </Stack>
                            </TableCell>
                            <TableCell size='small'>
                              <Stack direction={'row'}>
                                {/* {(row.status === 'invited' || !row.status || row.status === 'Invited') && ( */}
                                {/* <Tooltip title="Edit">
                                      <IconButton onClick={() => handleEditCustomer(row)} >
                                      Approve
                                      </IconButton>
                                    </Tooltip> */}
                                <Typography> <><Button className='dangerButton' size='small' onClick={() => rejectLeaveDays(row.resourceScheduleId)}>Reject</Button></></Typography>
                                {/* )}
                                  {row.status === 'Active' && (
                                    <Tooltip title="Edit">
                                      <IconButton onClick={() => onEdit(row.id)}>
                                        <RemoveRedEyeOutlinedIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )} */}
                                {/* <Tooltip title="Delete">
  
                                    <IconButton onClick={() => onDelete(row.id)}  >
                                      <DeleteRoundedIcon />
                                    </IconButton>
                                  </Tooltip> */}
                              </Stack>

                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {/* {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: (dense ? 33 : 53) * emptyRows,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )} */}
                    </TableBody>
                  }
                </Table>
              </TableContainer>
              <TablePagination
                sx={{ border: 'none', }}
                rowsPerPageOptions={[5]}
                component="div"
                // count={rows.length}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            {/* <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          /> */}
          </Box>

        }

        <CustomDialog
          open={openDialog}
          onClose={() => handleCloseDialog()}
          success={success}
          error={error}
          Content={success ? "Leave Rejected Successfully" : "Error Occured"}
        />
        {/* <Grid container spacing={1} >
        <Grid item xs={8} textAlign={"end"}>
  
          <Button className='primaryTonalButton' startIcon={<GroupAddIcon />} onClick={addEmployee} >Add employee</Button>
        </Grid>
        <Grid item xs={6}>
  
        </Grid>
        <Grid item md={10}>
          <style>{styles}</style>
      
          <DataGrid
            rows={rows}
            columns={columns}
  
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            // disableColumnSelector
            disableDensitySelector
            density='comfortable'
            slots={{
              // toolbar: GridToolbar,
  
            }}
            {...other}
          // checkboxSelection
          />
       
        </Grid>
      </Grid> */}
        {/* {showAnotherComponent && (<UpdateTimeSheet open={showAnotherComponent} onClose={() => closeConfirmation()} setRows={setRows} timeView={alignment} rowDatas={rowData} setCount={setCount}/>)} */}
      </Grid>
    </>
    // </div>
  )
}


export default LeaveList;
