/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 06-01-2025
* @author      : vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Confirm dialog to show message after reference project selection
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*/
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Backdrop, DialogContentText, IconButton, Typography } from '@mui/material';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import CloseIcon from '@mui/icons-material/Close';
import gmslogo from "../../assets/logo.gif";
import CustomDialog from '../../pages/Dialog';
import { completeProject } from '../../services/ProjectService';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { completeTask } from '../../services/TaskService';

const CompleteTaskWarning: React.FC<any> = (props) => {
    const { accessToken } = useOidcAccessToken();
    const [isLoading, setIsLoading] = React.useState(false);
    const [openCompleteDialog, setOpenCompleteDialog] = React.useState(false);
    const [completeSuccess, setCompleteSuccess] = React.useState(false);
    const [completeError, setCompleteError] = React.useState(false);

    const handleClose = () => {
        props.onClose();
    };

    const handleCompleteTask = async () => {
        setIsLoading(true);
        let result = await completeTask(props?.tasks?.id, props?.tasks?.taskUid, accessToken);
        if (result.status === 200 && result.data === true) {
            setCompleteSuccess(true);
            setOpenCompleteDialog(true);
            handleClose();
            props.func();
            setIsLoading(false);
        }
        else {
            setCompleteError(true);
            setOpenCompleteDialog(true);
            setIsLoading(false);
        }
    }

    return (
        <React.Fragment>

            <Dialog
                open={props?.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <img src={gmslogo} alt="gms" className="overlay" style={{ height: "60px" }} />
                </Backdrop>
                <DialogTitle className='warning'>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <WarningAmberRoundedIcon sx={{ fontSize: "40px", color: "white" }} /></Typography>
                        <Typography textAlign={'center'} color='white' fontSize={'18px'} fontWeight={'400'} >
                            Warning
                        </Typography>
                    </Box>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "white",
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom marginBottom={2} sx={{ color: "red", fontSize: '16px' }}>
                        You are attempting to Force Complete this task.
                    </Typography>
                    <DialogContentText gutterBottom marginBottom={2}>
                        Are you sure you wish to proceed?.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='contained'>Go Back</Button>
                    <Button
                        onClick={handleCompleteTask}
                        autoFocus
                        variant='contained'
                        color='success'>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomDialog
                open={openCompleteDialog}
                onClose={() => setOpenCompleteDialog(false)}
                success={completeSuccess}
                error={completeError}
                Content={completeSuccess ? "Task Completed Successfully" : "Error"}
            />
        </React.Fragment>
    );
}

export default CompleteTaskWarning;