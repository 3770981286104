 
// WelcomePage.tsx
import * as React from 'react';
import { Container, Typography, Button, CssBaseline, Card, CardMedia,CardActions, Grid, Box, Stack } from '@mui/material';
import { useOidc } from '@axa-fr/react-oidc';
import gmslogogif from "../assets/login/gmslogo.svg"
import leftsvg from "../assets/login/left.jpeg"
import rightsvg from "../assets/login/right.svg"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';
import Dashboard from './Dashboard';
import "./Home.scss"
 
import { styled } from '@mui/material/styles';
 
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
 
 
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
 
 
 
const Home: React.FC = () => {
  const { login, logout, isAuthenticated } = useOidc();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate('/commondashboard');
    }
  }, [isAuthenticated, navigate]);
  // const pageStyles: React.CSSProperties = {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   alignItems: 'center',
  //   minHeight: '100vh', // Make sure the container stretches to the full height of the viewport
  //   backgroundColor: '#5c78f7', // Your desired background color
  // };
 
  // const containerStyles: React.CSSProperties = {
  //   marginTop: '8rem',
  //   padding: '2rem',
  //   borderRadius: '8px',
  //   boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Add a shadow
  //   backgroundColor: 'white', // Container background color
  // };
 
 
  return (
    // <Box sx={{ position: "relative", overflow: "hidden", height: "100vh"}}>
    //   <img src={gmslogogif} alt="Your Alt Text Here" style={{ maxWidth: '100%', height: '100%', overflow: "hidden",objectFit:"cover" }} />
    //   {!isAuthenticated && (
    //     <Button style={{ position: "absolute", bottom: "45%", left: "67%",  minWidth: "160px" }} variant='contained' size='large'
    //       onClick={() => login('/commondashboard')} endIcon={<ArrowForwardIcon />} >
    //       Login
    //     </Button>
    //   )}
    //   <Stack direction="row" spacing={1} style={{ position: "absolute", bottom: "3%", left: "80%", zIndex: "1", fontWeight: "500" }}>
    //     <CopyrightOutlinedIcon />
    //     <Typography >Copyright 2024 GMS Inc - All Rights Reserved</Typography>
    //   </Stack>
    // </Box>
 
    <Box sx={{ flexGrow: 1 }}>
      <Grid container >
        <Grid item xs={6} md={6} lg={6}>
          <Card sx={{ borderRadius: 0 }}>
            <CardMedia
              sx={{ height: '100vh' }}
              image={leftsvg}
            />
          </Card>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <Card sx={{ borderRadius: 0, position: "relative",  height: '100vh' }} className='loginbgright'>
            {/* <CardMedia
              sx={{ height: '100vh', textAlign: 'center' }}
              image=
            /> */}
            <div style={{ textAlign: 'center', marginTop:'10%'   }}>
              <Grid container>
                <Grid item xs={3} >
                </Grid>
                <Grid item xs={6} >
                  <img src={gmslogogif} alt="Your Alt Text Here" />
                  {!isAuthenticated && (
                    <Button variant='contained' size='large' sx={{ marginTop: '75px' }}
                      onClick={() => login('/commondashboard')} endIcon={<ArrowForwardIcon />} >
                      Login
                    </Button>
                  )}
                </Grid>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ color: 'green', }}>
                    <Typography variant="h3" sx={{ marginTop: '175px' }}  >
                      Logging you into the world of
                    </Typography>
                    <Typography variant="h3" >
                      Advanced AI Powered Construction Management
                    </Typography>
                  </div>
                </Grid>
 
               
 
 
 
 
              </Grid>
 
            </div>
         
          </Card>
          <Grid container >
                  <Grid item xs={12}>
                    <div style={{ position: 'absolute', zIndex: '200', right: 20, bottom: 0, }}>
                      <Stack direction="row" spacing={1} sx={{ fontWeight: "500" }}>
                        <CopyrightOutlinedIcon />
                        <Typography >Copyright 2024 GMS Inc - All Rights Reserved</Typography>
                      </Stack>
                    </div>
                  </Grid>
                </Grid>
        </Grid>
      </Grid>
 
    </Box>
 
  );
};
 
export default Home;