
import React from 'react'
// import { Router, Route } from 'react-router-dom'

import Salescard from '../components/Salescard/Salescard'
import CreateAsset from '../components/asset/CreateAsset'
import ListAsset from '../components/asset/ListAsset'
import CreateBid from '../components/createbid/CreateBidWizard'
import CustomerTable from '../pages/Admin templates/CustomerTable'
import CustomerForm from '../components/customerDetail/customerRegistration/CustomerForm'
import ExpenseList from '../components/expense/ExpenseList'
import GPS from '../components/gps/Gps'
import Invoiceform from '../components/invoice/invoiceForm/InvoiceForm'
import MaterialList from '../components/materialManagement/MaterialList'
import Avatars from './Avatar Template/Avatar'
import BillForm from './BillForm'
import Buttons from './ButtonTemplates/Button'
import FloatChatbot from './Chatbot/FloatChatbot'
import CustomerProjectManagement from './CustomerProjectManagement'
import { Imageupload } from './Imageupload/Imageupload'
import Progress from './ProgressBar Template/Progress'
import ProjectInformation from './ProjectInformation'
import SalesEstimate from './SalesEstimate/SalesEstimate'
import SalesProduction from './SalesProduction/SalesProduction'
import SalesProjectManagement from './SalesProjectManagement'
import SampleView from './SampleTemplates/SampleView/SampleView'
import Steppers from './Stepper Template/Steppers'
import Timelines from './Timeline Template/Timeline'
import Calender from './calendar Template/Calendar'
import Sample from './sample'
import { BrowserRouter as Router, Routes as ReactRoutes, Route } from 'react-router-dom';
import Home from './Home'
import Dashboard from './Dashboard'
import Createuser from './Createuser'
import Customerfinance from '../components/finance/Customerfinance'
import Tenantfinance from '../components/finance/Tenantfinance'
import GanttChart from '../components/ganttchart/components/GanttChart'
import FinanceManangement from './Finance'
import ContractSigningTemplate from '../components/contractTemplate/ContractTemplate'
import Admin from './Admin templates/Admin'
import ResourceList from '../components/resourceManagement/ResourceList'

import InvoiceFormView from '../components/invoice/invoiceForm/InvoiceFormView'
import InvoiceList from '../components/invoice/InvoiceList'
import Tenantconfiguration from '../components/tenantconfiguration/Tenantconfiguration'
import CommonDashboard from './CommonDashboard'
import VendorRegister from '../components/vendor/VendorRegister'
import PODetails from '../components/purchaseOrder/GetPODetails'
import VendorList from '../components/vendor/VendorList'
import Employeetable from './Admin templates/Employeetable'
import VendorManagement from '../components/vendor/VendorManagement'
import JobLedgerList from '../components/jobLedger/JobLedgerList'
import Employment from './Admin templates/Employment/Employer'
import Customer from './Admin templates/Employment/Customer'
import DeliveryslipDetails from '../components/deliverySlip/DeliveryslipDetails'
import Tenatconfiguration from '../components/tenantconfiguration/Generalconfiguration'
import TimeSheetView from '../components/TimeLog/timeSheet/TimeSheetDetails'
import Questions from './questionBuilder/components/Questions'
import ToolList from '../components/toolManagement/ToolList'
import QuestionRenderer from './QuestionRenderer/QuestionRenderer'
import GanttChartComponent from '../components/treegridGantt/Gantt/GanttChartComponent'
import GanttParentPage from '../pages/GanttParentPage'
import InvoicePreview from '../components/invoice/invoiceForm/InvoicePreview'


const PageRoutes = () => {
  return (
    <div>

      <ReactRoutes>

        <Route path='/' element={<Home />} />

        <Route path="/commondashboard" element={<CommonDashboard />} />

        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/calender" element={<Calender />} />

        <Route path="/customertable" element={<CustomerTable />} />

        <Route path="/sample" element={<Sample />} />

        <Route path="/admin" element={<Admin />} />

        <Route path="/bill" element={<BillForm />} />

        <Route path="/customerregistration" element={<CustomerForm />} />

        <Route path="/createbid" element={<CreateBid />} />

        <Route path="/ganttchart" element={<GanttChart />} />

        <Route path="/gps" element={<GPS />} />

        <Route path="/sampleview" element={<SampleView />} />

        <Route path="/button" element={<Buttons />} />

        <Route path="/progress" element={<Progress />} />

        <Route path="/timeline" element={<Timelines />} />

        <Route path="/avatar" element={<Avatars />} />

        <Route path="/stepper" element={<Steppers />} />

        <Route path="/calender" element={<Calender />} />

        <Route path="/imageupload" element={<Imageupload />} />

        <Route path="/addinvoiceform" element={<Invoiceform />} />

        <Route path="/podetails" element={<PODetails />} />

        <Route path="/soldproduction/projectinformation/deliveryslip" element={<DeliveryslipDetails />} />

        <Route path="/invoice" element={<InvoiceList />} />

        <Route path="/invoiceform" element={<Invoiceform />} />

        <Route path="/expenselist" element={<ExpenseList />} />

        <Route path="/asset" element={<CreateAsset />} />

        <Route path="/assetList" element={<ListAsset />} />

        <Route path="/soldproduction/projectinformation" element={<ProjectInformation />} />

        <Route path="/salesestimate" element={<SalesEstimate />} />

        <Route path="/soldproduction" element={<SalesProduction />} />

        <Route path="/customerprojectmanagement" element={<CustomerProjectManagement />} />

        <Route path="/customerfinance" element={<Customerfinance />} />

        <Route path="/tenantfinance" element={<Tenantfinance />} />

        <Route path="/sales" element={<SalesProjectManagement />} />

        <Route path="/finance" element={<FinanceManangement />} />

        <Route path="/materialmanagement" element={<MaterialList />} />
        <Route path="/toolManagement" element={<ToolList />} />
        <Route path="/tenantconfiguration" element={<Tenantconfiguration />} />

        <Route path="/resourcemanagement" element={<ResourceList />} />

        <Route path="/chatui" element={<FloatChatbot />} />
        <Route path="/createuser" element={<Createuser />} />
        <Route path="/contract" element={<ContractSigningTemplate />} />
        <Route path="/soldproduction/projectinformation/invoiceview" element={<InvoiceFormView />} />
        <Route path="/admin/vendorregistration" element={<VendorRegister />} />
        <Route path="/vendorlist" element={<VendorList />} />
        <Route path="/vendormanagement" element={<VendorManagement />} />
        <Route path="/employeeList" element={<Employeetable />} />
        <Route path="/jobLedger" element={<JobLedgerList />} />
        <Route path="/employment" element={<Employment />} />
        <Route path="/admin/employment" element={<Employment />} />
        <Route path="/admin/customer" element={<Customer />} />
        <Route path="/Generalconfiguration" element={<Tenatconfiguration />} />
        <Route path="/customertable" element={<CustomerTable />} />
        <Route path="/timesheets" element={<TimeSheetView />} />
        <Route path="/questionBuilder" element={<Questions />} />

        {/* <Route path="/gantt" element={<GanttChartComponent />} /> */}
        {/* <Route path="/gantt" element={<GanttChartComponent tasks={[]} onGanttDataUpdate={() => {}} />} /> */}


        <Route path="/ganttCharts" element={<GanttParentPage />} />
        <Route path="/soldproduction/projectinformation/invoicePreview" element={<InvoicePreview />} />
        {/* <Route
    path="/ganttParent"
    element={
        <GanttParentPage
            selected={[]} // Pass an empty array as placeholder
            open={false}   // Pass `false` as the initial open state
            onClose={() => {}} // Provide a placeholder no-op function
        />
    }
/> */}


        {/* <Route path="questionrenderer" element={<QuestionRenderer />} /> */}

      </ReactRoutes>

    </div>
  )
}

export default PageRoutes;
