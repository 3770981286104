export const units = [
    "sq.ft", "ft", "EA"
  ];


  export const questions = [
    {
        type:"Install Task",
        questions:{"labour":"How many units of work were completed?"}
    },
    {
        type:"Remove Task",
        questions:{"labour":"How many units of work were completed?"}
    },
    {
        type:"Verify Task",
        questions:{"foreman":"Did all Crew Member complete their respective tasks?"}
    }
  ];