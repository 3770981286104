import * as React from 'react';
import Box from '@mui/material/Box';

import { styled, useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Personalinfo from '../../pages/Admin templates/Employment/Employer/Personalinfo';
import Generalconfiguration from '../tenantconfiguration/Generalconfiguration';
import Admin from '../../pages/Admin templates/Admin';
import { Card, Grid, Step, StepConnector, StepIconProps, StepLabel, Stepper, stepConnectorClasses } from '@mui/material';
import Tenantconfiguration from '../tenantconfiguration/Tenantconfiguration';
import { useNavigate } from 'react-router-dom';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import { useHandleUnauthorized, UserContext } from '../../hooks/UserSession';
import { getUserDetails } from '../../services/EmployeeService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  }),
);

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background:
      'linear-gradient(to right,#3c5bfa, #7f00ffd4)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background:
      'linear-gradient(to right,#3c5bfa, #7f00ffd4)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    background:
      'linear-gradient(to right,#3c5bfa, #7f00ffd4)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    background:
      'linear-gradient(to right,#3c5bfa, #7f00ffd4)',
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1:  <GroupAddIcon />,
    2:<SettingsIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}
export default function AdminOnBoarding() {
  const { userSessionDetails } = React.useContext(UserContext);
  const [userId, setUserId] = React.useState<any>();
  const { accessToken } = useOidcAccessToken();
  const [rowData, setRowData] = React.useState();
  const { handleUnauthorized } = useHandleUnauthorized();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      handleFinish();
    } else {
      setActiveStep((prevActiveStep) => Math.min(prevActiveStep + 1, maxSteps - 1));
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const handleFinish = () => {
    window.location.reload();
    console.log('Finish button clicked');
  };

  // Fetch profile data when `userSessionDetails.id` changes
  React.useEffect(() => {
    const handleProfileData = async () => {
      const response = await getUserDetails(userSessionDetails?.id, accessToken);
      handleUnauthorized(response)
      if (response) {
        setRowData(response.data);
      }
    };
    handleProfileData();
  }, [userSessionDetails?.id]);

  // Define steps based on rowData
  const steps = React.useMemo(() => [
    {
      label: 'Personal Information',
      description: 'Complete your Personal Information',
      component: rowData ? <Personalinfo rows={rowData} /> : <Personalinfo />,
    },
    {
      label: 'Trade Settings',
      description: 'Set up your General Configuration, Trade configuration, Contract Setting , Finance Setting, Mail Configuration and Workflow Metrics',
      component: <Tenantconfiguration />,
    },
    {
      label: 'Admin Setup',
      description: 'Configure Admin Settings by inviting Employees (required: 1 Sales associate, 1 Foreman, 1 Purchase officer, and 3 laborers),Customers and vendors and their material and tools',
      component: <Admin />,
    },
  ], [rowData]);

  const maxSteps = steps.length;

  return (
    <Grid container direction="column" alignItems="center">
      <Grid container>
        <Grid item xs={12}>
          <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button size="small" onClick={handleNext}>
                {activeStep === maxSteps - 1 ? 'Finish' : 'Next'}
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Back
              </Button>
            }
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} m={2}>
          <Typography style={{ fontSize: '18px' }}>{steps[activeStep].description}</Typography>
        </Grid>
        <Grid item xs={12}>
          {steps[activeStep].component}
        </Grid>
      </Grid>
    </Grid>
  );
}
