/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Invoice Service Layer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import { ConfigUrl } from "../auth/OidcConfig";
import { executeGet, executePost } from "./Api";
import {InvoiceJson} from '../data/invoicejson'
import { executeGetData, executePostData, executePostImage, executePutData } from "./ApiBase";

export const getInvoiceList= async (accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/invoices`;
  return await executeGetData(url, accessToken);
};

export const getInvoiceCountByProjectId= async (projectId:any, status:any, isCustomer:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/invoices/getInvoiceCount/projectId/${projectId}/status/${status}/isCustomer/${isCustomer}`;
  return await executeGetData(url, accessToken);
};

export const getInvoiceByProjectId= async (projectId:any, status:any, isCustomer:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/invoices/getInvoice/projectId/${projectId}/status/${status}/isCustomer/${isCustomer}`;
  return await executeGetData(url, accessToken);
};

export const getInvoicesByProjectId= async (projectId:any,accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/invoices/getInvoices/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
};

// export const generateInvoice = async(projectId:any,paymentTerm:any, paymentType:any,accessToken:any) =>
// {
//   const url = `${ConfigUrl.baseURL}/invoices/generateInvoice/projectId/${projectId}/paymentTerm/${paymentType}/paymentType/${paymentTerm}`;
//   return await executeGetData(url, accessToken);
// }

export const generateInvoice = async(projectId:any,accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/invoices/generateInvoice/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
}

export const saveInvoice = async (invoice: any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/invoices`;
  return await executePostData(url, invoice, accessToken);
};

export const editInvoice = async(id:any, payload:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/invoices/id/${id}`;
  return await executePutData(url, payload, accessToken);
}

export const getInvoiceByProjectIdAndInvoiceId= async (projectId:any, invoiceId:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/invoices/getInvoice/projectId/${projectId}/invoiceId/${invoiceId}`;
  return await executeGetData(url, accessToken);
};


export const getInvoiceTemplate= async (accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/invoices/getInvoiceTemplate`;
  return await executeGetData(url, accessToken);
};

export const getTerms = async(projectId:any,paymentTerm:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/invoices/getTerms/paymentTerm/${paymentTerm}/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
}

export const getPaymentType = async(projectId:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/invoices/getPaymentMethod/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
}



export const getUnPaidInvoice = async(status:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/invoices/getInvoiceByStatus/status/${status}`;
  return await executeGetData(url, accessToken);
}


export const uploadPaymentReceipt= async(payload:any,invoiceId:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/invoices/paymentReceipt/invoiceId/${invoiceId}`;
  return await executePostImage(url, payload, accessToken);
}

export const getPaymentreceipt= async(invoiceId:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/invoices/getPaymentReceipt/invoiceId/${invoiceId}`;
  return await executeGetData(url, accessToken);
}

export const changeInvoiceStatus = async(id:any, status:any, accessToken:any) =>
  {
    const url = `${ConfigUrl.baseURL}/invoices/updateStatus/id/${id}/status/${status}`;
    return await executePutData(url, null, accessToken);
  }

  
export const getPaymentReceiveDetail= async(invoiceId:any, accessToken:any) =>
  {
    const url = `${ConfigUrl.baseURL}/paymentReceives/getPaymentReceives/invoiceId/${invoiceId}`;
    return await executeGetData(url, accessToken);
  }

  export const getTotalAmount = async (invoice: any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/invoices/getTotalInvoicedAmount`;
    return await executePostData(url, invoice, accessToken);
  };

