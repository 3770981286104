/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : For customer projectmanagement dashboard (sales/Estimate)
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*/

import {  Grid } from "@mui/material";
import ProjectStatus from "../components/projectStatus/ProjectStatus";
import CreateBidAdvertisement from "../components/createBidAdv/CreateBidAdvertisement";
import { UserContext, isUserHasPermission, useHandleUnauthorized } from "../hooks/UserSession";
import { useContext, useEffect,} from "react";
import Projectactivity from "../components/Projectactivity/Projectactivity";



const CustomerProjectManagement: React.FC = () => {

  const { userSessionDetails, setUserDetails } = useContext(UserContext);
  const { handleUnauthorized } = useHandleUnauthorized();
  const userPermission = async () => {

    const response = await isUserHasPermission("ProjectStatus");

    handleUnauthorized(response);
    // setTasks(response.data);
  };

  useEffect(() => {
    userPermission();
  }, []);


  return (
    <>
      <UserContext.Provider value={{ userSessionDetails, setUserDetails }}>

        <Grid container rowSpacing={1} mt={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {/* <Grid item xs={1}></Grid> */}
          <Grid item xs={8}>
          <Grid item xs={12} className="mb-4" ><CreateBidAdvertisement /></Grid>
            {isUserHasPermission("ProjectStatus") && (
            <Grid item xs={12}><ProjectStatus /></Grid>
            )}
            
          </Grid>

          <Grid item xs={4}><Projectactivity /></Grid>
          {/* <Grid item xs={1}></Grid> */}
        </Grid>
      </UserContext.Provider>
    </>

  );
};

export default CustomerProjectManagement;