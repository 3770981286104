/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 06-Sep-2024
* @author      : vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Confirm dialog to show message after reference project selection
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*/
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Backdrop, DialogContentText, IconButton, Typography } from '@mui/material';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import CloseIcon from '@mui/icons-material/Close';
import gmslogo from "../../assets/logo.gif";
import CustomDialog from '../../pages/Dialog';
import { createEstimate } from '../../services/BidService';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getConfiguredDataByKey, getPreconstructionPeriod } from '../../services/TradeConfigurationService';
import { weekdays } from '../../utils/Dateutils';
import dayjs, { Dayjs } from 'dayjs';
import { getBlockedDates } from '../../services/ResourceService';
import { useHandleUnauthorized } from '../../hooks/UserSession';

const EstimateWarning: React.FC<any> = (props) => {
    const { accessToken } = useOidcAccessToken();
    const [isLoading, setIsLoading] = React.useState(false);
    const [openEstimateDialog, setOpenEstimateDialog] = React.useState(false);
    const [estimateSuccess, setEstimateSuccess] = React.useState(false);
    const [estimateError, setEstimateError] = React.useState(false);
    const [openDatePicker, setOpenDatePicker] = React.useState(false);
    const [preconstructionPeriod, setPerConstructinPeriod] = React.useState<any>();
    const [workingDays, setWorkingDays] = React.useState<any>([]);
    const [labourCount, setLabourCount] = React.useState<any>();
    const [projectDuration, setProjectDuration] = React.useState<any>();
    const blockedDates = React.useRef<any>({ disableDates: [] });
    const [disabledDates, setDisabledDates] = React.useState<any>([]);
    const [selectedDate, setSelectedDate] = React.useState<any>(null);
    const [projectData, setProjectData] = React.useState<any>();
    const [reason, setReason] = React.useState<any>();
    const navigate = useNavigate();
    const { handleUnauthorized } = useHandleUnauthorized();
    const handleClose = () => {
        props.onClose();
    };

    const createEstimates = async () => {
        setIsLoading(true);
        let result = await createEstimate(props?.projectId, selectedDate, accessToken);
        if (result.status === 200 && result.data !== null && result.data !== "") {
            if(result.data.status === "error"){
                if(result.data.errorMessage === "teamMembers"){
                    setProjectData(result.data);
                    getPreConstructionPeriod();
                    workSchedule();
                    setOpenDatePicker(true);
                    setProjectDuration(result.data.projectDuration);
                    setLabourCount(result.data.resourceCount);
                    handleMonthChange(dayjs());
                    setIsLoading(false);
                    props.onClose();
                }else{
                    setReason(result.data.errorMessage);
                    setIsLoading(false);
                    handleClose();
                    setOpenEstimateDialog(true);
                    setEstimateError(true); 
                }
            }else{
                setIsLoading(false);
                onNextPage();
            }
        }
        else {
            setReason("Error Creating Estimation");
            setIsLoading(false);
            handleClose();
            setOpenEstimateDialog(true);
            setEstimateError(true);
        }
    }

    const getPreConstructionPeriod = async () => {
        const result = await getPreconstructionPeriod("preconstructionPeriod", accessToken);
        handleUnauthorized(result);
        setPerConstructinPeriod(result?.data);
    }

    const workSchedule = async () =>{
        const response = await getConfiguredDataByKey("workingDays", accessToken);
        handleUnauthorized(response);

        if (response.data !== undefined && (response.status === 200 || response.status === 201)) {
            let tenantWokringDays = response.data.split(",");
            weekdays.map((day: any) => {
                if (tenantWokringDays.includes(day)) {
                    workingDays.push(weekdays.indexOf(day));
                }
            })
            //setWorkingDays(response.data.split(",").map((num:any) => parseInt(num)));
        }
    }

    // get disabled date from active construction start date
    const getDisabledDates = (date: Dayjs) => {
        const today = dayjs();  // Get the current date
        const selectedDate = props?.props?.jobStartDate === undefined ? dayjs(props?.props?.startDate) : dayjs(props?.props?.jobStartDate);
        let period = preconstructionPeriod;
        const salesStartDate = props.props?.salesStartDate ? dayjs(props.props?.salesStartDate) : props.props?.startDate;

        //const result = await getBlockedDates(date, );

        const blockDates: any = [];
        for (let i = 0; i < period; i++) {
            let d = dayjs(selectedDate.subtract(i, 'day'));
            if (d.day() === 0 || d.day() === 6) {
                period++
            }
            else {
                blockDates.push(d.format("YYYY-MM-DD"));
            }
        }
        // console.log(blockedDates.current.disableDates)
        if (blockedDates.current.disableDates.includes(date.format("YYYY-MM-DD"))) {
            return true;
        }

        // if (blockDates.includes(date.format("YYYY-MM-DD")) || date.isAfter(selectedDate, 'day')) {
        //     return true;
        // }

        if (!workingDays.includes(date.day())) {
            return true;
        }

        return date.day() === 6 || date.day() === 0;
    };

    const handleMonthChange = async (newMonth) => {
        // Automatically select the first day of the new month
        const firstDayOfNextMonth = newMonth.startOf('month');
        const formattedDate = firstDayOfNextMonth.format('YYYY-MM-DDTHH:mm[Z]');
        console.log(labourCount, projectDuration);
        const result = await getBlockedDates(formattedDate, labourCount, projectDuration, accessToken);
        if (result.status === 200) {
            blockedDates.current.disableDates = [];
            var dates = blockedDates.current.disableDates;
            if (result.data.length !== 0 && result.data !== '') {
                result.data.map(dateString => {
                    if (!blockedDates.current.disableDates.includes(dayjs(dateString).format("YYYY-MM-DD"))) {
                        blockedDates.current.disableDates.push(dayjs(dateString).format("YYYY-MM-DD"))
                    }
                })
                setDisabledDates(dates)
            }
        }
        // console.log(blockedDates.current.disableDates, disabledDates)
    };

    const handleDateChange = (newDate: any) => {
        setSelectedDate(dayjs(newDate.$d).format('YYYY-MM-DDTHH:mm[Z]'));
    };



    const onNextPage = () => {
        navigate("/salesestimate");
    }

    const closeDatePicker = () =>{
        setOpenDatePicker(false);
    }

    return (
        <React.Fragment>

            <Dialog
                open={props?.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
                </Backdrop>
                <DialogTitle sx={{ m: 0, p: 2, fontSize: '16px' }}>
                    Confirmation
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom marginBottom={1}>
                        Make sure you have added all the tasks, completed the Bom's, Tool's and added all resource required.
                    </Typography>
                    <DialogContentText gutterBottom >
                        Note that once you create an Estimate you cannot come back to this step.
                    </DialogContentText>
                    <DialogContentText gutterBottom >
                        Do you confirm and wish to proceed?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Go Back</Button>
                    <Button
                        onClick={createEstimates}
                        autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDatePicker}
                fullWidth={true}
                maxWidth={"sm"}
                scroll={'paper'}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
                </Backdrop>
                <DialogTitle >
                    {"Selecetd date is unavailable please select another date"}
                </DialogTitle>
                <DialogContent dividers>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                orientation="landscape"
                disablePast
                shouldDisableDate={getDisabledDates}//disable properties     
                disableHighlightToday
                slotProps={{ actionBar: { actions: [] } }}
                onMonthChange={handleMonthChange}
                onChange={handleDateChange}
                minDate={projectData != undefined ? dayjs(new Date(projectData.startDate)).add(projectDuration, 'day') : dayjs()}
                />
                </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button className='sucessButton' variant="contained" size="small" onClick={createEstimates}>Proceed</Button>
                    <Button className='dangerButton' variant="contained" size="medium" onClick={closeDatePicker} autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomDialog
                open={openEstimateDialog}
                onClose={() => setOpenEstimateDialog(false)}
                success={estimateSuccess}
                error={estimateError}
                Content={estimateSuccess ? "Estimate Created Successfully" : reason}
            />
        </React.Fragment>
    );
}

export default EstimateWarning;


