import dayjs from "dayjs";

export const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const convertUTCToLocalTime = (time:any) => {
    var hm = time.split(':');

    var d = new Date(dayjs(new Date()).format('YYYY-MM-DD') + "T" + hm[0] + ":" + hm[1] + ":00.000Z");

    return dayjs(new Date(d.toUTCString().replace("Z",""))).format('HH:mm');
  }