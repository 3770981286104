/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 24-Dec-2024
* @author      : vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Reason Dialog to get a Reason for skip a upload task.
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*/
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Divider, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { updateTaskReason } from '../../services/TaskService';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import CustomDialog from '../../pages/Dialog';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const reasons = ["Task is not relevant to this job", "Task has already been completed", "others"]

const ReasonDialog: React.FC<any> = (props) => {
    const [reason, setReason] = React.useState<any>('');
    const [otherReason, setOtherReason] = React.useState<any>('');
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [count, setCount] = React.useState<any>(0);

    const handleClose = () => {
        setReason('');
        setOtherReason('');
        setCount(0);
        props.onClose();
    };

    const closeDialog = () => {
        setOpenDialogs(false);
    }

    const handleChange = (e: any) => {
        setReason(e.target.value);
    }

    const handleOtherReasonChange = (e: any) => {
        setOtherReason(e.target.value);
    }

    const updateTaskStatusAndReason = async () => {
        setCount(count + 1);
        if (props?.taskId !== undefined && props?.taskUid !== undefined) {
            let result;
            if (otherReason === '' && reason !== '' && reason !== 'others') {
                result = await updateTaskReason(props?.taskId, reason, props?.taskUid, accessToken);
            }
            else if (otherReason !== '' && reason !== '') {
                result = await updateTaskReason(props?.taskId, otherReason, props?.taskUid, accessToken);
            }
            if (result !== undefined) {
                if (result.status === 200 && result.data === true) {
                    setCreateSuccess(true);
                    setOpenDialogs(true);
                    handleClose();
                    props?.func();
                }
                else {
                    setCreateError(true);
                    setOpenDialogs(true);
                }
            }
        }
    }
    return (
        <><React.Fragment>

            <Dialog
                open={props?.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle sx={{ m: 0, p: 2, fontSize: '16px' }}>
                    Reason For Skip
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Divider></Divider>
                <DialogContent>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="demo-simple-select-label" error={reason === '' && count !== 0} required>Reason</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="Reason"
                            value={reason}
                            error={reason === '' && count !== 0}
                            label="Reason"
                            onChange={(e) => handleChange(e)}
                            MenuProps={MenuProps}
                            required
                        >
                            {reasons?.map((type: any, index: any) => {
                                return (
                                    <MenuItem key={index} value={type}>{(type)?.split(/(?=[A-Z])/).join(" ").replace(/^./, type[0]?.toUpperCase())}</MenuItem>
                                )
                            })}


                        </Select>
                    </FormControl>
                    {reason === "others" ?
                        <FormControl fullWidth size='small' sx={{ mt: 2 }}>
                            <TextField
                                id="demo-helper-text-aligned"
                                label="Specify Why?"
                                name="otherReason"
                                size="small"
                                multiline
                                value={otherReason}
                                error={otherReason === '' && count !== 0 && reason === "others"}
                                fullWidth
                                onChange={(e) => handleOtherReasonChange(e)}
                                required />
                        </FormControl>
                        :
                        null}
                </DialogContent>

                <Divider></Divider>
                <DialogActions>
                    <Button className='sucessButton' variant="contained" size="small" onClick={updateTaskStatusAndReason}>Submit</Button>
                    <Button className='dangerButton' variant="contained" size="medium" onClick={handleClose} autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
            <CustomDialog
                open={openDialogs}
                onClose={() => closeDialog()}
                success={createSuccess}
                error={createError}
                Content={createSuccess ? "Updated" : "Not Updated"} /></>
    );
}

export default ReasonDialog;