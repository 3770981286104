import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    IconButton,
    TextField,
    Grid,
    Divider,
    FormControl,
    RadioGroup,
    Radio,
    styled,
    Switch,
    CircularProgress,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PhoneIcon from '@mui/icons-material/Phone';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CloseIcon from '@mui/icons-material/Close';
import { useHandleUnauthorized } from "../../hooks/UserSession";
import { getPOAndSlip, getPOItems, getPOSlip } from "../../services/PoService";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import dayjs from "dayjs";
import DeleteIcon from '@mui/icons-material/Delete';
import FileUpload from "../fileUploader/FileUpload";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { uploadBill } from "../../services/DeliverySlipService";
import { getCOAAccountTypes } from "../../services/JobLedgerService";

interface MaterialData {
    id: number;
    name: string;
    cost: number;
    code: string;
    project: string;
    vendor: string;
    unitCost: string;
    customer: string;
    quantity: string;
}
const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));


const PODeliveryCheckList: React.FC<any> = (props: any) => {
    console.log(props)
    const { handleUnauthorized } = useHandleUnauthorized();
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [bill, setBill] = useState<any[]>([]); // Initialize with an empty array
    const [type, setType] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [billNo, setBillNo] = useState('');
    const [billDate, setBillDate] = useState('');
    const [pOId, setPOId] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState(true);
    const [isPaid, setIsPaid] = useState(false);
    const [isAck, setIsAck] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [accountType, setAccountType] = useState('');
    const [paid, setPaid] = useState('');
    const [totalCost, setTotalCost] = React.useState<any>('');
    const [poData, setPoData] = React.useState({} as any);
    const [newRows, setNewRows] = React.useState<any>([]);
    const [updated, setUpdated] = React.useState(false);

    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [dcuploaded, setDcUploaded] = React.useState(false);
    const [createConfirmSuccess, setCreateComfirmSuccess] = React.useState(false);
    const [createConfirmError, setCreateCofirmError] = React.useState(false);

    const [createError, setCreateError] = React.useState(false);
    const [dragOver, setDragOver] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<any>([]);
    const [selectedfile, SetSelectedFile] = useState<{ filename: any; filetype: any; fileimage: string | ArrayBuffer | null; datetime: any; filesize: string; }[]>([]);
    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const [vendorAddressList, setVendorAddressList] = React.useState<any>();
    const [vendorAddress, setVendorAddress] = React.useState<any>();
    const [vendorName, setVendorName] = React.useState<any>();
    const [vendorList, setVendorList] = React.useState<any>([]);
    const [accountList, setAccountList] = React.useState<any>([]);
    const [count, setCount] = React.useState<any>(0);
    const [openVendorForm, setOpenVendorForm] = React.useState(false);
    const [confirmResult, setConfirmResult] = React.useState('');

    const [materialData, setMaterialData] = React.useState<any>();
    const [vendorId, setVendorId] = React.useState<any>();
    const [openAddMaterialForm, setOpenAddMaterialForm] = React.useState<boolean>(false);
    const [openConfirmForm, setOpenConfirmForm] = React.useState<boolean>(false);

    const [gradeList, setGradeList] = React.useState<any>();
    const [materialList, setMaterialList] = React.useState<any>([]);
    const [material, setMaterial] = React.useState<any>(
        {
            'companyName': '',
            'name': '',
            'quantity': '',
            'unit': '',
            'totalCost': '',
            'grade': '',
            'unitCost': '',
            'vendorId': '',
            'vendorMaterialMapId': '',
            'address': '',

        }
    );

    const [billData, setBillData] = React.useState<any>(
        {
            'vendorid': '',
            'paid': Boolean,
            'paymentMethod': '',
            'reason': '',
            'receiptInfo': '',
            'total': '',
            'billNo': '',
            'billDate': new Date(),
            'accountType': '',
            'isAcknowledged': Boolean
        }
    );
    const DELIVERYSLIP = 'DeliverySlip';
    const BILL = 'Bill'
    const [selectedMaterials, setSelectedMaterials] = useState<Set<number>>(new Set());
    const [dialogOpen, setDialogOpen] = useState(false);
    const [taxShow, setTaxShow] = React.useState(false);
    const [error, setError] = React.useState(false);
    console.log(props)
    const [rows, setRows] = useState([
        {
            industry: '',
            code: '',
            description: 'E',
            quantity: '',
            unitCost: '',
            amount: '',
        },
    ]);
    const getPOItem = async () => {
        if (props.rowData) {
            console.log("hiii")
            let result = await getPOSlip(props?.projectId, props?.rowData?.purchaseOrderId, accessToken);
            console.log(result);
            handleUnauthorized(result);
            let finalData = result.data;
            var add;
            if (finalData?.customerAddress) {
                add = JSON?.parse(finalData?.customerAddress);
                finalData['customerAddress'] = add?.address
            }

            if (finalData?.status === "Draft" && finalData?.taxInfos === null) {
                finalData['taxInfos'] = [{
                    name: "",
                    percentage: "",
                    value: "",
                }]
                console.log(finalData)
                if (finalData) {
                    setPoData(finalData);

                }
            }
            else {
                if (finalData) {
                    setPoData(finalData);

                }
                console.log(finalData)

            }
            if (finalData?.status !== "Draft") {
                setTaxShow(true);
            }
            else {
                setTaxShow(false);
            }
        }

    }
    const validateRows = () => {
        // Check if all rows are valid
        for (const item of poData.poItems) {
            if (!item.description || !item.itemCode || !item.quantity || !item.rate || !item.cost) {
                console.log("hiii")
                return false;
            }
        }
        return true;
    };
    console.log(poData)
    const handleChange = (e, index) => {

        // updatedRows[index][field] = e.target.value;
        // Find the corresponding item in poItems using the id from rows
        // const updatedPoItems = poData.poItems.map(item => {
        //     if (item.id === id) {
        //         return {
        //             ...item,
        //             [field]: e.target.value, // Update the field with the new value
        //         };
        //     }
        //     return item;
        // });
        // // Update the poData with the updated poItems
        // setPoData({
        //     ...poData,
        //     poItems: updatedPoItems,

        // });
        //setRows(updatedRows);
        const { name, value } = e.target;

        // Create a copy of the poItems array
        const updatedPoItems = [...poData.poItems];

        // Update the specific item's property
        updatedPoItems[index][name] = value;
        updatedPoItems[index].totalCost = Number(poData?.poItems?.reduce((acc, item) => acc + Number(item.cost || 0), 0)).toFixed(2);
        // Update state with the modified poItems array
        setPoData({ ...poData, poItems: updatedPoItems });

        console.log(poData);
    };
    const handleFileUpload = (file: File) => {
        console.log("Uploaded file:", file);
        // Handle the uploaded file (e.g., upload it to a server)
    };

    React.useEffect(() => {
        getPOItem();
        getAccountTypes();
    }, [accessToken, props?.rowData]);
    const handleCheckboxChange = (id: number) => {
        console.log(id);
        setSelectedMaterials((prevSelected) => {
          const updatedSelected = new Set(prevSelected);
          if (updatedSelected.has(id)) {
            updatedSelected.delete(id); // Deselect the item
          } else {
            updatedSelected.add(id); // Select the item
          }
          return updatedSelected;
        });
console.log(selectedMaterials)
      };
// To retrieve the selected details
const selectedDetails = Array.from(selectedMaterials).map((index) => poData.poItems[index]);

  
    const calculateTotalCost = () => {
        return Array.from(selectedMaterials).reduce(
            (total, id) => total + (poData?.poItems?.find((material) => material.id === id)?.cost || 0),
            0
        );
    };
    const onInputTaxNameChange = (e: any, index: any) => {
        let newArray = [...poData?.taxInfos];
        newArray[index][e.target.name] = e.target.value;
        setPoData({ ...poData, taxInfos: newArray });
    };
    const handleRemoveItem = (index: any) => {
        let newArray = [...poData?.taxInfos];
        newArray.splice(index, 1)
        let totalTaxAmount = 0
        newArray?.map((d: any) => {
            if (typeof (d.value) !== "string") {
                totalTaxAmount = totalTaxAmount + d.value;
            }
        })
        newRows.pop();
        let totalPoAmount = poData?.subTotal + totalTaxAmount;
        setPoData({ ...poData, taxInfos: newArray, purchaseAmount: totalPoAmount });
    }
    const onInputChange = (e: any, index: any, subTotal: any) => {
        // if ((e.target.value) >= 0) {
        //     let taxValue = (e.target.value * subTotal) / 100;
        //     let newArray = [...poData?.taxInfos];
        //     newArray[index][e.target.name] = e.target.value;
        //     newArray[index]["value"] = taxValue;
        //     let totalTaxAmount = 0
        //     poData?.taxInfos?.map((d: any) => {
        //         if (typeof (d.value) !== "string") {
        //             totalTaxAmount = totalTaxAmount + d.value;
        //         }
        //     })
        //     let totalPoAmount = subTotal + totalTaxAmount;
        //     setPoData({ ...poData, taxInfos: newArray, purchaseAmount: totalPoAmount });
        // }
        const { name, value } = e.target;

        // Create a copy of the poItems array
        const updatedPoItems = [...poData.poItems];

        // Update the specific item's property
        updatedPoItems[index][name] = value;
        updatedPoItems[index].totalCost = Number(poData?.poItems?.reduce((acc, item) => acc + Number(item.cost || 0), 0)).toFixed(2);
        // Update state with the modified poItems array
        setPoData({ ...poData, poItems: updatedPoItems });

        console.log(poData);
    };
    useEffect(() => {
        console.log(poData);
        
    }, [poData]);

        const handleUploadChange = async () => {
            setOpenDialogs(true);
            console.log("Upload file:", file);
            try {
                if (file !== null && file.length > 0) {
                    const formData = new FormData();
                    file.map((file: any) => {
                        formData.append('file', file);
                    });
                    console.log(formData, file);
                    formData.append('name', type)
                        setBillData({
                            'vendorid': vendorId,
                            'paid': false,
                            'paymentMethod': '',
                            'reason': '',
                            'receiptInfo': JSON.stringify(selectedDetails ),
                            'total': Number(selectedDetails?.reduce((acc, item) => acc + Number(item.cost || 0), 0)).toFixed(2),
                            'billNo': billNo,
                            'billDate': new Date(),
                            'accountType': accountType,
                            'isAcknowledged': isAck
                        })
                        // console.log(billData)
                        formData.append('data', JSON.stringify({
                            'vendorid': vendorId,
                            'paid': isPaid,
                            'paymentMethod': paymentMethod,
                            'reason': '',
                            'receiptInfo': JSON.stringify(selectedDetails ),
                            'total': Number(selectedDetails?.reduce((acc, item) => acc + Number(item.cost || 0), 0)).toFixed(2),
                            'billNo': billNo,
                            'billDate': new Date(),
                            'accountType': accountType,
                            'isAcknowledged': isAck
                        }))
                        const data = await uploadBill(orderNo, formData, accessToken)
                        if (data?.status === 200) {
                            setCreateSuccess(true);
                        } else {
                            setCreateError(true);
                        }
                   
    
                }
                else {
                    console.error("Invalid file:", file);
                    setCreateError(true);
                }
            }
            catch (error) {
                console.error("Error uploading:", error);
            }
        };
    const addRows = () => {
        if (!validateRows()) {
            setUpdated(false);
            return;
        }

        const addNewItem = {
            cost: "",
            costCode: "",
            customerName: '',
            delivery_status: "",
            description: "",
            id: "",
            itemCode: "",
            itemStatus: "",
            quantity: "",
            rate: "",
            received_quantity: '',
            referenceType: "",
            service: "",
            totalCost: ""

        };

        // Add the new item directly to poItems
        const updatedPoItems = [...poData.poItems, addNewItem];

        // Update state
        setPoData({ ...poData, poItems: updatedPoItems });
    };

    const handlebillNo = async (e: any) => {
        setBillNo(e.target.value);
    };

    const handlebillDate = async (e: any) => {
        setBillDate(dayjs(e.$d).format('YYYY-MM-DD') + "T00:00:00.000Z");
    };

    const handlepaymentmethod = async (e: any) => {
        console.log(e.target.value)
        setPaymentMethod(e.target.value);
    };
    const handleToggle = () => {
        setIsAck((prev) => !prev);
    };
    const handleDragOver = useCallback((event: any) => {
        event.preventDefault();
        setDragOver(true);
    }, []);

    const handleDragLeave = useCallback((event: any) => {
        event.preventDefault();
        setDragOver(false);
    }, []);

    const handleDrop = useCallback(
        (event: any) => {
            event.preventDefault();
            setDragOver(false);
            const files = event.dataTransfer.files;
            console.log(files)
            if (files && files[0]) {
                handleFileChange(files[0]);
            }
        },
        []
    );

    const handleFileChange = (file: File) => {
        console.log(file);
        setLoading(true);
        setFile(file);
        const formData = new FormData();
        formData.append('file', file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setLoading(false);
            if (reader.result === null) {
                setImagePreview(null);
            } else {
                setImagePreview(reader.result as string);
            }
        };
        reader.readAsDataURL(file);
    };


    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    let images: any = [];
    const handleFileDataChange = useCallback(
        (event: any) => {
            for (let i = 0; i < event.target.files.length; i++) {
                images.push((event.target.files[i]));
                let reader = new FileReader();
                let file = event.target.files[i];
                setFile(images);
                reader.onloadend = () => {
                    SetSelectedFile((preValue) => {
                        return [
                            ...preValue,
                            {
                                // id: shortid.generate(),
                                filename: event.target.files[i].name,
                                filetype: event.target.files[i].type,
                                fileimage: reader.result,
                                datetime: event.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                                filesize: filesizes(event.target.files[i].size)
                            }
                        ]
                    });
                }
                if (event.target.files[i]) {
                    reader.readAsDataURL(file);
                }
            }
        },
        []
    );
    const onselection = async (e: any) => {
        // console.log(e.target.value)
        setAccountType(e.target.value);
    };
    const getAccountTypes = async () => {
        let result = await getCOAAccountTypes(accessToken);
        if (result.data !== undefined) {
            setAccountList(result.data);
        }
    }

    return (
        <Box>
            {/* Button to Open Dialog */}
            <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)}>
                Order  Checklist
            </Button>

            {/* Dialog */}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="lg" fullWidth>
                <DialogTitle fontSize={'20px'} fontWeight={'400'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Item Checklist
                    <IconButton edge="end" color="inherit" onClick={() => setDialogOpen(false)} sx={{ position: 'absolute', right: 15 }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box m={2} mb={3} className="invoiceHeader">
                        <Typography variant="h4" mb={2}>
                            Vendor Order Details:
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>

                            {/* PO Details */}
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="h5"
                                    sx={{ fontSize: "16px", color: "primary.secondary" }} mb={1}>
                                    Delivery Details:
                                </Typography>
                                <Box ml={1}>

                                    <Typography className='invoiceNumber' display="flex" alignItems="center"><AccountTreeIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;PO Number:&nbsp;#{poData?.id}</Typography>

                                    {/* <Typography display="flex" alignItems="center"><AccountTreeIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;Project A</Typography> */}
                                    <Typography display="flex" alignItems="center"><AccountTreeIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;Delivery Date: {poData?.deliveryDate && dayjs(poData?.deliveryDate).isValid()
                                        ? dayjs(poData?.deliveryDate).format('DD MMM YYYY') // Format or bind the valid date
                                        : "No delivery date available." // Message for invalid or empty date
                                    }</Typography>
                                </Box>
                            </Box>

                            {/* Vendor Details */}
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="h5"
                                    sx={{ fontSize: "16px", color: "primary.secondary" }} mb={1}>
                                    Vendor Details:
                                </Typography>
                                <Box ml={1}>
                                    <Typography display="flex" alignItems="center"><BadgeIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.vendorName}</Typography>
                                    <Typography display="flex" alignItems="center"><EmailIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.vendorEmail}</Typography>
                                    <Typography display="flex" alignItems="center"><ContactMailIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.vendorAddress}</Typography>
                                    <Typography display="flex" alignItems="center"><PhoneIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.vendorMobileNo}</Typography>
                                </Box>
                            </Box>

                            {/* Customer Details */}
                            <Box sx={{ flex: 1 }}>
                                <Typography variant="h5"
                                    sx={{ fontSize: "16px", color: "primary.secondary" }} mb={1}>
                                    Customer Details:
                                </Typography>
                                <Box ml={1}>
                                    <Typography display="flex" alignItems="center"><BadgeIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.customerName}</Typography>
                                    <Typography display="flex" alignItems="center"><EmailIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.customerEmail}</Typography>
                                    <Typography display="flex" alignItems="center"><ContactMailIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.customerAddress}</Typography>
                                    <Typography display="flex" alignItems="center"><PhoneIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.customerMobileNo}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>



                    <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
                        <Table>
                            <TableHead>
                                <TableRow className='tableHead'>
                                    <TableCell sx={{ maxWidth: "50px" }}>Check</TableCell>
                                    <TableCell>Item Name</TableCell>
                                    <TableCell>Code</TableCell>
                                    <TableCell>Quantity</TableCell>

                                    <TableCell>Unit Cost</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {poData?.poItems?.map((row, index) => (
                                    <TableRow key={index}>
                                        {/* <TableCell>
                                          <input
                                            type="text"
                                            value={row.industry}
                                            onChange={(e) => handleChange(e, index, 'industry')}
                                          />
                                        </TableCell> */}
                                        <TableCell size='small'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedMaterials.has(index)}
                                                        onChange={() => handleCheckboxChange(index)}
                                                    />
                                                }
                                                label=""
                                            />
                                        </TableCell>

                                        <TableCell>
                                            <TextField id="standard-basic" variant="standard"
                                                type="text"
                                                value={row?.description}
                                                onChange={(e) => handleChange(e, index)}
                                                name="description" // Add name here

                                                error={!updated && !row?.description}
                                                helperText={!updated && !row?.description ? ("enter description ") : ("")}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField id="standard-basic" variant="standard"
                                                type="text"
                                                value={row?.itemCode}
                                                name="itemCode" // Add name here

                                                onChange={(e) => handleChange(e, index)}
                                                error={!updated && !row?.itemCode}
                                                helperText={!updated && !row?.itemCode ? ("enter item code ") : ("")}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField id="standard-basic" variant="standard"
                                                type="text"
                                                value={row?.quantity}
                                                onChange={(e) => handleChange(e, index)}
                                                error={!updated && !row?.quantity}
                                                name="quantity" // Add name here

                                                helperText={!updated && !row?.quantity ? ("enter quantity ") : ("")}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField id="standard-basic" variant="standard"
                                                type="text"
                                                value={row?.rate}
                                                onChange={(e) => handleChange(e, index)}
                                                name="rate" // Add name here

                                                error={!updated && !row?.rate}
                                                helperText={!updated && !row?.rate ? ("enter rate ") : ("")}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField id="standard-basic" variant="standard"
                                                type="text"
                                                value={row?.cost}
                                                onChange={(e) => handleChange(e, index)}
                                                error={!updated && !row?.cost}
                                                name="cost" // Add name here

                                                helperText={!updated && !row?.cost ? ("enter cost ") : ("")}
                                            />
                                        </TableCell>
                                        <TableCell>

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box>
                        <Stack direction={'row'} className='alignEnd'>
                            <Button onClick={addRows}>Add Row</Button>
                        </Stack>

                    </Box>
                    <Card variant="outlined">
                        <CardContent>
                            {poData?.taxExemption === false ?
                                <Grid container rowSpacing={1} columnSpacing={1}>
                                    {poData?.taxInfos?.map((tax, index) => (
                                        <><Grid item xs={12} sm={6}>
                                        </Grid>
                                            <Grid item xs={12} sm={2}>
                                                <Box>
                                                    <TextField
                                                        label="Tax Name"
                                                        id="outlined-size-small"
                                                        size="small"
                                                        value={tax?.name}
                                                        disabled={taxShow && !newRows.includes(index)}
                                                        error={tax?.name === '' && error}
                                                        name="name"
                                                        onChange={(e) => onInputTaxNameChange(e, index)}
                                                    />
                                                </Box>
                                            </Grid><Grid item xs={6} sm={2} className='alignEnd'>
                                                <Box>
                                                    <TextField
                                                        label="Percentage"
                                                        id="outlined-size-small"
                                                        size="small"
                                                        value={tax?.percentage}
                                                        name="percentage"
                                                        type='number'
                                                        error={tax?.percentage === '' && error}
                                                        disabled={taxShow && !newRows.includes(index)}
                                                        onChange={(e) => onInputChange(e, index, poData?.subTotal)}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} sm={2} className='alignEnd'>
                                                <Box>
                                                    <Stack direction={'row'} spacing={1}>
                                                        <TextField
                                                            label="Value"
                                                            id="outlined-size-small"
                                                            size="small"
                                                            type='number'
                                                            value={tax?.value ? Number(tax.value).toFixed(2) : ''}
                                                            name="value"
                                                            disabled
                                                        // onChange={(e) => onInputChange(e, index)}
                                                        />
                                                        {poData?.status === "Generated" && (poData?.taxInfos).length !== 0 && newRows.includes(index) ?
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <IconButton onClick={() => handleRemoveItem(index)}>
                                                                    <DeleteIcon fontSize='small'
                                                                    />
                                                                </IconButton>
                                                            </Box>
                                                            :
                                                            <></>
                                                        }
                                                    </Stack>
                                                </Box>
                                            </Grid>

                                        </>
                                    ))}
                                </Grid> :
                                <></>
                            }
                            {/* {poData?.status === "Generated" && poData?.taxExemption === false ?
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={12} sm={7}>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                            </Grid>
                            <Grid item xs={6} sm={3} className='alignEnd'>
                                <Box>
                                    <Stack direction={'row'}>
                                        <Button onClick={addRows}>Add Row</Button>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid> :
                        <></>
                    } */}
                            {/* <Grid container rowSpacing={2} columnSpacing={2} paddingBottom={3} paddingTop={1}>
                                <Grid item xs={12} sm={8}>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography className='subHeader' textAlign={'right'}>Total:</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography textAlign={'right'}>${Number(poData?.purchaseAmount)?.toFixed(2)}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider></Divider> */}
                            <Grid container spacing={3} style={{ paddingTop: 15 }}>
                                {/* Row 1: Bill Number and Bill Date */}
                                <Grid item xs={12} sm={6}>
                                    <Typography m={1}>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="demo-helper-text-aligned"
                                                label="Bill Number"
                                                name="billNumber"
                                                size="small"
                                                onChange={(e) => handlebillNo(e)}
                                                value={billNo}
                                            />
                                        </FormControl>
                                    </Typography>
                                    <Typography m={1}>
                                        <FormControl fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker
                                                        label="Billing Date"
                                                        slotProps={{
                                                            textField: { fullWidth: true, size: 'small', helperText: billDate !== undefined ? 'Please fill this field' : '' },
                                                        }}
                                                        value={billDate !== undefined ? dayjs(billDate) : null}
                                                        onChange={handlebillDate}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} sm={6} paddingTop={20}>
                                    <Typography m={1}>
                                <FormControl variant="outlined" fullWidth size="small">
                                                            <InputLabel id="demo-select-small" required>Account Type</InputLabel>
                                                            <Select
                                                                // error={selectedData.assetUid === undefined && completed}
                                                                required
                                                                labelId="demo-select-small-label"
                                                                id="demo-select-small"
                                                                value={accountType}
                                                                label="Account Type"
                                                                onChange={(e) => onselection(e)}
                                                                name='accountType'
                                                            >

                                                                {accountList?.map((acc: any, index: any) => {
                                                                    return (
                                                                        <MenuItem key={index} value={acc}>{acc}</MenuItem>
                                                                    )
                                                                })
                                                                }

                                                            </Select>
                                                        </FormControl>
                                                        </Typography>
                                    <Typography m={1}>

                                        <FormControl>
                                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                                <FormControlLabel value="Jobcost" control={<Radio />} label="Jobcost" />
                                                <FormControlLabel value="Overhead" control={<Radio />} label="Overhead" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Typography>
                                </Grid>
                                {/* Row 2: Radio Group */}
                                <Grid item xs={12} pt={1}>
                                                <Box>
                                                    <Paper
                                                        variant="outlined"
                                                        onDragOver={handleDragOver}
                                                        onDragLeave={handleDragLeave}
                                                        onDrop={handleDrop}
                                                        sx={{
                                                            border: "2px dashed #007BFF",
                                                            borderRadius: 2,
                                                            padding: 2,
                                                            textAlign: "center",
                                                            cursor: "pointer",
                                                            color: "#007BFF",
                                                            bgcolor: "#e6f7ff",
                                                          }}
                                                    >
                                                        <input
                                                            accept="image/*,.pdf"
                                                            style={{ display: 'none' }}
                                                            id="raised-button-file"
                                                            multiple
                                                            type="file"
                                                            onChange={handleFileDataChange}
                                                        />
                                                        <label htmlFor="raised-button-file">
                                                            <Box display="flex" flexDirection="column" alignItems="center">
                                                                <IconButton color="primary" aria-label="upload picture" component="span">
                                                                    <CloudUploadIcon style={{ fontSize: 60 }} />
                                                                </IconButton>
                                                                <Typography>Upload</Typography>
                                                                <Typography>Drag and drop files here or click to select files</Typography>
                                                            </Box>
                                                        </label>
                                                        {loading && (
                                                            <CircularProgress
                                                                size={24}
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    marginTop: '-12px',
                                                                    marginLeft: '-12px',
                                                                }}
                                                            />
                                                        )}
                                                    </Paper>

                                                    <Grid container justifyContent="center" style={{ marginTop: 16 }}>
                                                        {selectedfile.map((i: any) => {
                                                            console.log(typeof (i.fileimage))
                                                            return (
                                                                <iframe
                                                                    title="PDF Viewer"
                                                                    src={i.fileimage}
                                                                    style={{ height: '800px', width: '100%' }}
                                                                />
                                                            )
                                                        }
                                                        )}
                                                    </Grid >
                                                </Box>
                                            </Grid>

                                {/* Row 3: Payment Method */}

                                {/* <Grid item xs={12} sm={6}>
                                    <>
                                    <Grid item xs={12}>
                                    <Typography variant="h5" color="rgb(60 60 60 / 87%)">Payment Method</Typography>
                                </Grid>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={<RadioButtonUncheckedIcon />}
                                                    checkedIcon={<CheckCircleIcon />}
                                                    name="checkbox"
                                                    onChange={(e) => handlepaymentmethod(e)}
                                                    size="small"
                                                />
                                            }
                                            label="Credit Card"
                                        />
                                        {paymentMethod === "Credit Card" && (
                                            <>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<RadioButtonUncheckedIcon />}
                                                            checkedIcon={<CheckCircleIcon />}
                                                            name="checkbox"
                                                            onChange={(e) => handlepaymentmethod(e)}
                                                            size="small"
                                                        />
                                                    }
                                                    label="Personal Card"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<RadioButtonUncheckedIcon />}
                                                            checkedIcon={<CheckCircleIcon />}
                                                            name="checkbox"
                                                            value={paymentMethod}
                                                            onChange={(e) => handlepaymentmethod(3)}
                                                            size="small"
                                                        />
                                                    }
                                                    label="Company Card"
                                                />
                                            </>
                                        )}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={<RadioButtonUncheckedIcon />}
                                                    checkedIcon={<CheckCircleIcon />}
                                                    name="checkbox"
                                                    value={paymentMethod}
                                                    onChange={(e) => handlepaymentmethod(e)}
                                                    size="small"
                                                />
                                            }
                                            label="Vendor Account"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={<RadioButtonUncheckedIcon />}
                                                    checkedIcon={<CheckCircleIcon />}
                                                    name="checkbox"
                                                    value={paymentMethod}
                                                    onChange={(e) => handlepaymentmethod(e)}
                                                    size="small"
                                                />
                                            }
                                            label="Cash"
                                        />
                                          <Stack direction="row" alignItems="center" mt={1}>
                                        <FormControlLabel control={<Android12Switch onChange={handleToggle} />} label="" />
                                        <Typography variant="h6">Is Acknowledge Bill?</Typography>
                                    </Stack>
                                    </>
                                </Grid> */}

                            </Grid>

                            {/* <FileUpload onFileUpload={handleFileUpload} /> */}
                            <Box mt={2} sx={{ textAlign: 'right' }}>
                                <Typography variant="h5" sx={{ fontSize: "16px", color: "primary.secondary", textAlign: 'left' }}>
                                    Summary:
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                    <Typography ml={2} sx={{ textAlign: 'left' }}>Total Selected Items:</Typography>
                                    <Typography ml={2} sx={{ textAlign: 'right' }}>{selectedMaterials.size}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                    <Typography ml={2} sx={{ textAlign: 'left' }}>Total Cost:</Typography>
                                    <Typography ml={2} sx={{ textAlign: 'right' }}>${Number(selectedDetails?.reduce((acc, item) => acc + Number(item.cost || 0), 0)).toFixed(2)}</Typography>
                                </Box>
                            </Box>

                            {/* <Grid container rowSpacing={2} columnSpacing={2} paddingTop={3}>

                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography className='subHeader' textAlign={'right'}>Subtotal:</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography textAlign={'right'}>${poData?.subTotal !== undefined ? (poData?.subTotal).toFixed(2) : null}</Typography>
                                        <Typography ml={2}>Total Cost: ${calculateTotalCost()}</Typography>
                                    </Box>
                                </Grid>
                            </Grid> */}
                        </CardContent>
                    </Card>

                </DialogContent>

                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => alert(`Processing ${selectedMaterials.size} items with total cost $${calculateTotalCost()}`)}
                    >
                        Reject Bill
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => alert(`Processing ${selectedMaterials.size} items with total cost $${calculateTotalCost()}`)}
                    >
                        Process Selection
                    </Button>
                    <Button onClick={() => setDialogOpen(false)} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default PODeliveryCheckList;
