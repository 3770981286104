/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 20-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing of Bid added to Cart
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import * as React from 'react';
import {
  DialogContent, DialogActions,
  DialogTitle, Dialog, Box, Typography, Card, CardContent, Stack, Grid, Button, Divider, IconButton
} from '@mui/material';
import './MakePaymentList.scss'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { getUnPaidInvoice } from '../../../services/InvoiceServices';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useEffect, useState } from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useNavigate } from 'react-router-dom';
import { TaskContext } from '../../../hooks/UserSession';


const MakePaymentList: React.FC<any> = (props,unPaidDetails) => {
  const navigate = useNavigate();

  // console.log(unPaidDetails, props)
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [unPaid, setUnPaid] = useState<any[]>([]); // Initialize with an empty array
  const {  isTaskCompleted } = React.useContext(TaskContext);
  const [isOpen, setIsOpen] = useState(true);
  const handleOpenWithTimeout = () => {
    setIsOpen(true);
    setTimeout(() => {
      setIsOpen(false);
    }, 3000); // Adjust the delay (in milliseconds) as needed
  };
  // const fetchUnPaidDetails = async () => {

  //   try {

  //     const response = await getUnPaidInvoice("Un Paid", accessToken);
  //     console.log(response.data);
  //     setUnPaid(response.data);

  //   } catch (error) {
  //     console.error('Error fetching user session:', error);

  //   }
  // };
 
  const formatDate = (dateString: string): any => {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }
  const onClose = () => {
    props.handleClose();

  }
  const invoiceForm = (rowData: any) => {
    console.log(rowData.projectId, rowData.id, rowData)
    navigate("/soldproduction/projectinformation/invoiceview", { state: { rowData } });
    props.handleClose();
  };
  useEffect(() => {
    // fetchUnPaidDetails();
    setUnPaid(props.unPaidDetails);
    // props.handleClose();
    handleOpenWithTimeout();

    // setUnPaid(unPaidDetails)
    // fetchRows();
  }, [isOpen]);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={props.open}
    // onClose={()=>onClose()}

    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} >
            <Typography className='unPaidHead'>Pending Payments</Typography>
          </Box>
          <Box>
            <IconButton onClick={() => onClose()}>
              <CloseIcon sx={{ color: "red" }} />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "#f8f7fa" }} >
        {unPaid?.length === 0 ? (
          <CardContent className='noInvoice'>
            <Typography variant="body1" className='noInvoiceFound'><ReceiptIcon /></Typography>
            <Typography variant="body1" className='noInvoiceFound'>No unpaid invoices found.</Typography>
          </CardContent>
        ) : (
          unPaid?.map((row: any, index: any) =>
          (
            <Card className='contractCard' >
              <CardContent >
                <Grid container className='makePayment'>
                  <Grid item xs={12} md={5}>
                    <Stack className="orderHead " direction="row" justifyContent='center'>Invoice No <span className='orderNo'>&nbsp;#{row.invoiceNo}</span></Stack>
                  </Grid>
                  <Grid item xs={12} md={5} pl={2} justifyContent="center">   <Typography className='startDateForProject'>Issue Date: <span className="issueDate">&nbsp;{formatDate(row.createdAt)}</span></Typography></Grid>
                  <Grid item xs={12} md={2}><Button className='payNow' variant="contained" onClick={() => invoiceForm(row)}><LockOutlinedIcon />&nbsp;Pay Now</Button></Grid>
                  <Grid item xs={12} md={12} mt={1}><Divider /></Grid>
                  <Grid container mt={1} justifyContent="center" className='priceStatusDate'>
                    <Grid item xs={12} md={5} >
                      <Grid item xs={12} md={12}>  <Typography className='' variant="body1" component="div">
                        <Stack>{row?.description}</Stack>
                        {/* <Stack className='customer'>By:&nbsp;Customer Name </Stack> */}
                        <Stack mt={2} direction="row">
                          {/* <Typography className='customer'>Qty: &nbsp; {row.quantity}</Typography> */}
                          <Typography  className='invoicePrice'>Price : <span className='cost'>${row.invoicedAmount}</span></Typography>
                        </Stack>
                      </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Typography className='customer'>Status</Typography>

                      <Typography variant="body1" component="div" className="unPaidStatus">
                        {row.status}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} justifyContent="center" className="dueDate">
                      <Grid item xs={12} >
                        <Typography className='customer'>Due Date</Typography>

                        <Typography className='dueDateType'>{formatDate(row.dueDate)}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>

            </Card>
          )))}



      </DialogContent>
      <DialogActions>

      </DialogActions>
    </Dialog>

  );
}


export default MakePaymentList;





