import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Icon from '@mui/material/Icon'; // Import for the icon, you can customize this as needed
import { Box, DialogContentText, Divider, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import './HavingIssue.scss';
import BugReportIcon from '@mui/icons-material/BugReport';
import RuleIcon from '@mui/icons-material/Rule';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ErrorIcon from '@mui/icons-material/Error';
import { validQuestionTrade } from '../../services/TradeConfigurationService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { addJiraContent } from '../../services/UserService';
import { useState } from 'react';
import CustomDialog from '../../pages/Dialog';
import { getTenantDetails, getVersionDetails } from '../../services/TenantService';
import AddQuestion from '../../assets/addQuestion/AddQuestion';
import { useHandleUnauthorized } from '../../hooks/UserSession';
interface SystemInfo {
    os: string;
    browser: string;
    date: string;
    memory: string | null;
    url: string;
    component?: string;
}


const HavingIssue = ({ open, onClose }) => {
    const [openDialog, setOpenDialog] = React.useState<boolean>(open);
    const [openConfirmDialog, setOpenConfirmDialog] = React.useState<boolean>(open);

    const [createSuccess, setCreateSuccess] = React.useState<any>(false);
    const [error, setError] = React.useState<any>(false);
    const [versionDetails, setVeresionDetails] = useState<any>();
    const [tenantDetails, setTenantDetails] = useState<any>();
    const [systemInfo, setSystemInfo] = useState<SystemInfo | null>(null);

    const [selectedIssue, setSelectedIssue] = React.useState<string | null>(null);
    const [question, setQuestion] = React.useState<string>('');
    const [expectedAnswer, setExpectedAnswer] = React.useState<string>('');
    const [optionInputs, setOptionInputs] = React.useState(['']); // State to store input values for all options
    const [tradeNames, setTradeNames] = React.useState<any>([]);
    const [selectedTradeItem, setSelectedTradeItem] = React.useState<any>('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');
    const { accessToken } = useOidcAccessToken();
    const [jiraContent, setJiraContent] = React.useState<any>({
        // customerUuid:'e43fcc67-5b56-4bba-b07f-d717413f8239',
        type: '',
        questions: '',
        answers: [],
        tradeName: ''

    });
    const [questionError, setQuestionError] = React.useState(false);
    const [tradeError, setTradeError] = React.useState(false);
    const { handleUnauthorized } = useHandleUnauthorized();

    // Handle change in the current option input field
    const handleInputChange = (index) => (event) => {
        const newInputs = [...optionInputs];
        newInputs[index] = event.target.value;
        setOptionInputs(newInputs);
        console.log(optionInputs)
    };
    const getSystemInfo = (): SystemInfo => {
        const os = window.navigator.userAgent || "Unknown OS";
        const browser = window.navigator.appVersion || "Unknown Browser";
        const date = new Date().toLocaleString();
        const memory = (performance as any).memory
            ? `${(performance as any).memory.usedJSHeapSize / 1048576} MB`
            : 'Not available';
        const url = window.location.href || "Unknown URL";

        return { os, browser, date, memory, url };
    };
    // Handle adding a new option
    const handleAddOption = () => {
        setOptionInputs([...optionInputs, '']); // Add a new empty string for the new TextField
        console.log(optionInputs)

    };
    const getTenantConfig = async () => {
        const response = await getTenantDetails(accessToken);
        let details = response.data;
        setTenantDetails(details);
    };
    const getVersionDetail = async () => {
        const response = await getVersionDetails(accessToken);
        handleUnauthorized(response);
        setVeresionDetails(response.data);
        //console.log(response);
    }
    const issueData = [
        {
            icon: <BugReportIcon sx={{ fontSize: '24px' }} />,
            name: 'Bug',
            description: 'Raise a bug you have encountered.',
            color: '#df8830',
            button: 'Report a Bug'
        },
        {
            icon: <ErrorIcon sx={{ fontSize: '24px' }} />,
            name: 'Issue',
            description: 'Have any issues with the application.',
            color: '#8A2BE2',
            button: 'Raise an Issue'
        },
        {
            icon: <RuleIcon sx={{ fontSize: '24px' }} />,
            name: 'Missing Functionality',
            description: 'Feel like something you need is missing?',
            color: '#808080',
            button: 'Report Missing functionality'
        },
        {
            icon: <QuestionAnswerIcon sx={{ fontSize: '24px' }} />,
            name: 'Add Question',
            description: 'Add a new Question template for a trade.',
            color: '#0098c7',
            button: 'Add Question'
        },
        {
            icon: <FeedbackIcon sx={{ fontSize: '24px' }} />,
            name: 'Feedback',
            description: 'Share your feedback on the GMS application.',
            color: '#4CAF50',
            button: 'Write Feedback'
        }
    ];

    const handleTradeNames = () => {
        validQuestionTrade(accessToken).then(res => {
            if (res.data && res.ok === true) {
                setTradeNames(res.data || []);
            }
        }).catch(error => {
            // Handle error fetching trade names
        });
    };

    const issueHandlers = {
        Bug: () => setSelectedIssue('Bug'),
        Issue: () => setSelectedIssue('Issue'),
        'Missing Functionality': () => setSelectedIssue('Missing Functionality'),
        'Add Question': () => setSelectedIssue('Add Question'),
        Feedback: () => setSelectedIssue('Feedback')
    };

    React.useEffect(() => {
        handleTradeNames();
        setSystemInfo(getSystemInfo()); // Set system info on mount

        setOpenDialog(open);
    }, [open]);

    const handleClose = () => {
        setOpenDialog(false);
        onClose();
    };

    const handleQuestionChange = (event) => {
        setQuestion(event.target.value);
        console.log(question, optionInputs)

    };
    React.useEffect(() => {
        getTenantConfig();

    }, []);

    const handleFormSubmit = async () => {
        let isValid = true;
        const systemDetails = getSystemInfo();
        console.log(systemDetails)
        if (selectedIssue === 'Add Question' && !selectedTradeItem) {
            setTradeError(true);
            isValid = false;
        } else {
            setTradeError(false);
        }
 
        // Validate question
        if (!question.trim()) {
            setQuestionError(true);
            isValid = false;
        } else {
            setQuestionError(false);
        }
        const response = await getVersionDetails(accessToken);

        // Prevent API call if form is invalid
        if (!isValid) return;
        console.log("Question:", question);
        console.log("Expected Answer:", expectedAnswer);
 
        let jiraContent = {}; // Initialize the variable to store jira content

        // Get the current date
        const currentDate = new Date().toLocaleString();

        // Get browser information
        const browserInfo = navigator.userAgent;
        const browserVersion = browserInfo.match(/(firefox|msie|chrome|safari|opr|edg(?=\/))\/?\s*(\d+)/i)?.[0] || "Unknown";

        // Get memory usage if supported by the browser
        // Get memory usage if supported by the browser
        const memoryUsage = (navigator as any).deviceMemory ? `${(navigator as any).deviceMemory} GB` : "Memory info not supported";
        const pageUrl = window.location.href;

        const commonSystemDetails = {
            date: systemDetails?.date || currentDate,
            os: systemDetails?.os || browserInfo,
            browserVersion: systemDetails?.browser || browserVersion,
            memoryUsage: systemDetails?.memory || memoryUsage,
            pageUrl: systemDetails?.url || pageUrl,
        };

        // Get the current page URL
        // Set jiraContent based on selectedIssue
        if (selectedIssue === 'Add Question') {
            jiraContent = {
                type: selectedIssue,
                questions: question,
                answers: optionInputs,
                tradeName: selectedTradeItem,
                aseVersion: response?.data.aseVersion,
                gmsCloud: '0.4.0',
                tenantApiVersion: response?.data.tenantApiVersion,
                tenantBuildDate: response?.data.tenantApiBuildDate,
                aseBuildDate: response?.data.aseBuildDate,
                ...commonSystemDetails,  // Spread common system details
            };
        } else {
            jiraContent = {
                type: selectedIssue,
                questions: question,
                answers: [], // Empty array for answers
                tradeName: '',
                aseVersion: response?.data.aseVersion,
                gmsCloud: '0.4.0',
                tenantApiVersion: response?.data.tenantApiVersion,
                tenantBuildDate: response?.data.tenantApiBuildDate,
                aseBuildDate: response?.data.aseBuildDate,
                ...commonSystemDetails,  // Spread common system details
            };
        }
        // Log the constructed jiraContent
        console.log("Final jiraContent:", jiraContent, versionDetails);
        // Pass jiraContent to the backend via the API
        addJiraContent(jiraContent, accessToken)
            .then((res) => {
                console.log(res)
                if (res.ok === true && (res.status === 200 || res.status === 201)) {
                    console.log(res);
                    setOpenConfirmDialog(true);  // Move this inside the `then` block
                    setCreateSuccess(true);
                    setError(false)
                }

            })
            .catch((error) => {
                console.error("Error adding Jira content:", error);  // Optional: handle errors
                setOpenConfirmDialog(true);  // Move this inside the `then` block

                setCreateSuccess(false);
                setError(true)
            });
        // Add logic to handle form submission
        setQuestion('');
        setExpectedAnswer('');
        setSelectedIssue(null);
        setOptionInputs([])
    };
    const GoBack = () => {
        // console.log("Question:", question);
        // console.log("Expected Answer:", expectedAnswer);
        // const jiraContent={
        //     type:selectedIssue,
        //     questions:question,
        //     answers:optionInputs
        // }
        // console.log(jiraContent);
        // addJiraContent(jiraContent,accessToken).then((res)=>console.log(res))
        // Add logic to handle form submission
        setQuestion('');
        setExpectedAnswer('');
        setSelectedIssue(null);
        setOptionInputs([])
    };
    const handleAction = (issueName: string) => {
        const handler = issueHandlers[issueName];
        if (handler) {
            handler();
        } else {
            console.error('No handler found for:', issueName);
        }
    };
    const handleSelectChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        let values = event.target.value || '';
        setSelectedTradeItem(values);
    }
    const renderForm = () => {
        let label, placeholder, multiline, rows;

        switch (selectedIssue) {
            case 'Bug':
                label = "Describe the bug you've encountered";
                placeholder = "Describe the bug in detail...";
                multiline = true;
                rows = 4;
                break;
            case 'Issue':
                label = 'Type your issue here';
                placeholder = 'Describe your issue in detail...';
                multiline = true;
                rows = 4;
                break;
            case 'Missing Functionality':
                label = 'What functionality do you need?';
                placeholder = 'Describe the missing functionality...';
                multiline = true;
                rows = 4;
                break;
            case 'Feedback':
                label = 'Share your feedback on the GMS application.';
                placeholder = 'Share your feedback on the GMS application....';
                multiline = true;
                rows = 4;
                break;
            case 'Add Question':
                return (
                    <AddQuestion onClose={onClose} />
                );

            default:
                return null;
        }

        return (
            <div>
                <TextField
                    fullWidth
                    label={label}
                    value={question}
                    onChange={handleQuestionChange}
                    margin="normal"
                    size="small"
                    placeholder={placeholder}
                    multiline={multiline}
                    rows={rows}
                    error={questionError} // Show error if validation fails
                    helperText={questionError ? "Question is required" : ""} // Show error message
                />
                <Box
                    display="flex"
                    justifyContent="right"
                    alignItems="right"
                    sx={{ paddingBottom: 2, py: 1 }}
                >
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={GoBack}
                        sx={{ marginTop: '16px', marginRight: '9px' }}
                    >
                        Go Back
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleFormSubmit}
                        sx={{ marginTop: '16px' }}
                    >
                        Submit
                    </Button>
                </Box>
            </div>
        );
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={openDialog}
                onClose={handleClose}
                maxWidth={maxWidth}
                fullWidth
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <span>What do you want to report?</span>
                        <IconButton sx={{ color: 'red' }} edge="end" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Divider />
                <DialogContent className='issueContent'>
                    <DialogContentText>
                        {selectedIssue ? renderForm() : (
                            <Grid container spacing={1}>
                                {issueData.map((item, index) => (
                                    <Grid item xs={12} sm={4} key={index}>
                                        <Card className='cardContent'>
                                            <CardContent>
                                                <Grid container alignItems="center" spacing={1}>
                                                    <Grid item>
                                                        <Icon sx={{ color: item.color }}>
                                                            {item.icon}
                                                        </Icon>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="h6">{item.name}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    className='issueDescription'
                                                >
                                                    {item.description}
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ backgroundColor: item.color }}
                                                    onClick={() => handleAction(item.name)}
                                                >
                                                    {item.button}
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </DialogContentText>
                </DialogContent>
                {!selectedIssue && (<DialogActions>

                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
                )}
            </Dialog>
            <CustomDialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                success={createSuccess}
                error={error}
                Content={createSuccess ? "Send Successfully" : "Failed"}
            />
        </React.Fragment>
    );
};

export default HavingIssue;