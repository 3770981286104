/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : For showing project list
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |    DATE     |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*                 | 23/11/2023 | S.Priyankaa   |  Added Project Cost field
*   1599          |  10/4/24   |   Nandha      |  Pay now implementation
*   1714          |  26/4/24   |   Nandha      |  crew and foreman role based pagination issue fixed
*   1714          |  26/4/24   |   Nandha      |  crew and foreman role based pagination issue fixed
*   1765          |  2/5/24    |   Nandha      |  Project list Search is not working properly
*   2129          | 31/05/2024 | Shiyam Kumar  |  Project Reschedule Api Integrations 
*   2265          | 17/06/2024 | Shiyam kumar  |  Projeect Reschedule issue fixed and response dialog Implemented
*   3157          | 31/12/2024 |   Kanimozhi   |  gantt chart admin view implemented
*/
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, TableSortLabel,
    Paper, Checkbox, IconButton, Tooltip,
    Button, Grid, TextField, Typography, InputAdornment, Stack,
    Backdrop,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import {
    Print as PrintIcon,
    Share as ShareIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBillList, getVendor } from '../../services/BillService';
import { useContext, useEffect, useState } from 'react';
import { getAllProjectByStatus, getAllProjectCountByStatus, getAssigneesProjectByStatus, getAssigneesProjectCountByStatus, getProjectByCustomerId, getProjectByPOAndStatus, getProjectByStatus, getProjectCountByCustomerId, getProjectCountByPOAndStatus, getProjectCountByStatus, getSearch, getSearchBySales, projectReschedule, reschedule } from '../../services/ProjectService';
// import { getAllProjectByStatus, getAllProjectCountByStatus, getAssigneesProjectByStatus, getAssigneesProjectCountByStatus, getProjectByCustomerId, getProjectByStatus, getProjectCountByCustomerId, getProjectCountByStatus, getSearch,  } from '../../services/ProjectService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import GanttDialog from '../ganttchart/components/GanttDialog';
import { TaskContext, UserContext, getUserSession, setCurrentPageDetails, useHandleUnauthorized } from '../../hooks/UserSession';
import {  getTaskByProjectId } from '../../services/TaskService';
import { getAllTrade, getManufacturer } from '../../services/MaterialMangementService';
import dayjs from 'dayjs';
import SearchIcon from "@mui/icons-material/Search";
import Header from '../Header';
import CircularProgress from '@mui/material/CircularProgress';
import { getUnPaidInvoice } from '../../services/InvoiceServices';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import gmslogo from "../../assets/logo.gif";
import CustomDialog from '../../pages/Dialog';
import GanttParentPage from '../../pages/GanttParentPage';
import { ClearIcon } from '@mui/x-date-pickers';
import GanttChart from '../ganttchart/components/GanttChart';


interface Data {
    id: number;
    project: string;
    trade: string;
    manufacture: string;
    startdate: string;
    cost: string;
    state: number;
}



function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Order No.',
    },
    {
        id: 'project',
        numeric: false,
        disablePadding: true,
        label: 'Project Name',
    },
    {
        id: 'trade',
        numeric: true,
        disablePadding: false,
        label: 'Trade',
    },
    {
        id: 'startdate',
        numeric: true,
        disablePadding: false,
        label: 'Start Date',
    },
    {
        id: 'cost',
        numeric: true,
        disablePadding: false,
        label: 'Cost',
    },
    {
        id: 'state',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },

];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    return (
        <>
            <TableHead >
                <TableRow className='tablerows tableHead'>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>

                    {headCells.map((headCell) => (
                        // console.log(headCells),
                        <TableCell
                            key={headCell.id}
                            // align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel

                                // active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    {/* <TableCell className='tablerows '>Customer Name</TableCell>  */}
                    <TableCell style={{ width: '450px' }}>Action</TableCell>

                </TableRow>
            </TableHead>
        </>
    );
}

const ProjectList: React.FC<any> = () => {

    const [order, setOrder] = React.useState<Order>('asc');
    const { isTaskCompleted } = React.useContext(TaskContext);
    const status = isTaskCompleted ? "Due" : "";
    const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
    const [selected, setSelected] = React.useState<any>();
    const [selectedTrade, setSelectedTrade] = React.useState<any>();
    const [selectedManu, setSelectedManu] = React.useState<any>();
    const [trades, setTrades] = React.useState<any>();
    const [manufacturer, setManufacturer] = React.useState<any>();
    const [selectedproject, setSelectedproject] = React.useState<any>();
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [rows, setRows] = useState<any>([]);
    const [projectdata, setData] = useState<any>([]);
    // const [loading, setLoading] = useState(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [count, setCount] = useState(Number);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [user, setUser] = useState<any>();
    // const [tasks, setTasks] = React.useState([]);
    const [tasks, setTasks] = useState<any>([]);
    const [ganttData, setGanttData] = useState<any[]>([]);
    const SALESPERSONVISITSTATUS = 'Salesperson Visit';
    // const SALESPERSONVISITSTATUS = 'Contract Approval';
    const CONTRACT = 'Contract Upload';
    const CONTRACTAPPROVAL = "Contract Approval";
    const PROJECTACTIVESTATUS = 'Project Active';
    const PAYMENT = 'Payment';
    const POGENERATION = 'PO Generation';
    const SALESPERSONAPPROVALSTATUS = 'Payment Approval';
    const ONHOLD = "On Hold";
    const customer = "customer";
    const sales = "sales";
    const admin = "admin";
    const PROJECTCOMPLETE = "Project Complete"
    const PROJECTCLOSED = "Project Closed";
    const CONSTRUCTIONCOMPLETE = "Construction Complete"
    const statuses = ["Payment", "Project Active", "Payment Approval", "PO Generation", "On Hold", "Construction Complete", "Project Complete", "Project Closed"];
    const statusesAll = ["Payment", "Project Active", "Payment Approval", "PO Generation", "On Hold", "Construction Complete", "Project Complete", "Project Closed"];
    const [unPaid, setUnPaid] = useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const location = useLocation();
    const { userSessionDetails } = useContext(UserContext);
    const projectView = true;
    const { handleUnauthorized } = useHandleUnauthorized();

    //console.log(accessTokenPayload.oid)

    const handleClose = (state: any) => {
        setOpen(false);
    };

    const handleLoadingClose = () => {
        setIsLoading(false);
    }

    const closeDialog = () => {
        setSuccess(false);
        setError(false);
        setOpenDialog(false);
    }

    // const handleOpen = async (project: any) => {
    //     const response = await getTaskByProjectId(project.id, accessToken);
    //     setTasks(response.data);
    //     //console.log(response, tasks)
    //     setOpen(true);
    // };

    // const handleOpen = async (project: any) => {

    //     const response = await getTaskByProjectId(project.id, accessToken);
    //     // const response = await getProjectByTask(project.id, accessToken);
    //     setTasks(response.data);
    //     // setTasks(project);
    //     // console.log('project', project);
    //     // console.log('response', response.data);
    //     setOpen(true);
    // };
    const handleOpen = async (project: any) => {
        navigate("/ganttCharts", { state: { project } });
        setOpen(true); // Open the dialog immediately
        setIsLoading(true); // Set loading state
        setTasks([]); // Clear previous tasks

        try {
            const response = await getTaskByProjectId(project.id, accessToken);
            setTasks(response.data); // Set the tasks data
        } catch (error) {
            console.error("Failed to fetch tasks:", error);
        } finally {
            setIsLoading(false); // Stop the loading state
        }
    };

    const handleGantView = async () => {
        navigate("/ganttCharts");
    }

    const handleGanttDataUpdate = (updatedData: any) => {
        console.log('Gantt data updated:', updatedData);
        setGanttData(updatedData);
    };


    const [billList, setBillList] = React.useState([] as any[]);

    const onProjectInformationPage = (rowData: any, status: any) => {
        console.log(status);
        // setCurrentPageDetails(location.pathname, undefined, undefined);
        navigate("/soldproduction/projectinformation", { state: { rowData, user: userSessionDetails, status, projectView } });
    };


    const fetchData = async () => {

        // const response = await getUserSession(accessToken);
        // console.log(response);
        setUser(userSessionDetails);

        try {

            //console.log(page, rowsPerPage)
            if (userSessionDetails?.role.name === customer) {
                const res = await getProjectCountByCustomerId(accessTokenPayload.oid, statuses, accessToken);
                handleUnauthorized(res);
                setCount(res.data)
                const result = await getProjectByCustomerId(accessTokenPayload.oid, statuses, page, rowsPerPage, accessToken);
                handleUnauthorized(result);

                setData(result.data);
                setLoading(false);

            }
            else if (userSessionDetails?.role.name === sales) {
                const res = await getProjectCountByStatus(statuses, accessToken);
                handleUnauthorized(res);

                setCount(res.data)
                const result = await getProjectByStatus(statuses, page, rowsPerPage, accessToken);
                handleUnauthorized(result);

                setData(result.data);
                setLoading(false);

            }
            else if (userSessionDetails?.role.name === "purchaseOfficer") {
                const res = await getProjectCountByPOAndStatus(accessTokenPayload.oid, statuses, accessToken);
                handleUnauthorized(res);

                setCount(res.data)
                const result = await getProjectByPOAndStatus(accessTokenPayload.oid, statusesAll, page, rowsPerPage, accessToken);
                handleUnauthorized(result);

                setData(result.data);
                setLoading(false);

            }
            else if (userSessionDetails?.role.name === admin) {
                const res = await getAllProjectCountByStatus(statuses, accessToken);
                handleUnauthorized(res);

                setCount(res.data)
                const result = await getAllProjectByStatus(statuses, page, rowsPerPage, accessToken);
                handleUnauthorized(result);

                // setCount(result.data?.length);
                setData(result.data);

                // setCount(result.data?.length);
                setLoading(false);
            }
            else {
                const res = await getAssigneesProjectCountByStatus(accessTokenPayload.oid, PROJECTACTIVESTATUS, accessToken);
                handleUnauthorized(res);

                setCount(res.data)
                const result = await getAssigneesProjectByStatus(accessTokenPayload.oid, PROJECTACTIVESTATUS, page, rowsPerPage, accessToken);
                handleUnauthorized(result);

                setData(result.data);
                setLoading(false);

            }

            projectdata.map((project: any, index: any) => {
                //console.log(project.bid)
            })
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
        }
    };
    useEffect(() => {
        const fetchUnPaidDetails = async () => {

            try {

                const response = await getUnPaidInvoice("Un Paid", accessToken);
                handleUnauthorized(response);
                setUnPaid(response.data);

            } catch (error) {
                console.error('Error fetching user session:', error);

            }
        };

        const fetchRows = async () => {
            const data = await getVendor();
            handleUnauthorized (data)
            //  console.log(data)
            setRows(data);
            // console.log(item);
        };
        const fetData = async () => {
            const response = await getBillList();
            handleUnauthorized (response);

            //   console.log(response);
            setBillList(response);
        }

        const fettrade = async () => {
            const response = await getAllTrade(accessToken);
            handleUnauthorized (response);

            //console.log(response.data);
            setTrades(response.data);
        }


        fetchData();
        fetchRows();
        fetData();
        fettrade();
        fetchUnPaidDetails();
    }, []);
    useEffect(() => {
        fetchData();

    }, [userSessionDetails, rowsPerPage, page]);


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = billList.map((n: any) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        //console.log(id, projectdata)

        projectdata.map((project: any, index: any) => {
            if (project.id === id) {
                //console.log(id)
                getTask(id);
            }
        })

        // const selectedIndex = selected.indexOf(id);
        // let newSelected: readonly number[] = [];

        // if (selectedIndex === -1) {
        //     newSelected = newSelected.concat(selected, id);
        // } else if (selectedIndex === 0) {
        //     newSelected = newSelected.concat(selected.slice(1));
        // } else if (selectedIndex === selected.length - 1) {
        //     newSelected = newSelected.concat(selected.slice(0, -1));
        // } else if (selectedIndex > 0) {
        //     newSelected = newSelected.concat(
        //         selected.slice(0, selectedIndex),
        //         selected.slice(selectedIndex + 1),
        //     );
        // }
        setSelected(id);
    };

    const handleTrade = async (event: React.MouseEvent<unknown>, trade: string) => {
        console.log(trade);
        const response = await getManufacturer(trade, accessToken);
        //console.log(response.data);
        setManufacturer(response.data);
    };

    const getTask = async (id: any) => {
        console.log(id);
        const response = await getTaskByProjectId(id, accessToken);
        //console.log(response.data);
        setSelectedproject(response.data)
    }

    const billForm = (rowData: any) => {
        navigate("/bill", { state: { rowData } });

    };
    const billAddForm = () => {
        navigate("/bill");

    };
    const handleChangePage = async (event: unknown, newPage: number) => {

        console.log(newPage, rowsPerPage)
        if (user?.role?.name === 'customer') {
            const result = await getProjectByCustomerId(accessTokenPayload.oid, statuses, newPage, rowsPerPage, accessToken);
            //console.log(result.data)
            setData(result.data);
        }
        else if (user?.role?.name === 'sales') {
            const result = await getProjectByStatus(statuses, newPage, rowsPerPage, accessToken);
            // console.log(result.data)
            setData(result.data);

        }
        else if (user?.role?.name === 'admin') {
            const result = await getAllProjectByStatus(statuses, newPage, rowsPerPage, accessToken);
            //console.log(result.data)
            setData(result.data);

        }
        else if (user?.role?.name === 'purchaseOfficer') {
            const result = await getProjectByPOAndStatus(accessTokenPayload.oid, statusesAll, newPage, rowsPerPage, accessToken);
            // console.log(result);
            setData(result.data);

        }
        else {
            const result = await getAssigneesProjectByStatus(accessTokenPayload.oid, PROJECTACTIVESTATUS, newPage, rowsPerPage, accessToken);
            // console.log(result.data)
            setData(result.data);

        }

        setPage(newPage);
    };
    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });
    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value)
        const newRowsPerPage = parseInt(event.target.value);
        const newPage = Math.floor(page * rowsPerPage / newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setPage(newPage);
        // setRowsPerPage(parseInt(event.target.value));
        if (user?.role?.name === 'customer') {

            const result = await getProjectByCustomerId(accessTokenPayload.oid, statuses, newPage, newRowsPerPage, accessToken);
            //console.log(result.data)
            setData(result.data);

        }
        else if (user?.role?.name === 'sales') {
            const result = await getProjectByStatus(statuses, newPage, newRowsPerPage, accessToken);
            // console.log(result.data)
            setData(result.data);

        }
        else if (user?.role?.name === 'admin') {
            const result = await getAllProjectByStatus(statuses, newPage, newRowsPerPage, accessToken);
            //console.log(result.data)
            setData(result.data);

        }
        else if (user?.role?.name === 'purchaseOfficer') {
            const result = await getProjectByPOAndStatus(accessTokenPayload.oid, statusesAll, newPage, newRowsPerPage, accessToken);
            // console.log(result);
            setData(result.data);

        }
        else {
            const result = await getAssigneesProjectByStatus(accessTokenPayload.oid, PROJECTACTIVESTATUS, newPage, newRowsPerPage, accessToken);
            //console.log(result.data)
            setData(result.data);

        }

        // setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - billList?.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(billList, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const handleTradeChange = (event: any) => {
        console.log(event.target.value)
        setSelectedTrade(event.target.value);
    };

    const handleManu = (event: React.MouseEvent<unknown>, manu: string) => {
        console.log(manu)
        setSelectedManu(manu);
    };

    const [value, setValue] = React.useState('');


    const handleTextChange = async (event: any) => {
        console.log(event.target.value)
        setValue(event.target.value);
        if (event.target.value?.length >= 1) {
            // if(user?.role?.name ==='customer') {
            const response = await getSearch(accessTokenPayload.oid, statuses, user?.role?.name, event.target.value, accessToken);
            // console.log(response.data);
            setData(response.data);
            // }
            // else {
            // const response1 = await getSearchBySales(accessTokenPayload.oid,statuses, event.target.value, accessToken);
            // console.log(response1.data);
            // setData(response1.data);
            // }
        }


        else {

            fetchData();
        }
    };

    const rescheduleProject = async (projectId: any) => {
        setIsLoading(true);
        const result = await reschedule(projectId, "reschedule", accessToken);
        if ((result.status === 200 || result.status === 201) && result.data === "Successful") {
            setIsLoading(false);
            setOpenDialog(true);
            setSuccess(true);
            fetchData();
        } else {
            setIsLoading(false);
            setOpenDialog(true);
            setError(true);
        }
    }

    const invoiceForm = (rowData: any) => {
        setCurrentPageDetails(location.pathname, undefined, undefined);
        navigate("/soldproduction/projectinformation/invoiceview", { state: { rowData } });
    };
    const SearchBar = () => (

        <div className='searchBar'>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                <Grid item xs={12} className='headerTable'>Project List</Grid>

                {/* <Grid item xs={12}>
          <Grid container rowSpacing={0} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
            <Grid item xs={10}></Grid>
            
          </Grid>
        </Grid> */}

                {/* <Grid item xs={2} mt={2}>
                    {rows.map((vendor: any, index: any) => {
                        return (<Autocomplete
                            value=""
                            // onChange={(event, newValue) => {
                            //     setValue(newValue);
                            // }}
                            options={vendor.vendor}
                            renderInput={(params) => <TextField {...params} variant="outlined" label="Choose your Customer" />}
                            renderOption={(props, option: any) => <li {...props}>{option}</li>}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            freeSolo
                            size='small'
                            PaperComponent={({ children }) => {
                                return (
                                    <Paper>
                                        {children}
                                        <Button
                                            color="primary"
                                            fullWidth
                                            sx={{ justifyContent: "flex-start", pl: 2 }}
                                            onMouseDown={() => {
                                                // console.log("Add new");
                                            }}
                                        >
                                            + Add New Vendor
                                        </Button>
                                    </Paper>
                                );
                            }}
                        />
                        )
                    })}
                </Grid> */}
                {/* <Grid item xs={2} mt={2}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-select-small-label">Trade</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={selectedTrade}
                            label="Age"
                            onChange={handleTradeChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {trades?.map((trade: any) => {
                                return (
                                    <MenuItem value={trade} onClick={(event) => handleTrade(event, trade)}>{trade}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid> */}
                {/* <Grid item xs={2} mt={2}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-select-small-label">Manufacturer</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={selectedManu}
                            label="Age"
                            
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {manufacturer?.map((manu: any) => {
                                return (
                                    <MenuItem value={manu} onClick={(event) => handleManu(event, manu)}>{manu}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl></Grid> */}
                <Grid item xs={6} mb={2}>
                    <TextField id="outlined-basic" label="Search" variant="outlined" size='small' value={value} onChange={handleTextChange} />
                </Grid>
                <Grid item xs={2} ></Grid>
                <Grid item xs={4} className='createInvoice' sx={{ textAlign: "end" }}>
                    <Grid item xs={12} mb={2} >
                        <Tooltip title="Print">
                            <IconButton>
                                <PrintIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Share">
                            <IconButton>
                                <ShareIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton>
                                <SettingsIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                onClick={handleLoadingClose}
            >
                {/* <CircularProgress color="inherit" /> */}
                <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
                {/* <Typography className="loading" variant='h4' color="blue" >Loading . . .</Typography> */}
            </Backdrop>

            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {/* <Grid item xs={1}></Grid> */}
                <Grid ><Header title="Project List" subtitle="" /></Grid>
                <Box sx={{ width: '100%', }}>
                    <Paper className='FinanceCard' sx={{ width: '100%', mb: 2 }}>

                        {/* <SearchBar /> */}
                        <br />
                        <br />
                        <Grid item md={12} ml={2} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
    <TextField
        label="Search by trade, customer"
        size="small"
        name="materialName"
        onChange={(e) => handleTextChange(e)}
        value={value}
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <IconButton>
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            ),
        }}
    />
    {user?.role?.name === 'admin' && (
        <Button  sx={{marginRight:'1vh'}} onClick={handleGantView}>View Gantt</Button>
    )}
</Grid>


                        <Grid item md={12}>
                            <TableContainer >
                                <Table
                                    sx={{ minWidth: 750, border: 'none', }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected?.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={projectdata?.length}
                                    />
                                    <TableBody>
                                        {loading ? (
                                            <TableRow>
                                                <TableCell colSpan={10}>
                                                    <Typography align="center">Loading...  <CircularProgress size={20} /></Typography>
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            <>
                                                {projectdata?.length === 0 ?
                                                    (<TableRow>
                                                        <TableCell colSpan={10}>
                                                            <Typography align="center">No Active Projects</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    ) : (
                                                        <>{projectdata?.map((project: any, index: any) => {
                                                            //console.log(projectdata)
                                                            //const isItemSelected = isSelected(project.projectId);
                                                            const labelId = `enhanced-table-checkbox-${index}`;
                                                            return (
                                                                billList?.map((row, index) => {
                                                                    return (
                                                                        <TableRow
                                                                            hover
                                                                            onClick={(event) => handleClick(event, project?.id)}
                                                                            role="checkbox"
                                                                            aria-checked={project?.id === selected}
                                                                            tabIndex={-1}
                                                                            key={project.id}
                                                                            selected={project?.id === selected}
                                                                            sx={{ cursor: 'pointer' }}
                                                                        >
                                                                            <TableCell padding="checkbox">
                                                                                <CustomCheckbox
                                                                                    className='check'
                                                                                    color="primary"
                                                                                    checked={project?.id === selected}
                                                                                    inputProps={{
                                                                                        'aria-labelledby': labelId,
                                                                                    }}
                                                                                />

                                                                            </TableCell>
                                                                            <TableCell >{project?.jobNo}</TableCell>

                                                                            <TableCell
                                                                                //className='invoiceRows'
                                                                                component="th"
                                                                                id={labelId}
                                                                                scope="row"
                                                                                padding="none"
                                                                            >
                                                                                {project?.trade}&nbsp;{project?.manufacturer}
                                                                            </TableCell>

                                                                            <TableCell> {project?.trade}</TableCell>
                                                                            <TableCell>{dayjs(project?.jobStartdate).format('DD MMM YYYY')}</TableCell>
                                                                            <TableCell className=' estimateCost '> ${project?.projectCost}</TableCell>
                                                                            <TableCell size='medium'>
                                                                                {project?.status === SALESPERSONVISITSTATUS && (<Stack className='statusvisit'>{project?.status}</Stack>
                                                                                )}
                                                                                {project?.status === CONTRACTAPPROVAL && (<Stack className=' contractStatus '>{project?.status}</Stack>
                                                                                )}
                                                                                {project?.status === CONTRACT && (<Stack className='statusvisit'>{project?.status}</Stack>
                                                                                )}
                                                                                {(project?.status === PROJECTACTIVESTATUS || project?.status === PROJECTCOMPLETE) && (<Stack className=' statusactive' >{project?.status}</Stack>
                                                                                )}
                                                                                {project?.status === PAYMENT && (<Stack className=' payment' >{project?.status}</Stack>
                                                                                )}
                                                                                {(project?.status === SALESPERSONAPPROVALSTATUS ||
                                                                                    project?.status === PROJECTCLOSED || project?.status === CONSTRUCTIONCOMPLETE) && (<Stack className='statusapproval'>{project?.status}</Stack>
                                                                                    )}
                                                                                {project?.status === POGENERATION && (<Stack className=' payment' >{project?.status}</Stack>
                                                                                )}
                                                                                {project?.status === ONHOLD && (<Stack className=' statusvisit' >{project?.status}</Stack>
                                                                                )}
                                                                            </TableCell>
                                                                            {/*{user?.role?.name === 'sales' && <TableCell className='invoiceRows'> </TableCell>}*/}
                                                                            <TableCell >
                                                                                {unPaid.map(item => {
                                                                                    if (project?.id === item?.projectId && item?.status === "Un Paid" && user?.role?.name === "customer") {
                                                                                        return <Button key={item?.id} onClick={() => invoiceForm(item)} className='success'> <MonetizationOnOutlinedIcon sx={{ fontSize: '12px !important' }} />&nbsp;Pay Now</Button>;
                                                                                    } else {
                                                                                        return null; // or any other fallback content
                                                                                    }
                                                                                })}
                                                                                {/* <Button className='success'> <MonetizationOnOutlinedIcon sx={{ fontSize: '12px !important' }} />&nbsp;Pay Now</Button> */}
                                                                                {project.invalid ? <Button onClick={() => rescheduleProject(project?.id)}>Refresh</Button> :
                                                                                    <><Button onClick={() => onProjectInformationPage(project?.id, project?.status)}>View</Button>
                                                                                        <Button onClick={() => handleOpen(project)}>View Gantt</Button></>}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })

                                                            )
                                                        })}</>)
                                                }
                                            </>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Grid>
                    </Paper>
                </Box>
            </Grid>

            <CustomDialog
                open={openDialog}
                onClose={closeDialog}
                success={success}
                error={error}
                Content={success ? "Project Rescheduled Successfully" : "Failed To Reschedule"} />

            {/* {tasks !== undefined && <GanttDialog open={open} selected={tasks} function={handleClose} />} */}
            {/* {tasks !== undefined && <GanttParentPage   open={open} selected={tasks} onClose={handleClose} />} */}
            {/* {open && <GanttChartComponent selected={tasks} onGanttDataUpdate={handleGanttDataUpdate}  />} */}

            {/* <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={open}
                onClose={handleClose}
                
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} >
                            <Stack direction="row" >
                                <Typography className='cartheader'>Task Gantt</Typography>
                            </Stack>
                        </Box>
                        <Box>
                            <Button onClick={handleClose} className="dangerButton"
                                endIcon={<ClearIcon />}></Button>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent >
                    <GanttChartComponent selected={tasks} onGanttDataUpdate={handleGanttDataUpdate} />
                </DialogContent>
            </Dialog> */}
            {/* <Dialog
  fullWidth={false}
  open={open}
  onClose={handleClose}
  sx={{
    '& .MuiDialog-paper': {
      width: '110vw', 
      maxWidth: '1300px',
      marginRight: '2vh',
    },
  }}
>
  <DialogTitle>
    <Box display="flex" alignItems="center">
      <Box flexGrow={1}>
        <Stack direction="row">
          <Typography className="cartheader">Task Gantt</Typography>
        </Stack>
      </Box>
      <Box>
        <Button onClick={handleClose} className="dangerButton" endIcon={<ClearIcon />}>
          Close
        </Button>
      </Box>
    </Box>
  </DialogTitle>
  <DialogContent>
    {isLoading ? (
      <Box display="flex" justifyContent="center" alignItems="center" height="300px">
        <Typography>Loading tasks...</Typography>
      </Box>    
    ) : tasks.length > 0 ? (
      <GanttChartComponent selected={tasks} onGanttDataUpdate={handleGanttDataUpdate} />
    ) : (
      <Typography>No tasks available for this project.</Typography>
    )}
  </DialogContent>
</Dialog> */}



        </>


    );
}


export default ProjectList;


