/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 27-Dec-2024
* @author      : Kanimozhi
* @since       : 0.0.1
* Requirement# :
* Purpose      : Gantt Chart task view 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/ 
* Issue/WorkItem |   DATE        |   AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 3157          |  27/12/2024    |   Kanimozhi  |   changed the layout and ui changes ,data bind implemented
* 3157          |  31/12/2024    |   Kanimozhi  |   single and multi project data implemented
* 3157          |  08/01/2025    |   Kanimozhi  |   Flag implemented
* 3157          |  21/01/2025    |   Kanimozhi  |   Flag Text ,project name fileds added
* 3157          |  23/01/2025    |   Kanimozhi  |   Filed Name changed 
*
*/


import React, { useEffect, useState } from 'react';
import { Box, CssBaseline, Typography, CircularProgress } from '@mui/material';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getResourceByUid } from '../../../services/ResourceService';
import { getAllHolidays } from '../../../services/Holiday';
import { Task } from '@mui/icons-material';
import { getProjectMembers } from '../../../services/ProjectMembersService';

interface GanttChartComponentProps {
  onGanttDataUpdate: (data: any) => void;
  selected: any;
   status:any;
   projectId:any;
}

const GanttChartComponent: React.FC<GanttChartComponentProps> = ({ onGanttDataUpdate, selected,status,projectId }) => {
  console.log('selected', projectId);
  
  // const [resources, setResources] = useState([
  //   {
  //     Name: "Resource1",
  //     Price: 27,
  //     Type: 1,
  //     Availability: "2;w#3/6/2018~3/9/2018#2",
  //   },
  //   {
  //     Name: "Drill",
  //     Price: 40,
  //     Type: 1,
  //     Availability: "15;3/26/2018~4/7/2018#-8",
  //   },
  // ]);
  

  const [ganttData, setGanttData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { accessToken } = useOidcAccessToken();
  const [holidays, setHolidays] = useState<any[]>([]);
  const layoutUrl = status === 'single' ? "Layouts/GanttTreeDef.xml"  : "Layouts/GanttDef.xml"; 

  useEffect(() => {
    console.log('useeffect');
    const fetchAssignees = async (task: any) => {
      if (task.assignees && task.assignees.length > 0) {
        const assigneeNames = await Promise.all(
          task.assignees.map(async (resourceId: any) => {
            try {
              const resource = await getResourceByUid(resourceId, accessToken);
             
              return resource?.data?.name || '';
            } catch (err) {
              console.error(`Error fetching resource for ID ${resourceId}:`, err);
              return '';
            }
          })
        );
        return assigneeNames.join(', ');
      }
      const res = await getAllHolidays(accessToken);
      setHolidays(res.data);
      const dates = holidays.map(holiday => holiday.date);
      return '';
    };




    const processTasks = async () => {
      setIsLoading(true);
      const transformedTasks: any[] = [];
      let phaseIndex = 0;  
      if (status === 'single') {
        if (selected && selected.length > 0) {
          for (const phase of selected) {
            for (let taskIndex = 0; taskIndex < phase.tasks.length; taskIndex++) {
              const task = phase.tasks[taskIndex];
              const assigneeNames = await fetchAssignees(task);
  
              if (!task.taskType || task.taskType === '') {
                continue;
              }
              const childTask = {
                id: task.taskId,
                TaskId: task.taskId,
                PrimaryTaskId: `Phase-${phaseIndex}`,
                Task: task.taskName,             
                Start:task.startTime,
                End:task.endTime,
                Dependency: task.dependencies,
                Duration: task.estimatedHours,
                taskType: task.taskType,
                PrimaryTask: `Phase ${phaseIndex + 1}: ${phase.phase}`,
                GmsResource: assigneeNames,
              };
              transformedTasks.push(childTask);
            }
            phaseIndex++;
          }
        }
      } 
     
     else if (status === 'multi') {
      for (const project of selected) {
        let projectPhaseIndex = 0;
         if (!project.tasks || project.tasks.length === 0) {
          console.warn('No tasks found for project:', project);
        }
    
        for (let taskGroupIndex = 0; taskGroupIndex < project.tasks.length; taskGroupIndex++) {
          const taskGroup = project.tasks[taskGroupIndex];
    
          for (let taskIndex = 0; taskIndex < taskGroup.tasks.length; taskIndex++) {
            const task = taskGroup.tasks[taskIndex];
            const assigneeNames = await fetchAssignees(task);
    
            if (!task.taskType || task.taskType === '') {
              continue;
            }
    
            const childTask = {
              id: task.taskId,
              Project:` ${ project.jobNo}  ${ project.ProjectName} `,
              TaskId: task.taskId,
              Task: task.taskName,
              Start: task.startTime,
              End: task.endTime,
              Dependency: task.dependencies,
              Duration: task.estimatedHours,
              taskType: task.taskType,
              PrimaryTask: `Phase ${projectPhaseIndex + 1}: ${task.phase} `,
              GmsResource: assigneeNames,
              FLAGS: task.endTime,
              FLAGSTEXT: task.taskName,
              GanttGanttFlagsType: '3',
            };
            transformedTasks.push(childTask);
          }
    
          projectPhaseIndex++; 
        }
      }
    }
    
  
      setGanttData(transformedTasks);
      setIsLoading(false);
    };
  
   
      processTasks();
    }, [selected, status]);
  


  useEffect(() => {
    if (ganttData.length > 0 && window.StartTreeGrid) {
      window.StartTreeGrid();
      window.Grids.OnCustomAjax = (G: any, IO: any, data: any, func: any) => {
        MyAjax(IO, data);
      };
    } else {
      console.log('Gantt chart data is empty or StartTreeGrid is not defined');
    }
  }, [ganttData]);

  const MyAjax = (Url: any, data: string) => {
    try {
      console.log('MyAjax received data:', data);
      const parsedData = JSON.parse(data);
      console.log('Parsed data:', parsedData);
      setGanttData(parsedData);
      onGanttDataUpdate(parsedData);
    } catch (error) {
      console.error('Failed to parse data in MyAjax:', error);
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Box component="section" sx={{ p: 2 }}>
        <div className="ExampleBorder">
          <div className="ExampleMain" style={{ width: '100%' }} id="TreeGridMainTag">
            <bdo
              debug=""
              id="SampleGrid"
              // layout_url="Layouts/GanttDef.xml"
              layout_url={layoutUrl} 
              data_data={JSON.stringify({ Body: [ganttData] })}
              upload_url={`http://localhost:8080/gms-provider/api/v2/tasks/projectByTask,${accessToken}`}
              style={{ width: '100%', height: '100%', }}
            />
          </div>
        </div>
      </Box>
    </React.Fragment>
  );
};

export default GanttChartComponent;
