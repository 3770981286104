/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 22-Oct-2024
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Uploading Delivery Slip from Desktop
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import React, { useCallback } from "react";
import {
    DialogContent, DialogActions,
    DialogTitle, Dialog, Box, Typography, Card, CardContent, Grid, Button, IconButton,
    FormControl, InputLabel, Select,
    MenuItem, TextField, Paper,
    CircularProgress, FormControlLabel,
    Checkbox, createFilterOptions,
    Autocomplete, RadioGroup, Radio, Switch,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import '../paymentTypes/unPaidList/MakePaymentList'
import CloseIcon from '@mui/icons-material/Close';
import { useOidc, useOidcAccessToken } from '@axa-fr/react-oidc';
import { useEffect, useState } from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../../pages/Dialog';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { confirmDeliveyBill, uploadBill, uploadReceipt } from "../../services/DeliverySlipService";
import { getAllVendors, getMaterialsByAddress, getVendorNames, getVendorsAddressByName } from "../../services/VendorService";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VendorRegister from "../vendor/VendorRegister";
import { getGradesByNameAndVendorId, getMaterialsByNameAndGrade } from "../../services/materialService";
import AddMaterialForm from "../materialManagement/AddMaterialForm";
import AddIcon from '@mui/icons-material/Add';
import { getCOAAccountTypes } from "../../services/JobLedgerService";
import { styled } from '@mui/material/styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { useHandleUnauthorized } from "../../hooks/UserSession";
import EmailIcon from '@mui/icons-material/Email';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PhoneIcon from '@mui/icons-material/Phone';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import DeleteIcon from '@mui/icons-material/Delete';

const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));

const UploadSlip: React.FC<any> = (props) => {
    const navigate = useNavigate();

    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [bill, setBill] = useState<any[]>([]); // Initialize with an empty array
    const [type, setType] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [billNo, setBillNo] = useState('');
    const [billDate, setBillDate] = useState('');
    const [pOId, setPOId] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState(true);
    const [isPaid, setIsPaid] = useState(false);
    const [isAck, setIsAck] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [accountType, setAccountType] = useState('');
    const [paid, setPaid] = useState('');
    const [totalCost, setTotalCost] = React.useState<any>('');
    const [poData, setPoData] = React.useState({} as any);
    const [newRows, setNewRows] = React.useState<any>([]);
    const [updated, setUpdated] = React.useState(false);

    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [dcuploaded, setDcUploaded] = React.useState(false);
    const [createConfirmSuccess, setCreateComfirmSuccess] = React.useState(false);
    const [createConfirmError, setCreateCofirmError] = React.useState(false);

    const [createError, setCreateError] = React.useState(false);
    const [dragOver, setDragOver] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<any>([]);
    const [selectedfile, SetSelectedFile] = useState<{ filename: any; filetype: any; fileimage: string | ArrayBuffer | null; datetime: any; filesize: string; }[]>([]);
    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const [vendorAddressList, setVendorAddressList] = React.useState<any>();
    const [vendorAddress, setVendorAddress] = React.useState<any>();
    const [vendorName, setVendorName] = React.useState<any>();
    const [vendorList, setVendorList] = React.useState<any>([]);
    const [accountList, setAccountList] = React.useState<any>([]);
    const [count, setCount] = React.useState<any>(0);
    const [openVendorForm, setOpenVendorForm] = React.useState(false);
    const [confirmResult, setConfirmResult] = React.useState('');

    const [materialData, setMaterialData] = React.useState<any>();
    const [vendorId, setVendorId] = React.useState<any>();
    const [openAddMaterialForm, setOpenAddMaterialForm] = React.useState<boolean>(false);
    const [openConfirmForm, setOpenConfirmForm] = React.useState<boolean>(false);

    const [gradeList, setGradeList] = React.useState<any>();
    const [materialList, setMaterialList] = React.useState<any>([]);
    const [material, setMaterial] = React.useState<any>(
        {
            'companyName': '',
            'name': '',
            'quantity': '',
            'unit': '',
            'totalCost': '',
            'grade': '',
            'unitCost': '',
            'vendorId': '',
            'vendorMaterialMapId': '',
            'address': '',

        }
    );

    const [billData, setBillData] = React.useState<any>(
        {
            'vendorid': '',
            'paid': Boolean,
            'paymentMethod': '',
            'reason': '',
            'receiptInfo': '',
            'total': '',
            'billNo': '',
            'billDate': new Date(),
            'accountType': '',
            'isAcknowledged': Boolean
        }
    );
    const DELIVERYSLIP = 'DeliverySlip';
    const BILL = 'Bill'
    const { handleUnauthorized } = useHandleUnauthorized();

    const filter = createFilterOptions<any>();

    const handleOpenWithTimeout = () => {
        setIsOpen(true);
        setTimeout(() => {
            setIsOpen(false);
        }, 3000); // Adjust the delay (in milliseconds) as needed
    };

    const onClose = () => {
        props.handleClose();
        // window.location.reload();
    }

    useEffect(() => {
        setBill(props.billDetails);
        props?.billDetails?.map((b) => {
            Object.entries(b).map((data: any) => {
                // console.log(data[1])
            })
            //console.log(Object.entries(b))
        });
        // handleOpenWithTimeout();
        getVendors();
        getAccountTypes();
    }, [isOpen]);

    const onselect = async (e: any) => {
        setType(e.target.value);
    };

    const onselected = async (e: any) => {
        // console.log(e.target.value)
        setPOId(e.target.value);
    };

    const onselection = async (e: any) => {
        // console.log(e.target.value)
        setAccountType(e.target.value);
    };

    const handlepaymentmethod = async (e: any) => {
        console.log(e.target.value)
        setPaymentMethod(e.target.value);
    };

    const handleOrder = async (e: any) => {
        setOrderNo(e.target.value);
    };

    const handlebillNo = async (e: any) => {
        setBillNo(e.target.value);
    };

    const handlebillDate = async (e: any) => {
        setBillDate(dayjs(e.$d).format('YYYY-MM-DD') + "T00:00:00.000Z");
    };

    const handleToggle = () => {
        setIsAck((prev) => !prev);
    };


    const handleDialogClose = () => {
        setOpenDialogs(false);


    onClose();
    window.location.reload();
};
    const handleConfirmDialogClose = () => {
        setOpenConfirmForm(false);



    };
    const handleDragOver = useCallback((event: any) => {
        event.preventDefault();
        setDragOver(true);
    }, []);

    const handleDragLeave = useCallback((event: any) => {
        event.preventDefault();
        setDragOver(false);
    }, []);

    const handleDrop = useCallback(
        (event: any) => {
            event.preventDefault();
            setDragOver(false);
            const files = event.dataTransfer.files;
            console.log(files)
            if (files && files[0]) {
                handleFileChange(files[0]);
            }
        },
        []
    );

    const handleFileChange = (file: File) => {
        console.log(file);
        setLoading(true);
        setFile(file);
        const formData = new FormData();
        formData.append('file', file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setLoading(false);
            if (reader.result === null) {
                setImagePreview(null);
            } else {
                setImagePreview(reader.result as string);
            }
        };
        reader.readAsDataURL(file);
    };


    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    let images: any = [];
    const handleChange = useCallback(
        (event: any) => {
            for (let i = 0; i < event.target.files.length; i++) {
                images.push((event.target.files[i]));
                let reader = new FileReader();
                let file = event.target.files[i];
                setFile(images);
                reader.onloadend = () => {
                    SetSelectedFile((preValue) => {
                        return [
                            ...preValue,
                            {
                                // id: shortid.generate(),
                                filename: event.target.files[i].name,
                                filetype: event.target.files[i].type,
                                fileimage: reader.result,
                                datetime: event.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                                filesize: filesizes(event.target.files[i].size)
                            }
                        ]
                    });
                }
                if (event.target.files[i]) {
                    reader.readAsDataURL(file);
                }
            }
        },
        []
    );
    const addRows = () => {
        if (!validateRows()) {
            setUpdated(false);
            return;
        }

        const addNewItem = {
            cost: "",
            costCode: "",
            customerName: '',
            delivery_status: "",
            description: "",
            id: "",
            itemCode: "",
            itemStatus: "",
            quantity: "",
            rate: "",
            received_quantity: '',
            referenceType: "",
            service: "",
            totalCost: ""

        };

        // Add the new item directly to poItems
        const updatedPoItems = [...poData.poItems, addNewItem];

        // Update state
        setPoData({ ...poData, poItems: updatedPoItems });
    };

    const handleUploadChange = async () => {
        setOpenDialogs(true);
        console.log("Upload file:", file);
        try {
            if (file !== null && file.length > 0) {
                const formData = new FormData();
                file.map((file: any) => {
                    formData.append('file', file);
                });
                console.log(formData, file);
                formData.append('name', type)
                if (type === DELIVERYSLIP) {
                    const data = await uploadReceipt(pOId[0], pOId[1], formData, accessToken)
                    if (data?.status === 200) {
                        console.log("Upload successful:", data);
                        console.log(data);
                        handleUnauthorized(data);
                        let finalData = data.data;
                        var add;
                        if (finalData?.customerAddress) {
                            add = JSON?.parse(finalData?.customerAddress);
                            finalData['customerAddress'] = add?.address
                        }

                        if (finalData?.status === "Draft" && finalData?.taxInfos === null) {
                            finalData['taxInfos'] = [{
                                name: "",
                                percentage: "",
                                value: "",
                            }]
                            //  console.log(finalData)
                            if (finalData) {
                                setPoData(finalData);

                            }
                        }
                        else {
                            if (finalData) {
                                setPoData(finalData);

                            }
                            console.log(finalData)

                        }
                        // if (finalData?.status !== "Draft") {
                        //     setTaxShow(true);
                        // }
                        // else {
                        //     setTaxShow(false);
                        // }
                        setDcUploaded(true);
                        // setCreateSuccess(true);
                    } else {
                        setCreateError(true);
                        console.error("Upload failed:", data);
                    }
                } else if (type === BILL) {
                    setBillData({
                        'vendorid': vendorId,
                        'paid': isPaid,
                        'paymentMethod': paymentMethod,
                        'reason': '',
                        'receiptInfo': JSON.stringify(materialList),
                        'total': totalCost,
                        'billNo': billNo,
                        'billDate': new Date(),
                        'accountType': accountType,
                        'isAcknowledged': isAck
                    })
                    // console.log(billData)
                    formData.append('data', JSON.stringify({
                        'vendorid': vendorId,
                        'paid': isPaid,
                        'paymentMethod': paymentMethod,
                        'reason': '',
                        'receiptInfo': JSON.stringify(materialList),
                        'total': totalCost,
                        'billNo': billNo,
                        'billDate': new Date(),
                        'accountType': accountType,
                        'isAcknowledged': isAck
                    }))
                    const data = await uploadBill(orderNo, formData, accessToken)
                    if (data?.status === 200) {
                        setCreateSuccess(true);
                    } else {
                        setCreateError(true);
                    }
                } else {

                }

            }
            else {
                console.error("Invalid file:", file);
                setCreateError(true);
            }
        }
        catch (error) {
            console.error("Error uploading:", error);
        }
    };

    const getVendors = async () => {
        // let result = await getAllVendors(accessToken);
        let result = await getVendorNames(accessToken);
        handleUnauthorized(result);
        setVendorList(result.data);
    }

    const getAccountTypes = async () => {
        let result = await getCOAAccountTypes(accessToken);
        if (result.data !== undefined) {
            setAccountList(result.data);
        }
    }

    const handleChangeVendor = (e: any, value: any) => {
        // props.changeVendorName(value?.companyName);
        if (value !== null) {
            getVendorAddressList(value?.companyName);
            setVendorName(value?.companyName);
            setMaterial({ ...material, "companyName": value?.companyName });
        }
    }

    const getVendorAddressList = async (vendorName: any) => {
        let result = await getVendorsAddressByName(vendorName, accessToken);
        console.log(result.data)
        if (result.data !== undefined) {
            setVendorAddressList(result.data);
        }
    }

    const openVendorRegForm = () => {
        setOpenVendorForm(true);
    };

    const handleChangeAddress = async (e: any) => {
        setVendorAddress(e.target.value);
        setMaterial({ ...material, "address": e.target.value });
        let res = await getMaterialsByAddress(e.target.value, accessToken);
        if (res.data !== undefined) {
            console.log(res.data)
            setMaterialData(res?.data);
            setVendorId(res?.data[0]?.vendorId);
            material['vendorId'] = res?.data[0]?.vendorId;
        }
    }

    const handleChangeMaterialName = (e: any, value: any) => {
        setMaterial({ ...material, "name": value?.name });
        material['name'] = value?.name;
        if (value?.name != null) {
            getMaterialGrades(value?.name, vendorId);
        }
        if (value?.name !== '' && material?.grade !== '') {
            getMaterials(value?.name, material?.grade);
        }
    }

    const openForm = () => {
        setOpenAddMaterialForm(true);
    }

    const getMaterialGrades = async (name: any, vendorId: any) => {
        let materials = {};
        materials["name"] = name;
        materials["vendorId"] = vendorId;

        let result = await getGradesByNameAndVendorId(materials, accessToken);
        if (result.data !== undefined || result.data !== null) {
            setGradeList(result.data);
        }
    }

    // get vendors based on material name and grade
    const getMaterials = async (materialName: any, grade: any) => {
        if (materialName !== '' && grade !== '') {
            setMaterial({ ...material, "vendorId": vendorId });
            let finalMaterialData = {};
            finalMaterialData["name"] = materialName;
            finalMaterialData["grade"] = grade;
            finalMaterialData["vendorId"] = vendorId;
            finalMaterialData["address"] = vendorAddress;
            let result = await getMaterialsByNameAndGrade(finalMaterialData, accessToken);
            if (result?.data !== undefined) {
                setMaterial({
                    ...material, "unit": (result?.data)?.unit, "unitCost": (result?.data)?.cost,
                    "vendorMaterialMapId": (result.data)?.vendorMaterialMapId, "grade": grade
                });
            }
            if (result?.data !== undefined && material?.quantity !== '') {
                setMaterial({
                    ...material, "unit": (result?.data)?.unit, "unitCost": (result?.data)?.cost,
                    "vendorMaterialMapId": (result.data)?.vendorMaterialMapId, totalCost: ((result?.data)?.cost * material?.quantity)
                });
            }
        }
    }

    const handleChangeGrade = (e: any) => {
        setMaterial({ ...material, [e.target.name]: e.target.value })
        material['grade'] = e.target.value;
        if (material?.name !== '' && e.target.value !== '') {
            getMaterials(material?.name, e.target.value);
        }
    }

    const handleChangeQuantity = (e: any) => {
        if (e.target.value > 0 || e.target.value === '') {
            setMaterial({ ...material, [e.target.name]: e.target.value, totalCost: (material?.unitCost * e.target.value) })
        }
    }

    const handleListChange = (material) => {
        if (materialList.length === 0) {
            materialList.push(material);
            calculateTotalCost(materialList);
            setMaterialList(materialList);
            setMaterial({
                'companyName': '',
                'name': '',
                'quantity': '',
                'unit': '',
                'totalCost': '',
                'grade': '',
                'unitCost': '',
                'vendorId': '',
                'vendorMaterialMapId': '',
                'address': '',
            })
        }
        else {
            const updatedMaterials = [
                ...materialList,
                material,
            ];
            calculateTotalCost(updatedMaterials);
            setMaterialList(updatedMaterials);
            setMaterial({
                'companyName': '',
                'name': '',
                'quantity': '',
                'unit': '',
                'totalCost': '',
                'grade': '',
                'unitCost': '',
                'vendorId': '',
                'vendorMaterialMapId': '',
                'address': '',

            })
        }
    }

    const handlePaid = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "Yes") {
            setPaid(event.target.value)
            setIsPaid(true);
        } else {
            setPaid(event.target.value)
            setIsPaid(false);
        }
    }
    const validateRows = () => {
        // Check if all rows are valid
        for (const item of poData.poItems) {
            if (!item.description || !item.itemCode || !item.quantity || !item.rate || !item.cost) {
                console.log("hiii")
                return false;
            }
        }
        return true;
    };

    const calculateTotalCost = (materialList: any) => {
        let total = 0;
        for (let i = 0; i < materialList?.length; i++) {
            total = materialList[i]?.totalCost + total;
        }
        setTotalCost(total.toString());
    }
    const handleChanged = (e, id, field) => {
        // updatedRows[index][field] = e.target.value;
        // Find the corresponding item in poItems using the id from rows
        const updatedPoItems = poData.poItems.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    [field]: e.target.value, // Update the field with the new value
                };
            }
            return item;
        });
        // Update the poData with the updated poItems
        setPoData({
            ...poData,
            poItems: updatedPoItems.poItems,

        });
        //setRows(updatedRows);
    };
    const onInputChange = (e: any, index: number) => {
        const { name, value } = e.target;

        // Create a copy of the poItems array
        const updatedPoItems = [...poData.poItems];

        // Update the specific item's property
        updatedPoItems[index][name] = value;
        updatedPoItems[index].totalCost = Number(poData?.poItems?.reduce((acc, item) => acc + Number(item.cost || 0), 0)).toFixed(2);
        // Update state with the modified poItems array
        setPoData({ ...poData, poItems: updatedPoItems });

        console.log(poData);
    };

    const handleRemoveItem = (index: any) => {
        let newArray = [...poData?.poItems];
        newArray.splice(index, 1)
        // setInvoiceData({ ...invoiceData, taxInfo: newArray });
        let totalTaxAmount = 0

        newRows.pop();
        setPoData({ ...poData, poItems: newArray });
    }
    const ConfirmDeliveryItems = () => {
        if (!validateRows()) {
            setUpdated(false);
            return;
        }
        const res = confirmDeliveyBill(poData?.receiptId, poData, accessToken).then(res => {
            console.log(res);
            if (res.data) {
                setOpenConfirmForm(true);
                if (res.data === "Confirmed delivery") {
                    console.log("hiii")
                    setCreateComfirmSuccess(true);
                    setConfirmResult(res.data);
                }
                else {
                    setOpenConfirmForm(true);
                    setCreateCofirmError(true);
                    setConfirmResult(res.data);

                }

            }
            else {
                setOpenConfirmForm(true);
                setCreateCofirmError(true)
            }
        });
        console.log(res);
    }
    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={props.open}
            // onClose={()=>onClose()}
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} >
                            <Typography className='unPaidHead'>Upload Receipt/Bill</Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={() => onClose()}>
                                <CloseIcon sx={{ color: "red" }} />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ backgroundColor: "#f8f7fa" }} >

                    {(bill?.length === 0 && !dcuploaded) ? (
                        <CardContent className='noInvoice'>
                            <Typography variant="body1" className='noInvoiceFound'><ReceiptIcon /></Typography>
                            <Typography variant="body1" className='noInvoiceFound'>No purchase order found.</Typography>
                        </CardContent>
                    ) : (
                        bill?.map((row: any, index: any) =>
                        (
                            !dcuploaded && (
                                <Card className='contractCard' >
                                    <CardContent >
                                        <Grid container className='makePayment'>
                                            <Grid item xs={6}>
                                                <Grid container spacing={2}>
                                                    {!dcuploaded && (
                                                        <>
                                                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                                                <Typography variant='h5' color="rgb(60 60 60 / 87%)">Upload Type</Typography>
                                                            </Grid>
                                                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                                                <FormControl variant="outlined" fullWidth size="small">
                                                                    <InputLabel id="demo-select-small" required>Type</InputLabel>
                                                                    <Select
                                                                        // error={selectedData.assetUid === undefined && completed}
                                                                        required
                                                                        labelId="demo-select-small-label"
                                                                        id="demo-select-small"
                                                                        value={type}
                                                                        label="Type"
                                                                        onChange={(e) => onselect(e)}
                                                                        name='Type'
                                                                    >
                                                                        <MenuItem value="DeliverySlip">Delivery Slip</MenuItem>
                                                                        <MenuItem value="Receipt">Receipt</MenuItem>
                                                                        <MenuItem value="Bill">Bill</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                        </>
                                                    )}
                                                    {type === BILL && <Grid item xs={12} sm={9} md={9} lg={9} xl={9} mb={2}>


                                                        <FormControl fullWidth >
                                                            <TextField
                                                                id="demo-helper-text-aligned"
                                                                label="Project Order No"
                                                                name="orderNo"
                                                                size="small"
                                                                fullWidth

                                                                onChange={(e) => handleOrder(e)}
                                                                value={orderNo}
                                                            />
                                                        </FormControl>
                                                        <FormControl fullWidth style={{ paddingTop: 15 }}>
                                                            <TextField
                                                                id="demo-helper-text-aligned"
                                                                label="Bill Number"
                                                                name="billNumber"
                                                                size="small"
                                                                fullWidth
                                                                onChange={(e) => handlebillNo(e)}
                                                                value={billNo}
                                                            />
                                                        </FormControl>
                                                        <FormControl fullWidth style={{ paddingTop: 15 }}>
                                                            {/* <TextField
                                                            id="demo-helper-text-aligned"
                                                            label="Bill Date"
                                                            name="billDate"
                                                            size="small"
                                                            fullWidth
                                                            onChange={(e) => handlebillDate(e)}
                                                            value={billDate}
                                                        /> */}
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DemoContainer components={['DatePicker']} >
                                                                    <DatePicker label="Date"
                                                                        slotProps={{ textField: { fullWidth: true, size: 'small', helperText: (billDate !== undefined) ? 'Please fill this field' : '' } }}
                                                                        value={billDate !== undefined ? dayjs(billDate) : null}
                                                                        onChange={handlebillDate}
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </FormControl>


                                                        <FormControl>
                                                            {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                            // value={}
                                                            // onChange={handlePaid}
                                                            >
                                                                <FormControlLabel value="Jobcost" control={<Radio />} label="Jobcost" />
                                                                <FormControlLabel value="Overhead" control={<Radio />} label="Overhead" />

                                                            </RadioGroup>
                                                        </FormControl>



                                                    </Grid>
                                                    }
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        {(type === DELIVERYSLIP && !dcuploaded) && <Typography variant='h5' color="rgb(60 60 60 / 87%)">Purchaser Order No.</Typography>}
                                                        {type === BILL && <Typography variant='h5' color="rgb(60 60 60 / 87%)">Account Type</Typography>}
                                                    </Grid>
                                                    <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                                        {(type === DELIVERYSLIP && !dcuploaded) && (

                                                            <FormControl variant="outlined" fullWidth size="small">
                                                                <InputLabel id="demo-select-small" required>Purchase Order No</InputLabel>
                                                                <Select
                                                                    // error={selectedData.assetUid === undefined && completed}
                                                                    required
                                                                    labelId="demo-select-small-label"
                                                                    id="demo-select-small"
                                                                    value={pOId[1]}
                                                                    label="Purchase Order Number"
                                                                    onChange={(e) => onselected(e)}
                                                                    name='purchaseOrder'
                                                                >
                                                                    {Object.entries(row).map((data: any) => {
                                                                        return (
                                                                            data[1]?.map((purchaseId: any, index: any) => {
                                                                                return (
                                                                                    <MenuItem key={index} value={[data[0], purchaseId]}>{purchaseId}</MenuItem>
                                                                                )
                                                                            })
                                                                        )
                                                                    })}
                                                                </Select>
                                                            </FormControl>

                                                        )

                                                        }
                                                        {type === BILL && <><FormControl variant="outlined" fullWidth size="small">
                                                            <InputLabel id="demo-select-small" required>Account Type</InputLabel>
                                                            <Select
                                                                // error={selectedData.assetUid === undefined && completed}
                                                                required
                                                                labelId="demo-select-small-label"
                                                                id="demo-select-small"
                                                                value={accountType}
                                                                label="Account Type"
                                                                onChange={(e) => onselection(e)}
                                                                name='accountType'
                                                            >

                                                                {accountList?.map((acc: any, index: any) => {
                                                                    return (
                                                                        <MenuItem key={index} value={acc}>{acc}</MenuItem>
                                                                    )
                                                                })
                                                                }

                                                            </Select>
                                                        </FormControl>
                                                            <FormControl>
                                                                {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                                    name="row-radio-buttons-group"
                                                                    value={paid}
                                                                    onChange={handlePaid}
                                                                >
                                                                    <FormControlLabel value="Paid" control={<Radio />} label="Paid" />
                                                                    <FormControlLabel value="UnPaid" control={<Radio />} label="UnPaid" />

                                                                </RadioGroup>
                                                            </FormControl>
                                                        </>
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        {type === BILL && <Typography variant='h5' color="rgb(60 60 60 / 87%)">Payment Method</Typography>}
                                                    </Grid>
                                                    <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                                        {type === BILL &&
                                                            <>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox icon={<RadioButtonUncheckedIcon />}
                                                                            checkedIcon={<CheckCircleIcon />}
                                                                            name="checkbox"
                                                                            //checked={}
                                                                            onChange={(e) => handlepaymentmethod(e)}
                                                                            size='small'
                                                                        // disabled={(assetDataObject?.isBilling === true)}
                                                                        />
                                                                    }
                                                                    label="Credit Card"
                                                                />

                                                                {paymentMethod === "Credit Card" && <><FormControlLabel
                                                                    control={
                                                                        <Checkbox icon={<RadioButtonUncheckedIcon />}
                                                                            checkedIcon={<CheckCircleIcon />}
                                                                            name="checkbox"
                                                                            //checked={}
                                                                            onChange={(e) => handlepaymentmethod(e)}
                                                                            size='small'
                                                                        // disabled={(assetDataObject?.isBilling === true)}
                                                                        />
                                                                    }
                                                                    label="Personal Card"
                                                                />
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox icon={<RadioButtonUncheckedIcon />}
                                                                                checkedIcon={<CheckCircleIcon />}
                                                                                name="checkbox"
                                                                                //checked={}
                                                                                value={paymentMethod}
                                                                                onChange={(e) => handlepaymentmethod(3)}
                                                                                size='small'
                                                                            // disabled={(assetDataObject?.isBilling === true)}
                                                                            />
                                                                        }
                                                                        label="Company Card"
                                                                    /></>
                                                                }

                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox icon={<RadioButtonUncheckedIcon />}
                                                                            checkedIcon={<CheckCircleIcon />}
                                                                            name="checkbox"
                                                                            //checked={}
                                                                            value={paymentMethod}
                                                                            onChange={(e) => handlepaymentmethod(e)}
                                                                            size='small'
                                                                        // disabled={(assetDataObject?.isBilling === true)}
                                                                        />
                                                                    }
                                                                    label="Vendor account"
                                                                />

                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox icon={<RadioButtonUncheckedIcon />}
                                                                            checkedIcon={<CheckCircleIcon />}
                                                                            name="checkbox"
                                                                            //checked={}
                                                                            value={paymentMethod}
                                                                            onChange={(e) => handlepaymentmethod(e)}
                                                                            size='small'
                                                                        // disabled={(assetDataObject?.isBilling === true)}
                                                                        />
                                                                    }
                                                                    label="Cash"
                                                                />
                                                            </>
                                                        }
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        {type === BILL && <Stack direction="row">
                                                            <FormControlLabel
                                                                control={<Android12Switch
                                                                    onChange={handleToggle} />}
                                                                label=""
                                                            />
                                                            <Grid item xs={6}>
                                                                <Typography variant="h6">
                                                                    Is Acknowledge Bill?
                                                                </Typography>
                                                            </Grid>

                                                        </Stack>
                                                        }
                                                    </Grid>

                                                    {/* <Grid item xs={12}>
                                                    {type === "Bill" &&
                                                        <>
                                                            <Grid item xs={12}>
                                                                <Typography variant='h5' color="rgb(60 60 60 / 87%)">Payment Method</Typography>
                                                            </Grid>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox icon={<RadioButtonUncheckedIcon />}
                                                                        checkedIcon={<CheckCircleIcon />}
                                                                        name="checkbox"
                                                                        //checked={}
                                                                        //onChange={(e) => { setMaterialData({ ...materialData, inStock: e.target.checked }) }}
                                                                        size='small'
                                                                    // disabled={(assetDataObject?.isBilling === true)}
                                                                    />
                                                                }
                                                                label="Self"
                                                            />

                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox icon={<RadioButtonUncheckedIcon />}
                                                                        checkedIcon={<CheckCircleIcon />}
                                                                        name="checkbox"
                                                                        //checked={}
                                                                        //onChange={(e) => { setMaterialData({ ...materialData, inStock: e.target.checked }) }}
                                                                        size='small'
                                                                    // disabled={(assetDataObject?.isBilling === true)}
                                                                    />
                                                                }
                                                                label="Company or Vendor Credit"
                                                            />

                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox icon={<RadioButtonUncheckedIcon />}
                                                                        checkedIcon={<CheckCircleIcon />}
                                                                        name="checkbox"
                                                                        //checked={}
                                                                        //onChange={(e) => { setMaterialData({ ...materialData, inStock: e.target.checked }) }}
                                                                        size='small'
                                                                    // disabled={(assetDataObject?.isBilling === true)}
                                                                    />
                                                                }
                                                                label="Cash"
                                                            />
                                                        </>
                                                    }
                                                </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {!dcuploaded && (
                                            <Grid item xs={12} pt={1}>
                                                <Box>
                                                    <Paper
                                                        variant="outlined"
                                                        onDragOver={handleDragOver}
                                                        onDragLeave={handleDragLeave}
                                                        onDrop={handleDrop}
                                                        style={{
                                                            border: dragOver ? '2px dashed #000' : '2px dashed #aaa',
                                                            padding: 20,
                                                            textAlign: 'center',
                                                            cursor: 'pointer',
                                                            background: dragOver ? '#eee' : '#fafafa',
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        <input
                                                            accept="image/*,.pdf"
                                                            style={{ display: 'none' }}
                                                            id="raised-button-file"
                                                            multiple
                                                            type="file"
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="raised-button-file">
                                                            <Box display="flex" flexDirection="column" alignItems="center">
                                                                <IconButton color="primary" aria-label="upload picture" component="span">
                                                                    <CloudUploadIcon style={{ fontSize: 60 }} />
                                                                </IconButton>
                                                                <Typography>Upload</Typography>
                                                                <Typography>Drag and drop files here or click to select files</Typography>
                                                            </Box>
                                                        </label>
                                                        {loading && (
                                                            <CircularProgress
                                                                size={24}
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '50%',
                                                                    left: '50%',
                                                                    marginTop: '-12px',
                                                                    marginLeft: '-12px',
                                                                }}
                                                            />
                                                        )}
                                                    </Paper>

                                                    <Grid container justifyContent="center" style={{ marginTop: 16 }}>
                                                        {selectedfile.map((i: any) => {
                                                            console.log(typeof (i.fileimage))
                                                            return (
                                                                <iframe
                                                                    title="PDF Viewer"
                                                                    src={i.fileimage}
                                                                    style={{ height: '800px', width: '100%' }}
                                                                />
                                                            )
                                                        }
                                                        )}
                                                    </Grid >
                                                </Box>
                                            </Grid>

                                        )}
                                        {type === BILL && <Grid container spacing={2} mb={2}>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth size="small">
                                                    <Autocomplete
                                                        value={material?.companyName}
                                                        onChange={(e, newValue) => {
                                                            if (typeof newValue === 'string') {
                                                                handleChangeVendor(e, newValue);
                                                            } else if (newValue && newValue.inputValue) {
                                                                openVendorRegForm();
                                                            } else {
                                                                handleChangeVendor(e, newValue);
                                                            }
                                                        }}
                                                        // onChange={(e, newValue) => setVendorNameAndId(e, newValue,index)}
                                                        filterOptions={(options, params) => {
                                                            const filtered = filter(options, params);

                                                            const { inputValue } = params;
                                                            // Suggest the creation of a new value
                                                            const isExisting = options.some((option) => inputValue === option?.companyName);
                                                            if (inputValue !== '' && !isExisting) {
                                                                filtered.push({
                                                                    inputValue,
                                                                    companyName: `Add Vendor`,
                                                                });
                                                            }

                                                            return filtered;
                                                        }}
                                                        selectOnFocus
                                                        clearOnBlur
                                                        handleHomeEndKeys
                                                        id="free-solo-with-text-demo"
                                                        options={vendorList}
                                                        getOptionLabel={(option) => {
                                                            // Value selected with enter, right from the input
                                                            if (typeof option === 'string') {
                                                                return option;
                                                            }
                                                            // Add "xxx" option created dynamically
                                                            if (option.inputValue) {
                                                                return option.inputValue;
                                                            }
                                                            // Regular option
                                                            return option?.companyName;
                                                        }}

                                                        renderOption={(props, option) => <li {...props}>{option?.companyName}</li>}

                                                        freeSolo
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Vendor Name" size='small'
                                                                error={(vendorList?.companyName === '' && count !== 0)}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="demo-simple-select-label"
                                                    // error={(material?.address === '' && count !== 0)} 
                                                    >Vendor Address</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={material.address}
                                                        label="Vendor Address"
                                                        name="address"
                                                        onChange={(e) => handleChangeAddress(e)}
                                                        required
                                                    // disabled={vendorList?.companyName === '' || vendorList?.companyName === undefined ? true : false}
                                                    // error={(props?.address === '' && count !== 0)}
                                                    >
                                                        {vendorAddressList?.map((vendor) => (
                                                            <MenuItem value={vendor?.address}>{vendor?.address}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth size="small">
                                                    <Autocomplete
                                                        value={material?.name}
                                                        onChange={(e, newValue) => {
                                                            if (typeof newValue === 'string') {
                                                                handleChangeMaterialName(e, newValue);
                                                            } else if (newValue && newValue.inputValue) {
                                                                openForm();
                                                            } else {
                                                                handleChangeMaterialName(e, newValue);
                                                            }
                                                        }}
                                                        filterOptions={(options, params) => {
                                                            const filtered = filter(options, params);

                                                            const { inputValue } = params;
                                                            // Suggest the creation of a new value
                                                            const isExisting = options.some((option) => inputValue === option?.name);
                                                            if (inputValue !== '' && !isExisting) {
                                                                filtered.push({
                                                                    inputValue,
                                                                    name: `Add Material`,
                                                                });
                                                            }

                                                            return filtered;
                                                        }}
                                                        selectOnFocus
                                                        clearOnBlur
                                                        handleHomeEndKeys
                                                        id="free-solo-with-text-demo"
                                                        options={materialData}
                                                        getOptionLabel={(option) => {
                                                            // Value selected with enter, right from the input
                                                            if (typeof option === 'string') {
                                                                return option;
                                                            }
                                                            // Add "xxx" option created dynamically
                                                            if (option.inputValue) {
                                                                return option.inputValue;
                                                            }
                                                            // Regular option
                                                            return option.name;
                                                        }}
                                                        renderOption={(props, option) => <li {...props}>{option?.name}
                                                        </li>}
                                                        // disabled={disabledMaterial}
                                                        freeSolo
                                                        disabled={vendorName?.companyName === '' || vendorAddress?.address === '' ? true : false}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Material Name" size='small'
                                                                error={(materialData?.companyName === '' && count !== 0)}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="demo-simple-select-label" error={(material?.grade === '' && count !== 0)} >Grade</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={material?.grade}
                                                        label="Grade"
                                                        name="grade"
                                                        onChange={(e) => handleChangeGrade(e)}
                                                        required
                                                        disabled={material?.name === '' ? true : false}
                                                        error={(material?.grade === '' && count !== 0)}
                                                    >
                                                        {gradeList?.map((grade) => (
                                                            <MenuItem value={grade}>{grade}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Quanitity"
                                                        name="quantity"
                                                        size="small"
                                                        fullWidth
                                                        disabled={material?.name === '' || material?.grade === '' ? true : false}
                                                        value={material?.quantity}
                                                        error={(material?.quantity === '' && count !== 0)}
                                                        onChange={(e) => handleChangeQuantity(e)}
                                                        type="number" />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Unit"
                                                        name="unit"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={material?.unit}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Sub Total"
                                                        name="subTotal"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={material?.totalCost !== undefined || material?.totalCost !== '' ? (Number(material?.totalCost)).toFixed(2) : null}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        name="cost"
                                                        id="outlined-size-small"
                                                        size="small"
                                                        label="Unit Cost"
                                                        type="number"
                                                        disabled
                                                        value={material?.unitCost}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button onClick={() => handleListChange(material)} size="large" startIcon={<AddIcon fontSize='small' />}>Add</Button>
                                            </Grid>



                                            <Grid container spacing={2} m={1}>
                                                {materialList?.map((m: any) => {
                                                    return (
                                                        <>
                                                            <Grid item xs={2}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="demo-helper-text-aligned"
                                                                        label="Vendor Name"
                                                                        name="companyName"
                                                                        size="small"
                                                                        fullWidth
                                                                        disabled
                                                                        value={m.companyName}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="demo-helper-text-aligned"
                                                                        label="Material Name"
                                                                        name="name"
                                                                        size="small"
                                                                        fullWidth
                                                                        disabled
                                                                        value={m?.name}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="demo-helper-text-aligned"
                                                                        label="Quanitity"
                                                                        name="quantity"
                                                                        size="small"
                                                                        fullWidth
                                                                        disabled
                                                                        value={m?.quantity}
                                                                        type="number" />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="demo-helper-text-aligned"
                                                                        label="Unit"
                                                                        name="unit"
                                                                        size="small"
                                                                        fullWidth
                                                                        disabled
                                                                        value={m?.unit}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="demo-helper-text-aligned"
                                                                        label="Unit Cost"
                                                                        name="unitcost"
                                                                        size="small"
                                                                        fullWidth
                                                                        disabled
                                                                        value={m?.unit}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        id="demo-helper-text-aligned"
                                                                        label="Sub Total"
                                                                        name="subTotal"
                                                                        size="small"
                                                                        fullWidth
                                                                        disabled
                                                                        value={m?.totalCost}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            {/* <Grid item xs={1}>
                                                    <IconButton
                                                        id="demo-positioned-button"
                                                        aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={openMenu ? 'true' : undefined}
                                                        onClick={(e) => handleClick(e, index)}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Menu
                                                        id="demo-positioned-menu"
                                                        aria-labelledby="demo-positioned-button"
                                                        anchorEl={anchorEl}
                                                        open={openMenu === true && menuIndex === index ? true : false}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => editRow(index, material)}>
                                                            <Stack direction={'row'} spacing={1}>
                                                                <EditIcon fontSize='small'
                                                                />
                                                                <Typography>Edit</Typography>
                                                            </Stack>
                                                        </MenuItem>
                                                        <MenuItem onClick={() => removeRow(index)}>
                                                            <Stack direction={'row'} spacing={1}>
                                                                <DeleteIcon fontSize='small' />
                                                                <Typography>Delete</Typography>
                                                            </Stack>
                                                        </MenuItem>
                                                        <MenuItem onClick={() => addNewMaterial()}>
                                                            <Stack direction={'row'} spacing={1}>
                                                                <AddIcon fontSize='small' />
                                                                <Typography>Add</Typography>
                                                            </Stack>
                                                        </MenuItem>
                                                    </Menu>
                                                </Grid> */}
                                                        </>
                                                    )
                                                })}
                                            </Grid>
                                            <Grid xs={12}>
                                                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                                    <Grid item xs={3}>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                    </Grid>
                                                    <Grid item xs={2} className='alignEnd'>
                                                        <Typography>Total:</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography>{totalCost}</Typography>
                                                    </Grid>
                                                    <Grid item xs={1}>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        }
                                    </CardContent>
                                </Card>
                            )
                        ))
                    )}
                    {dcuploaded && (

                        <>
                            <Grid item xs={12}>
                                <Box m={2} mb={4} sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="h5"
                                            sx={{ fontSize: "16px", color: "primary.secondary" }} mb={1}>
                                            Delivery Details:
                                        </Typography>
                                        <Box ml={1}>
                                            <Typography className='invoiceNumber' display="flex" alignItems="center">&nbsp;Delivery Number:&nbsp;#{props?.receiptId}</Typography>

                                            <Typography display="flex" alignItems="center"><AccountTreeIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;PO Number:&nbsp;#{poData?.id}</Typography>

                                            {/* <Typography display="flex" alignItems="center"><AccountTreeIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;Project A</Typography> */}
                                            <Typography display="flex" alignItems="center"><AccountTreeIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;Delivery Date: {poData?.deliveryDate && dayjs(poData?.deliveryDate).isValid()
                                                ? dayjs(poData?.deliveryDate).format('DD MMM YYYY') // Format or bind the valid date
                                                : "No delivery date available." // Message for invalid or empty date
                                            }</Typography>
                                        </Box>
                                    </Box>

                                    {/* Vendor Details */}
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="h5"
                                            sx={{ fontSize: "16px", color: "primary.secondary" }} mb={1}>
                                            Vendor Details:
                                        </Typography>
                                        <Box ml={1}>
                                            <Typography display="flex" alignItems="center"><BadgeIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.vendorName}</Typography>
                                            <Typography display="flex" alignItems="center"><EmailIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.vendorEmail}</Typography>
                                            <Typography display="flex" alignItems="center"><ContactMailIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.vendorAddress}</Typography>
                                            <Typography display="flex" alignItems="center"><PhoneIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.vendorMobileNo}</Typography>
                                        </Box>
                                    </Box>

                                    {/* Customer Details */}
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="h5"
                                            sx={{ fontSize: "16px", color: "primary.secondary" }} mb={1}>
                                            Customer Details:
                                        </Typography>
                                        <Box ml={1}>
                                            <Typography display="flex" alignItems="center"><BadgeIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.customerName}</Typography>
                                            <Typography display="flex" alignItems="center"><EmailIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.customerEmail}</Typography>
                                            <Typography display="flex" alignItems="center"><ContactMailIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.customerAddress}</Typography>
                                            <Typography display="flex" alignItems="center"><PhoneIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.customerMobileNo}</Typography>
                                        </Box>
                                    </Box>


                                </Box>
                                <Typography variant="h5"
                                    sx={{ fontSize: "16px", color: "primary.secondary" }} mb={1}>Confirm Delivery Items</Typography>
                                <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow className='tableHead'>
                                                <TableCell>Item Name</TableCell>
                                                <TableCell>Code</TableCell>
                                                <TableCell>Quantity</TableCell>

                                                <TableCell>Unit Cost</TableCell>
                                                <TableCell>Amount</TableCell>
                                                <TableCell>Actions</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {poData?.poItems.length > 0 ? (
                                                poData?.poItems?.map((row, index) => (
                                                    <TableRow key={index}>
                                                        {/* <TableCell>
                                  <input
                                    type="text"
                                    value={row.industry}
                                    onChange={(e) => handleChange(e, index, 'industry')}
                                  />
                                </TableCell> */}


                                                        <TableCell>
                                                            <TextField id="standard-basic" variant="standard"
                                                                type="text"
                                                                value={row?.description}
                                                                name="description"
                                                                error={!updated && !row?.description}
                                                                helperText={!updated && !row?.description ? ("enter description ") : ("")}

                                                                onChange={(e) => onInputChange(e, index)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField id="standard-basic" variant="standard"
                                                                type="text"
                                                                value={row?.itemCode}
                                                                name="itemCode"
                                                                error={!updated && !row?.itemCode}
                                                                helperText={!updated && !row?.itemCode ? ("enter item code ") : ("")}

                                                                onChange={(e) => onInputChange(e, index)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField id="standard-basic" variant="standard"
                                                                type="text"
                                                                value={row?.quantity}
                                                                name="quantity"
                                                                error={!updated && !row?.quantity}
                                                                helperText={!updated && !row?.quantity ? ("enter quantity ") : ("")}

                                                                onChange={(e) => onInputChange(e, index)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField id="standard-basic" variant="standard"
                                                                type="text"
                                                                value={row?.rate}
                                                                name="rate"
                                                                error={!updated && !row?.rate}
                                                                helperText={!updated && !row?.rate ? ("enter rate ") : ("")}

                                                                onChange={(e) => onInputChange(e, index)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField id="standard-basic" variant="standard"
                                                                type="text"
                                                                value={row?.cost}
                                                                name="cost"
                                                                error={!updated && !row?.cost}
                                                                helperText={!updated && !row?.cost ? ("enter cost ") : ("")}

                                                                onChange={(e) => onInputChange(e, index)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <IconButton onClick={() => handleRemoveItem(index)}>
                                                                    <DeleteIcon fontSize='small'
                                                                    />
                                                                </IconButton>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            )
                                                : (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            textAlign: 'center', // Optional: Ensures text is centered
                                                            height: '100%', // Optional: Adjust height as needed
                                                        }}
                                                    >
                                                        <Stack>
                                                            <Stack><Typography>Cannot able to read Delivery Items</Typography></Stack>
                                                            <Stack> <Button onClick={addRows}>Add Row</Button></Stack>
                                                        </Stack>
                                                    </Box>


                                                )}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid item xs={6} sm={3} mb={2}>
                                    <Box>
                                        <Stack direction={'row'} className='alignEnd'>
                                            <Button onClick={addRows}>Add Row</Button>
                                        </Stack>

                                    </Box>
                                </Grid>
                                <Grid container rowSpacing={2} paddingBottom={3}>
                                    <Grid item xs={6} sm={6}>
                                        <Box>
                                            <Typography className='subHeader' textAlign="left">
                                                Total:
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <Box>
                                            <Typography textAlign="right">
                                                ${Number(poData?.poItems?.reduce((acc, item) => acc + Number(item.cost || 0), 0)).toFixed(2)}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>)}
                </DialogContent>
                <DialogActions>
                    {!dcuploaded ? <Button onClick={handleUploadChange} className='sucessButton'>Submit</Button>
                        : <Button className='sucessButton' onClick={ConfirmDeliveryItems}>Confirm</Button>}
                    <Button className='primaryTonalButton' onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <AddMaterialForm open={openAddMaterialForm}
                onClose={() => setOpenAddMaterialForm(false)}></AddMaterialForm>

            <VendorRegister
                open={openVendorForm}
                onClose={() => setOpenVendorForm(false)}
            />

            <CustomDialog
                open={openDialogs}
                onClose={handleDialogClose}
                success={createSuccess}
                error={createError}
                Content={createSuccess ? "Uploaded succesfully" : "Failed"} />
            <CustomDialog
                open={openConfirmForm}
                onClose={handleConfirmDialogClose}
                success={createConfirmSuccess}
                error={createConfirmError}
                Content={createConfirmSuccess && !dcuploaded ? "Uploaded succesfully" : dcuploaded && createConfirmSuccess ? confirmResult : dcuploaded && createConfirmError ? confirmResult : "failed"} />
        </>
    );
}

export default UploadSlip;