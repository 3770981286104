import { PagingInfo } from "../queryparams/PagingInfo";
import { ProjectQueryParam } from "../queryparams/ProjectQueryParam";
import { executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";

export const postChatBot = async (message: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/chatApi`;
  return await executePostData(url, message, accessToken);
};

export const getProjectInfoByQueryParam = async (
  projectQueryParam: ProjectQueryParam,
  pagingInfo: PagingInfo,
  accessToken: any
) => {
  const url = `${ConfigUrl.baseURL}/bids/getProjectInfoByQueryParam?page=${pagingInfo.page}&size=${pagingInfo.size}`;
  JSON.stringify(projectQueryParam);
  return await executePostData(url, projectQueryParam, accessToken);
}

export const getProjectInfoByProjectId = async (orderNo: any, accessToken: any) => {
  //const url = `${ConfigUrl.baseURL}/bids/getBidInfoByQueryParam?PageNo=${pagingInfo.page}&PageSize=${pagingInfo.size}`;
  const url = `${ConfigUrl.baseURL}/bids/getProjectByOrderNo/orderNo/${orderNo}`;
  return await executeGetData(url, accessToken);
}

