import { Dialog, DialogTitle, Typography, IconButton, DialogContent, DialogActions, Button, FormControl,
     Stack, Table, TableBody, TableCell, TableContainer, TableRow, Checkbox, TableHead} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Box, styled } from "@mui/system";
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getResources } from "../../services/ResourceService";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { exportTimelogWithDateRange, resourcesWithLastExportedDate } from "../../services/ExportService";
import dayjs from "dayjs";
import CustomDialog from "../../pages/Dialog";

const ExportTimelog: React.FC<any> = (props) => {

    const [selected, setSelected] = useState<readonly number[]>([]);
    const [selectedResources, setSelectedResources] = useState<any>([]);
    const [isAllItemSelected, setIsAllItemSelected] = useState<any>(false);
    const [startDate, setStartDate] = useState<any>();
    const [endDate, setEndDate] = useState<any>();
    const [validated, setValidated] = useState<any>(true);
    const [rows, setRows] = useState<any>([]);
    const [openDialogs, setOpenDialogs] = useState(false);
    const [success, setSuccess] = useState(false);
    const [emptyData, setEmptyData] = useState(false);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();


    const exportPayroll = async () =>{
        if(startDate !== undefined && endDate !== undefined && selectedResources.length !== 0){
            setValidated(true);
            console.log(selectedResources)
            const response = await exportTimelogWithDateRange(dayjs(startDate).format('YYYY-MM-DD').toString(), dayjs(endDate).format('YYYY-MM-DD').toString(), selectedResources, accessToken);
            console.log(response);
            if(response.status === 200){
                if(response?.data?.length !== 0 && response?.data !== null){
                    handleDownload(response.data)
                    setOpenDialogs(true);
                    setSuccess(true);
                }else{
                    setEmptyData(true);
                    setOpenDialogs(true);
                    setSuccess(true);
                }
            }else{
                setOpenDialogs(true);
                setSuccess(false);
            }
        }else{
            setValidated(false);
        }
    }

    const onClose = () => {
        props.handleClose();
    }

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const handleClick = (event: React.MouseEvent<unknown>, resource: any, isAllSelected: any) => {
        if(isAllSelected){
            if(!isAllItemSelected){
                let newSelected:any = [];
                let selectedResources:any = [];
                rows.map((row:any, index:any)=>{
                    newSelected.push(row?.id);
                    selectedResources.push(row?.resourceUid);
                })
                setSelectedResources(selectedResources);
                setSelected(newSelected);
                setIsAllItemSelected(true);
            }else{
                setSelectedResources([]);
                setSelected([]);
                setIsAllItemSelected(false);
            }
        }else{
            const selectedIndex = selected.indexOf(resource.id);
            let newSelected: readonly number[] = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, resource.id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }

            if(selectedResources.includes(resource.resourceUid)){
                selectedResources.splice(selectedResources.indexOf(resource.resourceUid), 1);
            }else{
                selectedResources.push(resource.resourceUid);
            }
            setSelected(newSelected);
        }
        console.log(selectedResources)
    };

    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });

    const fetchData = async () => {
        const response = await resourcesWithLastExportedDate(accessToken);
        console.log(response.data)
        setRows(response.data);

        // const result = await resourcesWithLastExportedDate(accessToken);
        // console.log(result)
    };

    useEffect(() => {
        fetchData();
    }, []);


    const jsonToCSV = (json:any) => {
        const header = Object.keys(json[0]);
        const rows = json.map(item => header.map(fieldName => item[fieldName]).join(','));
        return [header.join(','), ...rows].join('\n');
      };

    const handleDownload = (data:any) => {
        
      const blob = new Blob([jsonToCSV(data)], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'payroll.csv';
      link.click();
    };

    const handleClose = () => {
        setOpenDialogs(false);
        setSuccess(false);
        setEmptyData(false);
        props.handleClose();
      }

return(
    <><Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={props.open}>

        <DialogTitle>
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <Typography className='cartheader'>Export Payroll</Typography>
                </Box>
                <Box>
                    <IconButton onClick={() => onClose()}>
                        <CloseIcon sx={{ color: "red" }} />
                    </IconButton>
                </Box>
            </Box>
        </DialogTitle>

        <DialogContent sx={{ backgroundColor: "#f8f7fa" }}>
            <TableContainer sx={{ marginTop: 3 }}>
                {(!validated && selectedResources.length === 0) && <Typography align={'center'} color={'red'}>Must select any one resource</Typography>}

                <Table
                    aria-labelledby="tableTitle"
                >
                    <TableHead>
                        <TableRow
                            hover
                            onClick={(event) => handleClick(event, null, true)}
                            role="checkbox"
                            aria-checked={isAllItemSelected}
                            tabIndex={-1}
                            selected={isAllItemSelected}
                            sx={{ cursor: 'pointer' }}
                        >
                            <TableCell size='small'>
                                <CustomCheckbox
                                    className='check'
                                    color="primary"
                                    checked={isAllItemSelected} />{"SI.No"}
                            </TableCell>
                            <TableCell size='small'>{"Name"}</TableCell>
                            <TableCell size='small'>{"last exported on"}</TableCell>
                        </TableRow>
                    </TableHead>
                    {rows?.length === 0 ? <Typography textAlign={'center'}>No Employees</Typography> :
                        <TableBody>
                            {rows?.map((row: any, index: any) => {
                                const isItemSelected = isSelected(Number(row?.id));
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        onClick={(event) => handleClick(event, row, false)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        <TableCell size='small'>
                                            <CustomCheckbox
                                                className='check'
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }} />{index + 1}
                                        </TableCell>
                                        <TableCell size='small'>{row?.name}</TableCell>
                                        <TableCell size='small'>{row?.lastExportedDate === null ? "---" : dayjs(row?.lastExportedDate).format("MMM DD, YYYY")}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>}
                </Table>
            </TableContainer>
        </DialogContent>

        <DialogActions sx={{
            display: 'flex',
            justifyContent: 'space-between', // Align buttons to the edges
        }}>
            <Box>
                <Stack direction="row" spacing={1}>
                    <Stack>
                        <FormControl>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="start Date"
                                    slotProps={{ textField: { size: "small", error: (!validated && startDate === undefined) } }}
                                    sx={{ maxWidth: "150px" }}
                                    closeOnSelect={false}
                                    name="startTime"
                                    onChange={(e: any) => setStartDate(e.$d)} />
                            </LocalizationProvider>
                        </FormControl>
                    </Stack>
                    <Stack>
                        <FormControl>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="end Date"
                                    slotProps={{ textField: { size: "small", error: (!validated && endDate === undefined) } }}
                                    sx={{ maxWidth: "150px" }}
                                    closeOnSelect={false}
                                    name="endTime"
                                    onChange={(e: any) => setEndDate(e.$d)} />
                            </LocalizationProvider>
                        </FormControl>
                    </Stack>
                </Stack>
            </Box>
            <Box>
                <Stack direction="row" spacing={1}>
                    <Stack>
                        <Button onClick={() => exportPayroll()} className='sucessButton' variant="contained">Export As CSV</Button>
                    </Stack>
                    <Stack>
                        <Button onClick={() => onClose()} className='dangerButton' variant="contained">Cancel</Button>
                    </Stack>
                </Stack>
            </Box>
        </DialogActions>

    </Dialog>
    <CustomDialog
    open={openDialogs}
    onClose={handleClose}
    success={success}
    error={!success}
    Content={success ? emptyData ? "There No Data to Export" : "Export Successfully Completed" : "Error Couldn't Export"} /></>
);

}


export default ExportTimelog;