import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { AppBar, Backdrop, Tab, Tabs } from '@mui/material';
import TaskForm from './TaskForm';
import MaterialForm from './MaterialForm';
import ResourceForm from './ResourceForm';
import ToolForm from './ToolForm';
import ActionForm from './ActionForm';
import { addTask, getTaskById, saveTask, updateTaskById } from '../../services/TaskService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import CustomDialog from '../../pages/Dialog';
import dayjs from 'dayjs';
import moment from 'moment';
import { getConfiguredDataByKey } from '../../services/TradeConfigurationService';
import { convertUTCToLocalTime, weekdays } from '../../utils/Dateutils';
import gmslogo from "../../assets/logo.gif";
import { questions } from '../../utils/TaskUtils';
import { gpsTaskTypes } from '../../utils/TaskConstants';
import { getDuration, getProjectById } from '../../services/ProjectService';
import { useHandleUnauthorized } from '../../hooks/UserSession';
import { DepartureBoard } from '@mui/icons-material';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    data: string;
    xyz: string;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, data, index, xyz, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={data !== xyz}
            id={`simple-tabpanel-${xyz}`}
            aria-labelledby={`simple-tab-${xyz}`}
            {...other}
        >
            {data === xyz && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}


const AddTaskDialog: React.FC<any> = ({ open, onClose, projectData, taskId, phase, wbs,
    func, tasks, editView, state, projectId, id, removeTaskName, getTasks }) => {
    const [value, setValue] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [data, setData] = React.useState<any>("simple-tab-0");
    const { handleUnauthorized } = useHandleUnauthorized();
    
    const [taskData, setTaskData] = React.useState<any>({
        taskName: '',
        wbs: wbs,
        taskId: taskId,
        estimatedHours: '',
        estimatedDays: '',
        taskType: '',
        dependencies: [],
        otherDependencies: [],
        successorTask: '',
        queries: '',
        value: '',
        unit: '',
        phase: phase,
        startTime: '',
        endTime: '',
        resources: [
            {
                'type': '',
                'group': '',
                'count': ''
            }
        ],
        boms: [
            {
                'companyName': '',
                'name': '',
                'address': '',
                'quantity': '',
                'unit': '',
                'totalCost': '',
                'unitCost': '',
                'vendorMaterialMapId': ''
            }
        ],
        projectTools: [
            {
                'companyName': '',
                'name': '',
                'address': '',
                'quantity': '',
                'unit': '',
                'owned': true,
                'totalCost': '',
                'unitCost': '',
                'vendorToolMapId': ''
            }
        ],
        actions: [
            {
                'state': '',
                'action': '',
                'item': ''
            }
        ],
    });
    const { accessToken } = useOidcAccessToken();
    const [openSaveDialog, setOpenSaveDialog] = React.useState(false);
    const [saveSuccess, setSaveSuccess] = React.useState(false);
    const [saveError, setSaveError] = React.useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
    const [updateSuccess, setUpdateSuccess] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const [totalMaterialCost, setTotalMaterialCost] = React.useState<any>();
    const [totalToolCost, setTotalToolCost] = React.useState<any>();
    const [companyName, setCompanyName] = React.useState<any>();
    const [vendorAddress, setVendorAddress] = React.useState<any>();
    const [toolCompanyName, setToolCompanyName] = React.useState<any>();
    const [toolVendorAddress, setToolVendorAddress] = React.useState<any>();
    const [loading, setLoading] = React.useState(false);
    const [workingDays, setWorkingDays] = React.useState<any>([]);
    const [startTime, setStartTime] = React.useState<any>();
    const [endTime, setEndTime] = React.useState<any>();
    const [project, setProject] = React.useState<any>();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        // let validated = validation();
        // if (validated === true) {
        setValue(newValue);
        setData(event.currentTarget.id);
        // }
        // else {
        //     setCount(count + 1);
        // }

    };

    const closeDialog = () => {
        setIsLoading(false);
        setLoading(false);
        setSuccess(false);
        setError(false);
        setOpenDialog(false);
        setCount(0);
        // setOpen(false);
        setOpenUpdateDialog(false);
        setUpdateSuccess(false);
        setUpdateError(false);
        setOpenSaveDialog(false);
        setSaveSuccess(false);
        setSaveError(false);
        if (!error) {
            handleClose();
        }

    }
    const handleLoadingClose = () => {
        setIsLoading(false);
    }

    const handleClose = () => {
        onClose();
        Object.assign(taskData, {
            taskName: '',
            wbs: '',
            taskId: '',
            estimatedHours: '',
            phase: '',
            startTime: '',
            endTime: '',
            queries: '',
            value: '',
            unit: '',
            taskType: '',
            dependencies: [],
            otherDependencies: [],
            successorTask: '',
            resources: [
                {
                    'type': '',
                    'group': '',
                    'count': ''
                }
            ],
            boms: [
                {
                    'companyName': '',
                    'name': '',
                    'address': '',
                    'quantity': '',
                    'unit': '',
                    'totalCost': '',
                    'unitCost': '',
                    'vendorMaterialMapId': ''
                }
            ],
            projectTools: [
                {
                    'companyName': '',
                    'name': '',
                    'address': '',
                    'quantity': '',
                    'unit': '',
                    'owned': true,
                    'totalCost': '',
                    'unitCost': '',
                    'vendorToolMapId': ''
                }
            ],
            actions: [
                {
                    'state': '',
                    'action': '',
                    'item': ''
                }
            ],
        })
        setCount(0);
        setValue(0);
        setData("simple-tab-0");
        setTotalMaterialCost(0);
        setTotalToolCost(0);
        setVendorAddress('');
        setCompanyName('');
        setToolVendorAddress('');
        setToolCompanyName('');
    };

    const calculateWorkingHours = () => {
        if (taskData?.startTime !== '' && taskData?.endTime !== '') {

            getTaskDuration().then((estimatedHours) => {
                Object.assign(taskData, { estimatedHours: estimatedHours });
                setTaskData({ ...taskData, estimatedHours: estimatedHours });
                console.log("Updated taskData:", taskData);
            }).catch((error) => {
                console.error("Error calculating task duration:", error);
            });

        }
    }

    const handleChangeTaskData = (e: any, index: any) => {
        const {
            target: { value },
        } = e;
        if (e.target.name === 'type') {
            let newArray = taskData?.resources;
            newArray[index]["type"] = e.target.value;
            setTaskData({ ...taskData, 'resources': newArray });
        }
        else if (e.target.name === "taskId") {
            setTaskData({
                ...taskData, taskId: (e.target.value).taskId,
                wbs: (e.target.value).wbs, phase: (e.target.value).phase
            })
        }
        else
            if (e.target.name === 'group') {
                let newArray = taskData?.resources;
                newArray[index]["group"] = e.target.value;
                setTaskData({ ...taskData, 'resources': newArray });
            }
            else if (e.target.name === 'count') {
                if (e.target.value >= 0) {
                    let newArray = taskData.resources;
                    newArray[index]["count"] = e.target.value;
                    setTaskData({ ...taskData, 'resources': newArray });
                }
            }
            else if (e.target.name === 'estimatedHours') {
                if (e.target.value >= 0) {
                    setTaskData({ ...taskData, [e.target.name]: e.target.value });
                }
            }
            else if (e.target.name === "otherDependencies") {
                setTaskData({ ...taskData, [e.target.name]: typeof e.target.value === 'string' ? e.target.value.split(',') : (e.target.value), });
            }
            else if (e.target.name === "dependencies") {
                if (state === "Gps" || state === "Project Information") {
                    let dates: any = [];
                    let Dependencies: any = [];
                    value.map((res: any) => {
                        if (res.endTime !== null) {
                            dates.push(dayjs((res.endTime).replace(/Z/g, '')));
                        }

                        if (res?.taskId !== undefined) {
                            (Dependencies).push((res?.taskId)?.toString());
                        }
                    })
                    if (dates.length !== 0) {
                        Object.assign(taskData, { startTime: dayjs(Math.max(...dates)).format('YYYY-MM-DDTHH:mm:ss[Z]').toString() });
                        calculateWorkingHours();
                    }
                    Object.assign(taskData, { dependencies: Dependencies });    
                }
                else {
                    let dates: any = [];
                    let Dependencies: any = [];
                    value.map((res: any) => {
                        if (phase === "sales") {
                            if (res.endTime !== null) {
                                dates.push(dayjs((res.endTime).replace(/Z/g, '')));
                            }
                        }
                        if (res?.taskId !== undefined) {
                            (Dependencies).push((res?.taskId)?.toString());
                        }
                    })
                    if (dates.length !== 0) {
                        Object.assign(taskData, { startTime: dayjs(Math.max(...dates)).format('YYYY-MM-DDTHH:mm:ss[Z]').toString() });
                        calculateWorkingHours();
                    }
                    Object.assign(taskData, { dependencies: Dependencies });
                }
            }
            else if (e.target.name === "taskType") {
                if (e.target.value === "Install Task") {
                    let data = getQuestions("Install Task");
                    setTaskData({ ...taskData, [e.target.name]: e.target.value, queries: data });
                }
                else if (e.target.value === "Remove Task") {
                    let data = getQuestions("Remove Task");
                    setTaskData({ ...taskData, [e.target.name]: e.target.value, queries: data });
                }
                else if (e.target.value === "Verify Task") {
                    let data = getQuestions("Verify Task");
                    setTaskData({ ...taskData, [e.target.name]: e.target.value, queries: data });
                }
                else {
                    setTaskData({ ...taskData, [e.target.name]: e.target.value, queries: '' });
                }
            }
            else if (e.target.name === "value") {
                if (e.target.value > 0 || e.target.value === '') {
                    setTaskData({ ...taskData, [e.target.name]: e.target.value });
                }
            }
            else {
                setTaskData({ ...taskData, [e.target.name]: e.target.value });
            }
    }

    const resetData = () => {
        Object.assign(taskData, { otherDependencies: [], dependencies: [] });
    }

    const workSchedule = async () => {
        const result = await getConfiguredDataByKey("workingHours", accessToken);
        handleUnauthorized(result);
        if (result.data !== undefined && (result.status === 200 || result.status === 201)) {
            var object = JSON.parse(result.data);
            setStartTime(dayjs(convertUTCToLocalTime(object.startTime), 'HH:mm'));
            setEndTime(dayjs(convertUTCToLocalTime(object.endTime), 'HH:mm'));
        }
        const response = await getConfiguredDataByKey("workingDays", accessToken);
        handleUnauthorized(response);
        if (response.data !== undefined && (response.status === 200 || response.status === 201)) {
            let tenantWokringDays = response.data.split(",");
            weekdays.map((day: any) => {
                if (tenantWokringDays.includes(day)) {
                    workingDays.push(weekdays.indexOf(day));
                }
            })
            //setWorkingDays(response.data.split(",").map((num:any) => parseInt(num)));
        }
    }

    // const calculateWorkingHours = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
    //     let start = startDate;
    //     let end = endDate;

    //     if (start.isAfter(end)) return 0;

    //     let totalWorkingHours = 0;

    //     while (start.isBefore(end)) {
    //       if (start.day() !== 0 && start.day() !== 6) { // Check if it's a weekday
    //         const startOfWorkday = start.hour(startTime.hour()).minute(0).second(0);
    //         const endOfWorkday = start.hour(endTime.hour()).minute(0).second(0);

    //         if ((start.isSame(startOfWorkday) || start.isAfter(startOfWorkday)) && (start.isSame(endOfWorkday) || start.isBefore(endOfWorkday))) {
    //           totalWorkingHours += Math.min(endOfWorkday.diff(start, 'hour', true), end.diff(start, 'hour', true));
    //         } else if (start.isBefore(startOfWorkday)) {
    //           totalWorkingHours += Math.min(endOfWorkday.diff(startOfWorkday, 'hour', true), end.diff(startOfWorkday, 'hour', true));
    //         }
    //       }
    //       start = start.add(1, 'day').startOf('day');
    //     }

    //     return totalWorkingHours;
    //   };

    const onDateChange = (e: any, field: any) => {
        //october issue fixed
        if (field === 'startTime') {
            Object.assign(taskData, { startTime: dayjs(e.$d).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') })

        }
        else if (field === 'endTime') {
            Object.assign(taskData, { endTime: dayjs(e.$d).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') })


        }
        let estimatedHours = 0;

        if (taskData?.startTime !== '' && taskData?.endTime !== '') {

            getTaskDuration().then((estimatedHours) => {
                Object.assign(taskData, { estimatedHours: estimatedHours });
                setTaskData({ ...taskData, estimatedHours: estimatedHours });
                console.log("Updated taskData:", taskData);
            }).catch((error) => {
                console.error("Error calculating task duration:", error);
            });

        }
    }

   
const getTaskDuration = async () => {
    try {
        const response = await getDuration(taskData?.startTime, taskData?.endTime, accessToken);
        handleUnauthorized(response);

        const estimatedHours = (response.data / 60).toFixed(2); // Convert minutes to hours
        console.log("Duration in hours:", estimatedHours);
        return estimatedHours; // Return the value so that it's available in .then()
    } catch (error) {
        console.error("Error fetching duration:", error);
        throw error; // Propagate error for handling in the caller
    }
};

    const getQuestions = (type: any) => {
        return questions.find((obj) => obj?.type === type)?.questions;
    }


    // change materials in task data 
    const handleChangeMaterial = (data) => {
        if (taskData?.boms[0]?.companyName === '' && taskData?.boms[0].name === '' &&
            taskData?.boms[0]?.quantity === '' && taskData?.boms[0].unit === '' &&
            taskData?.boms[0]?.totalCost === '' && taskData?.boms[0].unitCost === '' &&
            taskData?.boms[0]?.vendorMaterialMapId === ''
        ) {
            taskData.boms[0] = data;
            calculateTotalMaterialCost(taskData?.boms);
        }
        else {
            const updatedMaterials = [
                ...taskData.boms,
                data,
            ];
            calculateTotalMaterialCost(updatedMaterials);
            setTaskData({ ...taskData, 'boms': updatedMaterials });
        }
    }

    const editMaterial = (index: any, data: any) => {
        let newArray = [...taskData?.boms];
        newArray[index] = data;
        calculateTotalMaterialCost(newArray);
        setTaskData({ ...taskData, 'boms': newArray });
    }

    const editTool = (index: any, data: any) => {
        let newArray = [...taskData?.projectTools];
        newArray[index] = data;
        calculateTotalToolCost(newArray);
        setTaskData({ ...taskData, 'projectTools': newArray });
    }


    // change vendor name in boms

    const handleChangeVendor = (e: any, value: any, index: any) => {
        let newArray = taskData.boms;
        newArray[index]["companyName"] = value?.companyName;
        setTaskData({ ...taskData, 'boms': newArray });
    }

    // change vendor name in project Tool

    const handleChangeToolVendor = (e: any, value: any, index: any) => {
        let newArray = taskData.projectTools;
        newArray[index]["companyName"] = value?.companyName;
        setTaskData({ ...taskData, 'projectTools': newArray });
    }

    const addResources = () => {
        const resource = {
            type: '',
            group: '',
            count: ''
        };
        const updatedResources = [
            ...taskData.resources,
            resource,
        ];
        setTaskData({ ...taskData, 'resources': updatedResources });
    }

    const removeResources = (index: any) => {
        let newArray = taskData.resources;
        if (newArray.length > 1) {
            newArray.splice(index, 1);
            setTaskData({ ...taskData, 'resources': newArray });
        }
        else {
            let resource = {
                'type': '',
                'group': '',
                'count': ''
            }
            newArray[index] = resource
            setTaskData({ ...taskData, 'resources': newArray });
        }
    }

    const addMaterial = () => {
        const material = {
            name: '',
            quantity: '',
            unit: ''
        };
        const updatedMaterials = [
            ...taskData.boms,
            material,
        ];
        setTaskData({ ...taskData, 'boms': updatedMaterials });
    }

    const removeMaterial = (index: any) => {
        let newArray = taskData.boms;
        if (newArray.length > 1) {
            newArray.splice(index, 1);
            setTaskData({ ...taskData, 'boms': newArray });
        }
        else {
            let material = {
                'companyName': '',
                'address': '',
                'name': '',
                'quantity': '',
                'unit': '',
                'totalCost': '',
                'unitCost': '',
                'vendorMaterialMapId': ''
            }
            newArray[index] = material
            setTaskData({ ...taskData, 'boms': newArray });
        }
        calculateTotalMaterialCost(newArray);
    }

    const handleChangeTool = (data: any) => {
        if (taskData?.projectTools[0]?.companyName === '' && taskData?.projectTools[0]?.name === '' &&
            taskData?.projectTools[0]?.quantity === '' && taskData?.projectTools[0]?.unit === '' &&
            taskData?.projectTools[0]?.totalCost === '' && taskData?.projectTools[0]?.unitCost === '' &&
            taskData?.projectTools[0]?.vendorToolMapId === ''
        ) {
            taskData.projectTools[0] = data;
            calculateTotalToolCost(taskData?.projectTools);
        }
        else {
            const updateTools = [
                ...taskData.projectTools,
                data,
            ];
            calculateTotalToolCost(updateTools);
            setTaskData({ ...taskData, 'projectTools': updateTools });
        }
    }
    const addTool = () => {
        const tool = {
            name: '',
            quantity: '',
            unit: ''
        };
        const updatedTools = [
            ...taskData.projectTools,
            tool,
        ];
        setTaskData({ ...taskData, 'projectTools': updatedTools });
    }

    const removeTool = (index: any) => {
        let newArray = taskData.projectTools;
        if (newArray.length > 1) {
            newArray.splice(index, 1);
            setTaskData({ ...taskData, 'projectTools': newArray });
        }
        else {
            let tool =
            {
                'companyName': '',
                'name': '',
                'address': '',
                'quantity': '',
                'unit': '',
                'owned': true,
                'totalCost': '',
                'unitCost': '',
                'vendorToolMapId': ''
            }

            newArray[index] = tool
            setTaskData({ ...taskData, 'projectTools': newArray });
        }
        calculateTotalToolCost(newArray);
    }

    const handleChangeAction = (e: any, index: any) => {
        let newArray = taskData?.actions;
        newArray[index][e.target.name] = e.target.value;
        setTaskData({ ...taskData, 'actions': newArray });
    }

    const calculateTotalMaterialCost = (tasksData: any) => {
        let total = 0;
        for (let i = 0; i < tasksData?.length; i++) {
            total = tasksData[i]?.totalCost + total;
        }
        setTotalMaterialCost(total);
    }

    const calculateTotalToolCost = (tasksData: any) => {
        let total = 0;
        for (let i = 0; i < tasksData?.length; i++) {
            total = tasksData[i]?.totalCost + total;
        }
        setTotalToolCost(total);
    }

    const addAction = () => {
        const action = {
            state: '',
            action: '',
            item: ''
        };
        const updatedAction = [
            ...taskData?.actions,
            action,
        ];
        setTaskData({ ...taskData, 'actions': updatedAction });
    }

    const removeAction = (index: any) => {
        let newArray = taskData?.actions;
        if (newArray.length > 1) {
            newArray.splice(index, 1);
            setTaskData({ ...taskData, 'actions': newArray });
        }
        else {
            let action =
            {
                'state': '',
                'action': '',
                'item': ''
            }

            newArray[index] = action
            setTaskData({ ...taskData, 'actions': newArray });
        }
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const validation = () => {
        let validated = true;
        if (state !== "Gps" || state !== "Project Information") {
            if (taskData.taskName === '' || taskData.estimatedHours === '' || taskData.taskType === '') {
                validated = false;
            }
        }
        else {
            if (taskData.taskName === '' || taskData.estimatedHours === '' || taskData.taskType === '' || taskData.startTime === '' || taskData.taskId === undefined || taskData.endTime === ''
                // || taskData.dependencies.length === 0 || taskData.otherDependencies.length === 0
            ) {
                validated = false;
            }
        }
        taskData?.resources?.map(d => {
            if ((d.type === '' || d.group === '' || d.count === '') && gpsTaskTypes.includes(taskData?.taskType)) {
                validated = false;
            }
        })
        if (taskData?.taskType === "Install Task" || taskData?.taskType === "Remove Task") {
            if (taskData?.value === "" || taskData?.unit === "") {
                validated = false;
            }
        }
        return validated;
    }

    // remove empty bom in taskData 
    const removeEmptyBom = (bom: any) => {
        for (let i = 0; i < bom.length; i++) {
            if (bom[i]?.companyName === '' && bom[i]?.name === '' && bom[i]?.quantity === '' &&
                bom[i]?.unit === '' && bom[i]?.totalCost === '' && bom[i]?.unitCost === '' &&
                bom[i]?.vendorMaterialMapId === ''
            ) {
                bom.splice(bom[i], 1);
            }
        }
        return bom;
    }

    // remove empty resources in taskData 
    const removeEmptyResources = (resources: any) => {
        for (let i = 0; i < resources.length; i++) {
            if (resources[i].type === '' && resources[i].group === '' && resources[i].count === ''
            ) {
                resources.splice(resources[i], 1);
            }
        }
        if (resources.length === 0) {
            resources = null;
        }
        return resources;
    }
    // remove empty actions in taskData 
    const removeEmptyActions = (actions: any) => {
        for (let i = 0; i < actions.length; i++) {
            if (actions[i].state === '' && actions[i].action === '' && actions[i].item === ''
            ) {
                actions.splice(actions[i], 1);
            }
        }
        if (actions.length === 0) {
            actions = null;
        }
        return actions;
    }

    const removeEmptyProjectTools = (projectTools: any) => {
        for (let i = 0; i < projectTools.length; i++) {
            if (projectTools[i]?.companyName === '' && projectTools[i]?.name === '' && projectTools[i]?.quantity === '' &&
                projectTools[i]?.unit === '' && projectTools[i]?.totalCost === '' && projectTools[i]?.unitCost === '' &&
                projectTools[i]?.vendorToolMapId === ''
            ) {
                projectTools.splice(projectTools[i], 1);
            }
        }
        return projectTools;
    }

    const addNewTask = async () => {
        setCount(count + 1);
        let validated = validation();
        if (validated === true) {
            setLoading(true);
            var finalData = {};
            finalData['boms'] = removeEmptyBom(taskData.boms);
            finalData['resources'] = JSON.stringify(removeEmptyResources(taskData.resources));
            finalData['actions'] = JSON.stringify(removeEmptyActions(taskData.actions));
            finalData['projectTools'] = removeEmptyProjectTools(taskData.projectTools);
            if (state === "Gps") {
                finalData['wbs'] = taskData?.wbs;
                finalData['phase'] = taskData?.phase;
                finalData['taskId'] = taskData?.taskId;
                finalData['project'] = projectId;
                finalData['startTime'] = taskData?.startTime;
                finalData['endTime'] = taskData?.endTime;
            }
            else if (state === "Project Information") {
                finalData['wbs'] = getWbs(wbs);
                finalData['phase'] = phase;
                finalData['taskId'] = id + 1;
                finalData['project'] = projectId;
                finalData['startTime'] = taskData?.startTime;
                finalData['endTime'] = taskData?.endTime;
            } else {
                finalData['wbs'] = getWbs(wbs);
                finalData['phase'] = phase;
                finalData['taskId'] = taskId + 1;
                finalData['project'] = projectData?.props?.id;
                finalData['startTime'] = taskData?.startTime;
                finalData['endTime'] = taskData?.endTime;
            }
            finalData['taskName'] = taskData.taskName;
            finalData['estimatedHours'] = taskData.estimatedHours;
            finalData['taskName'] = taskData.taskName;
            finalData['dependencies'] = (taskData.dependencies).join(";");
            finalData['otherDependencies'] = (taskData.otherDependencies).join(";");
            finalData['taskType'] = taskData.taskType;
            finalData['allocatedUnit'] = JSON.stringify({
                unit: taskData?.unit,
                value: taskData?.value
            });
            if (taskData?.unit !== '' && taskData?.value !== '') {
                finalData['allocatedUnit'] = JSON.stringify({
                    unit: taskData?.unit,
                    value: taskData?.value
                });
            }
            else {
                finalData['allocatedUnit'] = null;
            }
            if (taskData?.queries !== '') {
                finalData['queries'] = JSON.stringify(taskData?.queries);
            }
            else {
                finalData['queries'] = null;
            }
            let response: any;
            if (state === "Gps" || state === "Project Information") {
                if (project?.status === "bidReview" || project?.status === "awaitingEstimation" || project?.status === "Contract Approval") {
                    response = await saveTask(accessToken, finalData);
                } else {
                    response = await addTask(finalData, accessToken);
                }
                console.log("add task", response);
                if ((response.status === 201 || response.status === 200) && (response.data === "successful" || response.data === "Successful")) {
                    setError(false);
                    setIsLoading(false);
                    setLoading(false);
                    setOpenDialog(true);
                    setSuccess(true);
                    if (state === "Project Information") {
                        getTasks();
                    }
                    // setTaskId(undefined);
                } else {
                    setIsLoading(false);
                    setLoading(false);
                    setOpenDialog(true);
                    setError(true);
                }

            } else {
                response = await saveTask(accessToken, finalData);
                if ((response.status === 201 || response.status === 200) && (response.data === "successful" || response.data === "Successful")) {
                    setLoading(false);
                    setSaveSuccess(true);
                    setOpenSaveDialog(true);
                    handleClose();
                    func();
                }
                else {
                    setLoading(false);
                    setOpenSaveDialog(true);
                    setSaveError(true);
                    // handleClose();
                }
            }
        }
    }
    // //manual task addition
    // const addNewTask = async () => {
    //     // setWarning(false);

    //     setCount(count + 1);
    //     let validate = validation();
    //     if (validate === true) {
    //         setIsLoading(true);
    //         // Object.assign(taskData, { 'project': projectId });
    //         var finalData = {};
    //         finalData['boms'] = removeEmptyBom(taskData.boms);
    //         finalData['resources'] = JSON.stringify(removeEmptyResources(taskData.resources));
    //         finalData['actions'] = JSON.stringify(removeEmptyActions(taskData.actions));
    //         finalData['projectTools'] = removeEmptyProjectTools(taskData.projectTools);
    //         if (state === "Gps") {
    //             finalData['wbs'] = taskData?.wbs;
    //             finalData['phase'] = taskData?.phase;
    //             finalData['taskId'] = taskData?.taskId;
    //         }
    //         else if (state === "Project Information") {
    //             finalData['wbs'] = getWbs(wbs);
    //             finalData['phase'] = phase;
    //             finalData['taskId'] = id + 1;
    //         }
    //         finalData['project'] = projectId;
    //         finalData['taskName'] = taskData.taskName;
    //         finalData['estimatedHours'] = taskData.estimatedHours;
    //         finalData['taskName'] = taskData.taskName;
    //         finalData['dependencies'] = (taskData.dependencies).join(";");
    //         finalData['otherDependencies'] = (taskData.otherDependencies).join(";");
    //         finalData['taskType'] = taskData?.taskType;
    //         finalData['startTime'] = taskData?.startTime;
    //         finalData['endTime'] = taskData?.endTime;
    //         if (taskData?.unit !== '' && taskData?.value !== '') {
    //             finalData['allocatedUnit'] = JSON.stringify({
    //                 unit: taskData?.unit,
    //                 value: taskData?.value
    //             });
    //         }
    //         else {
    //             finalData['allocatedUnit'] = null;
    //         }
    //         if (taskData?.queries !== '') {
    //             finalData['queries'] = JSON.stringify(taskData?.queries);
    //         }
    //         else {
    //             finalData['queries'] = null;
    //         }
    //         const response = await addTask(finalData, accessToken);
    //         console.log("add task",response);
    //         if (response.data === "successful" || response.data === "Successful") {
    //             setError(false);
    //             setIsLoading(false);
    //             setOpenDialog(true);
    //             setSuccess(true);
    //             if(state === "Project Information"){
    //                 getTasks();
    //             }
    //             // setTaskId(undefined);
    //         } else {
    //             setIsLoading(false);
    //             setOpenDialog(true);
    //             setError(true);
    //         }
    //     }
    //     else {
    //         // setIsLoading(false);
    //         // setError(true);
    //     }
    // };

    const updateTask = async () => {
        setLoading(true);
        setCount(count + 1);
        let validated = validation();
        if (validated === true) {
            var finalData = {};
            finalData['boms'] = removeEmptyBom(taskData.boms);
            finalData['resources'] = JSON.stringify(removeEmptyResources(taskData.resources));
            finalData['actions'] = JSON.stringify(removeEmptyActions(taskData.actions));
            finalData['projectTools'] = removeEmptyProjectTools(taskData.projectTools);
            finalData['wbs'] = wbs;
            finalData['phase'] = phase;
            finalData['taskId'] = taskId;
            finalData['id'] = tasks?.id;
            finalData['project'] = projectData?.props?.id;
            finalData['taskName'] = taskData?.taskName;
            finalData['estimatedHours'] = taskData?.estimatedHours;
            finalData['dependencies'] = (taskData?.dependencies).join(";");
            finalData['otherDependencies'] = (taskData?.otherDependencies).join(";");
            finalData['startTime'] = taskData?.startTime;
            finalData['endTime'] = taskData?.endTime;
            finalData['taskType'] = taskData?.taskType;
            if (taskData?.unit !== '' && taskData?.value !== '') {
                finalData['allocatedUnit'] = JSON.stringify({
                    unit: taskData?.unit,
                    value: taskData?.value
                });
            }
            else {
                finalData['allocatedUnit'] = null;
            }
            if (taskData?.queries !== '') {
                finalData['queries'] = JSON.stringify(taskData?.queries);
            }
            else {
                finalData['queries'] = null;
            }
            finalData['allocatedUnit'] = JSON.stringify({
                unit: taskData?.unit,
                value: taskData?.value
            });
            let result = await updateTaskById(finalData, accessToken);
            if (result.status === 201 || result.status === 200) {
                setLoading(false);
                setUpdateSuccess(true);
                setOpenUpdateDialog(true);
                handleClose();
                func();
                removeTaskName();

            }
            else {
                setLoading(false);
                setOpenUpdateDialog(true);
                setUpdateError(true);
                handleClose();
            }
        }
    }

    // get task, boms and project tools based on task id
    const getTasksByTaskId = async (id: any) => {
        let result = await getTaskById(id, accessToken);
        handleUnauthorized(result);
        let data = result.data;
        let editData = {};
        if (data['boms'] === null || data['boms'] === "null" || data['boms'] === "" || data['boms']?.length === 0) {
            editData['boms'] = [
                {
                    'companyName': '',
                    'address': '',
                    'name': '',
                    'quantity': '',
                    'unit': '',
                    'totalCost': '',
                    'unitCost': '',
                    'vendorMaterialMapId': ''
                }
            ]
        }
        else {
            editData['boms'] = data['boms'];
            setCompanyName(data['boms'][0]?.companyName);
            setVendorAddress(data['boms'][0]?.address);
            calculateTotalMaterialCost(data["boms"])
        }
        if (data['projectTools'] === null || data['projectTools'] === "null" || data['projectTools'] === "" ||
            data['projectTools'] === undefined || data['projectTools'].length === 0) {
            editData['projectTools'] = [
                {
                    'companyName': '',
                    'name': '',
                    'address': '',
                    'quantity': '',
                    'unit': '',
                    'owned': true,
                    'totalCost': '',
                    'unitCost': '',
                    'vendorToolMapId': ''
                }
            ]
        }
        else {
            editData['projectTools'] = data['projectTools'];
            setToolCompanyName(data['projectTools'][0]?.companyName);
            setToolVendorAddress(data['projectTools'][0]?.address);
            calculateTotalToolCost(data["projectTools"])
        }
        if (data['actions'] === null || data['actions'] === "null" || data['actions'] === "" || data['actions'] === undefined) {
            editData['actions'] = [
                {
                    'state': '',
                    'action': '',
                    'item': ''
                }
            ]
        }
        else {

            editData['actions'] = JSON.parse(data['actions']);
        }
        if (data['resources'] === null || data['resources'] === "null" || data['resources'] === "" || data['resources'] === undefined) {
            editData['resources'] = [
                {
                    'type': '',
                    'group': '',
                    'count': ''
                }
            ]
        }
        else {

            editData['resources'] = JSON.parse(data['resources']);
        }
        editData['taskName'] = data['taskName'];
        editData['wbs'] = data['wbs'];
        editData['taskId'] = data['taskId'];
        editData['estimatedHours'] = data['estimatedHours'];
        editData['phase'] = data['phase'];
        editData['taskType'] = data['taskType'];
        editData['startTime'] = data['startTime'];
        editData['endTime'] = data['endTime'];
        if (data['allocatedUnit'] === "" || data['allocatedUnit'] === null) {
            editData['value'] = "";
            editData['unit'] = "";
        }
        else {
            editData['value'] = JSON.parse(data['allocatedUnit'])?.value;
            editData['unit'] = JSON.parse(data['allocatedUnit'])?.unit;
        }
        if (data['queries'] === "" || data['queries'] === null) {
            editData['queries'] = data['queries'];;
        }
        else {
            editData['queries'] = JSON.parse(data['queries']);
        }
        if (data['dependencies'] === "") {
            editData['dependencies'] = [];
        }
        else {
            editData['dependencies'] = data['dependencies']?.split(";");
        }
        if (data['otherDependencies'] === "" || data['otherDependencies'] === null || data['otherDependencies'] === undefined) {
            editData['otherDependencies'] = [];
        }
        else {
            editData['otherDependencies'] = data['otherDependencies']?.split(";");
        }
        setTaskData(editData)

    }

    // change vendor name and address
    const handleChangeAddress = (e: any) => {
        setVendorAddress(e.target.value);
        let material = [{
            'companyName': '',
            'address': '',
            'name': '',
            'quantity': '',
            'unit': '',
            'totalCost': '',
            'unitCost': '',
            'vendorMaterialMapId': ''
        }];
        setTaskData({ ...taskData, 'boms': material });
    }

    const handleChangeVendorName = (data: any) => {
        setCompanyName(data);
        setVendorAddress(undefined);
        let material = [{
            'companyName': '',
            'address': '',
            'name': '',
            'quantity': '',
            'unit': '',
            'totalCost': '',
            'unitCost': '',
            'vendorMaterialMapId': ''
        }];
        setTaskData({ ...taskData, 'boms': material });
    }
    // change tool vendor namd and address
    const handleChangeToolAddress = (e: any) => {
        setToolVendorAddress(e.target.value);
        let tools = [
            {
                'companyName': '',
                'name': '',
                'address': '',
                'quantity': '',
                'unit': '',
                'owned': true,
                'totalCost': '',
                'unitCost': '',
                'vendorToolMapId': ''
            }
        ]
        setTaskData({ ...taskData, 'projectTools': tools });
    }

    const handleChangeToolVendorName = (data: any) => {
        setToolCompanyName(data);
        setToolVendorAddress(undefined);
        let tools = [
            {
                'companyName': '',
                'name': '',
                'address': '',
                'quantity': '',
                'unit': '',
                'owned': true,
                'totalCost': '',
                'unitCost': '',
                'vendorToolMapId': ''
            }
        ]
        setTaskData({ ...taskData, 'projectTools': tools });
    }

    // wbs addition function
    const getWbs = (wbs: any) => {
        let stringArray = wbs.split(".");
        let newWbs: any;
        if (stringArray.length === 2) {
            stringArray.push(1);
            newWbs = stringArray.join(".");
        }
        else {
            let lastIndex = Number(stringArray[stringArray.length - 1]) + 1
            stringArray.splice(stringArray.length - 1, 1);
            stringArray.push(lastIndex.toString());
            newWbs = stringArray.join(".");
        }
        return newWbs;
    }

    const getProject = async () =>{
        const response = await getProjectById(accessToken,projectId);
       handleUnauthorized(response)
        if(response.status === 200 || response.status === 201){
            setProject(response.data)
        }
    }

    React.useEffect(() => {
        if (editView === true) {
            getTasksByTaskId(tasks?.id)
            // setTaskData(editData);
        }
        if ((state === "Gps") || (state === "Project Information")) {
            getProject();
        }
        workSchedule();
    }, [editView, tasks, open]);
    return (

        <React.Fragment>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={"lg"}
            >
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
                </Backdrop>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {
                        editView === true ?
                            "Edit Task"
                            :
                            "Add Task"
                    }
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {/* <Divider /> */}
                {/* <AppBar position="static" color="default"> */}
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                >
                    <Tab label="Task" {...a11yProps(0)} />
                    {gpsTaskTypes.includes(taskData?.taskType) ?
                        <Tab label="Resource" {...a11yProps(1)} sx={{ color: (taskData?.resources[0].count === '' && count != 0) ? 'red' : '' }} />
                        : null}
                    {((taskData?.taskType === "Create BOM Task") &&
                        (taskData?.projectTools[0]?.companyName === '' &&
                            taskData?.projectTools[0]?.name === '' &&
                            taskData?.projectTools[0]?.quantity === '' && taskData?.projectTools[0]?.unit === '' &&
                            taskData?.projectTools[0]?.totalCost === '' && taskData?.projectTools[0]?.unitCost === ''
                            && taskData?.projectTools[0]?.vendorToolMapId === '')) ?
                        <Tab label="Bom" {...a11yProps(2)} />
                        :
                        null
                    }
                    {((taskData?.taskType === "Create BOM Task") &&
                        (taskData?.boms[0]?.companyName === '' && taskData?.boms[0]?.name === '' &&
                            taskData?.boms[0]?.quantity === '' && taskData?.boms[0]?.unit === '' &&
                            taskData?.boms[0]?.totalCost === '' && taskData?.boms[0]?.unitCost === ''
                            && taskData?.boms[0]?.vendorMaterialMapId === ''))
                        ?
                        <Tab label="Tools" {...a11yProps(3)} />
                        :
                        null
                    }
                    {/* <Tab label="Question" {...a11yProps(4)} /> */}
                    <Tab label="Action" {...a11yProps(4)} />
                </Tabs>
                {/* </AppBar> */}
                <DialogContent dividers sx={{ padding: 0, height: '40vh' }} >
                    <CustomTabPanel value={value} index={0} xyz="simple-tab-0" data={data}>
                        <TaskForm changeTaskData={handleChangeTaskData} resetData={resetData}
                            dateChange={onDateChange}
                            task={taskData} taskId={((state === "Gps") || (state === "Project Information")) ? id : taskId}
                            projectId={((state === "Gps") || (state === "Project Information")) ? projectId : projectData?.props?.id}
                            count={count} editView={editView} state={state} workStartTime={startTime} workEndTime={endTime} workDays={workingDays} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1} xyz="simple-tab-1" data={data}>
                        <ResourceForm changeTaskData={handleChangeTaskData} task={taskData} add={addResources} remove={removeResources}
                            count={count} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={0} xyz="simple-tab-2" data={data}>
                        <MaterialForm projectData={projectData?.props} task={taskData}
                            changeTaskData={handleChangeMaterial} add={addMaterial}
                            remove={removeMaterial} ChangeVendorData={handleChangeVendor}
                            total={totalMaterialCost} edit={editMaterial} companyName={companyName} address={vendorAddress}
                            changeVendorName={handleChangeVendorName} changeVendorAddress={handleChangeAddress} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2} xyz="simple-tab-3" data={data}>
                        <ToolForm projectData={projectData?.props} task={taskData}
                            changeTaskData={handleChangeTool} add={addTool}
                            remove={removeTool} changeVendorToolData={handleChangeToolVendor}
                            total={totalToolCost} edit={editTool} companyName={toolCompanyName} address={toolVendorAddress}
                            changeVendorName={handleChangeToolVendorName} changeVendorAddress={handleChangeToolAddress} />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={4} xyz="simple-tab-4" data={data}>
                        <ActionForm task={taskData}
                            changeTaskData={handleChangeAction} add={addAction}
                            remove={removeAction} />
                    </CustomTabPanel>
                </DialogContent>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                    onClick={handleLoadingClose}
                >
                    <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
                </Backdrop>
                <DialogActions>
                    {editView === true ? (
                        <Button variant='contained' className="successButton" onClick={updateTask}>
                            Update
                        </Button>
                    ) : (

                        <Button variant='contained' className="successButton" onClick={addNewTask} >
                            Save
                        </Button>

                    )}

                    <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleClose}>Close</Button>

                </DialogActions>
            </Dialog>
            <CustomDialog
                open={openSaveDialog}
                onClose={closeDialog}
                success={saveSuccess}
                error={saveError}
                Content={saveSuccess ? "Saved" : "Not Saved"}
            />
            <CustomDialog
                open={openUpdateDialog}
                onClose={closeDialog}
                success={updateSuccess}
                error={updateError}
                Content={updateSuccess ? "Updated" : "Not Updated"}
            />
            <CustomDialog
                open={openDialog}
                onClose={closeDialog}
                success={success}
                error={error}
                Content={success ? "Task Added Successfully" : "Failed To Add Task"} />
        </React.Fragment>
    );
}

export default AddTaskDialog;