import { ConfigUrl } from '../auth/OidcConfig';
import { executeGetData } from './ApiBase';

export const exportTimelogWithDateRange = async (startDate: any, endDate :any, resourceUIds:any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/export/payroll/startDate/${startDate}/endDate/${endDate}/resourceUIds/${resourceUIds}`;
    return await executeGetData(url, accessToken);
};

export const resourcesWithLastExportedDate = async (accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/export/resources`;
    return await executeGetData(url, accessToken);
};