import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    Box, Table, TableBody, TableCell, TableContainer,
    TableHead, TablePagination, TableRow, TableSortLabel,
    Paper, Checkbox,
    Grid, useMediaQuery, Collapse,
    Stack,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogActions
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import './JobLedger.scss';
import { getAllCodes, getJobLedgerExpense, getJobLedgerList, getJobLedgerMaterialExpense, getJobLedgerToolsExpense } from '../../services/JobLedgerService';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { importLedger } from '../../services/ImportService';
import CustomDialog from '../../pages/Dialog';
import { useHandleUnauthorized } from '../../hooks/UserSession';




interface Data {
    id: number;
    industry: string;
    accountName: string;
    unit: string;
    actualCost: number;
    estCost: number;
    variance: number;
}

function createData(
    id: number,
    industry: string,
    accountName: string,
    unit: string,
    actualCost: number,
    estCost: number,
    variance: number,
): Data {
    return {
        id,
        industry,
        accountName,
        unit,
        actualCost,
        estCost,
        variance,
    };
}

const rows = [
    createData(1, 'Cupcake', '305', '3.7', 0.00, 0.00, 0.00),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'industry',
        numeric: false,
        disablePadding: true,
        label: 'Industry',
    },
    {
        id: 'accountName',
        numeric: true,
        disablePadding: false,
        label: 'Account Name',
    },
    {
        id: 'unit',
        numeric: true,
        disablePadding: false,
        label: 'Unit',
    },
    {
        id: 'actualCost',
        numeric: true,
        disablePadding: false,
        label: 'Actual Cost',
    },
    {
        id: 'estCost',
        numeric: true,
        disablePadding: false,
        label: 'Est Cost/Rev',
    },
    {
        id: 'variance',
        numeric: true,
        disablePadding: false,
        label: 'Variance',
    }
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead >
            <TableRow className='tableHead'>
                {/* <TableCell padding="checkbox">
                </TableCell> */}
                <TableCell>
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const JobLedgerList: React.FC<any> = (props) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('industry');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState([] as any[])
    const [rowIndex, setRowIndex] = React.useState<any>();
    const [costCode, setCostCode] = React.useState<any>();
    const [tableHeader, setTableHeader] = React.useState<any>([]);
    const [jobCost, setJobCost] = React.useState<any>([] as any[])
    const { handleUnauthorized } = useHandleUnauthorized();
    const [codes, setCodes] = React.useState<any>();

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleSetIndex = (index: any, costCode: any, code: any) => {
        setRowIndex(index);
        setCostCode(costCode);
        if (code === codes?.labor) {
            setTableHeader([]);
            setJobCost([]);
            getExpenseList();
        }
        else if (code === codes?.tools) {
            setTableHeader([]);
            setJobCost([]);
            getToolsExpense();
        }
        else if (code === codes?.materials) {
            setTableHeader([]);
            setJobCost([]);
            getMaterialExpense();
        }
        else {
            setOpen(false);
        }
    }

    const getExpenseList = async () => {
        let result = await getJobLedgerExpense(props.projectId, accessToken);
        handleUnauthorized(result);
        if ((result.data).length !== 0) {
            setTableHeader(Object.keys(result.data[0]).toString().split(","))
            setJobCost(result.data);
            setOpen(!open);
        }
        else {
            setOpen(false);
        }
    }
    const getToolsExpense = async () => {
        let result = await getJobLedgerToolsExpense(props.projectId, accessToken);
        handleUnauthorized(result);
        if ((result.data).length !== 0) {
            setTableHeader(Object.keys(result.data[0]).toString().split(","))
            setJobCost(result.data);
            setOpen(!open);
        }
        else {
            setOpen(false);
        }
    }

    const getMaterialExpense = async () => {
        let result = await getJobLedgerMaterialExpense(props.projectId, accessToken);
        handleUnauthorized(result);
        if ((result.data).length !== 0) {
            setTableHeader(Object.keys(result.data[0]).toString().split(","))
            setJobCost(result.data);
            setOpen(!open);
        }
        else {
            setOpen(false);
        }
    }

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));

    const getJobLedger = async () => {
        let result = await getJobLedgerList(props.projectId, accessToken);
        handleUnauthorized(result);
        if (result.data != null) {
            setData(result.data);
        }
        console.log(result.data);
    }

    const [openDialog, setOpenDialog] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [file, setFile] = React.useState<File | null>(null);
    const [imagePreview, setImagePreview] = React.useState<string | null>(null);
    const [dataa, setFormData] = React.useState<any>();
    const [openSuccess, setOpenSuccess] = React.useState<boolean>(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleChange = React.useCallback(
        (event) => {
            const files = event.target.files;
            if (files && files[0]) {
                handleFileChange(files[0]);
                // setFile(files[0]);
            }
        },
        []
    );

    let formData = new FormData();

    const handleFileChange = (file: File) => {
        setLoading(true);
        setFile(file);
        formData.append('file', file);
        console.log(file)
        setFormData(formData);
    };

    const importCustomer = async () => {
        const res = await importLedger(dataa, accessToken);
        handleUnauthorized(res);
        setOpenDialog(false);
        setOpenSuccess(true);
        if (res.ok === true) {
            setSuccess(true);

        } else {
            setError(true);
        }
    }

    const closeDialog = () => {
        setSuccess(false);
        setError(false);
        if (success) {
            handleDialogClose();
        }
    }

    const getCodes = async () => {
        let result = await getAllCodes(accessToken);
        setCodes(result?.data);
    }

    React.useEffect(() => {
        getJobLedger();
        getCodes();
    }, [])
    return (
        <>
            {/* <Box
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "space-between" : "start"}
                alignItems={smScreen ? "center" : "start"}
            >
                <Header title="Job Ledger" subtitle="" />
            </Box> */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} className='alignEnd' sx={{ padding: '15px' }}><Button variant="contained" size="large" onClick={handleDialogOpen} >Import Ledger</Button></Grid>
                <Grid item md={12}>
                    <Box sx={{ width: '100%', }}>
                        <Paper className='POCard' sx={{ width: '100%', mb: 2 }}>
                            {/* <div className='searchBar'>
                                <Grid container rowSpacing={1} mt={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                                    <Grid item xs={2}>
                                        <Typography>Job Name :</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>James Anderson Roof Replacement</Typography>
                                    </Grid>
                                    <Grid item xs={6} >
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Typography>Job# :</Typography>
                                    </Grid>
                                    <Grid item xs={4} >
                                        <Typography>ABC-25-236</Typography>
                                    </Grid>
                                    <Grid item xs={6} >
                                    </Grid>
                                </Grid>
                            </div> */}
                            <TableContainer sx={{ marginTop: 3 }}>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                // sx={{
                                //     [`&:last-child.${tableCellClasses.root}`]: {
                                //       borderBottom: "none"
                                //     }
                                //   }}
                                // sx={{
                                //     "&:last-child": {
                                //         background: "red"
                                //       },
                                // }}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows?.length}
                                    />
                                    <TableBody>
                                        {data?.map(item => (
                                            <React.Fragment>
                                                <TableRow>
                                                    <TableCell rowSpan={item?.detail.length + 1} className='subTotal' align='center'>
                                                        {item?.name}
                                                    </TableCell>
                                                </TableRow>
                                                {item?.detail?.map((detail, index) => (
                                                    <><TableRow>
                                                        <TableCell className={((item?.detail?.length - 1) === index) ? 'subTotal' : ""}>{detail?.industry}</TableCell>
                                                        {(detail?.code === codes?.labor || detail?.code === codes?.materials || detail?.costCode === codes?.tools) ?
                                                            <TableCell onClick={() => handleSetIndex(index, detail?.costCode, detail?.code)} sx={{ cursor: 'pointer' }}>
                                                                <Stack direction="row" alignItems="center">
                                                                    <Typography sx={{ fontSize: '13px' }}> {detail?.costCode}</Typography>
                                                                    {open === false ?
                                                                        <ArrowDropDownIcon fontSize='small' />
                                                                        :
                                                                        <ArrowDropUpIcon fontSize='small' />
                                                                    }
                                                                </Stack>
                                                            </TableCell>
                                                            : <TableCell onClick={() => handleSetIndex(index, detail?.costCode, detail?.code)}>{detail?.costCode}</TableCell>
                                                        }

                                                        <TableCell>{detail?.unit}</TableCell>
                                                        {item.name === "Gross Profit Margin" ?
                                                            <>

                                                                <TableCell className={((item?.detail?.length - 1) === index) ? 'subTotal' : ""}>{(detail?.actualCost)?.toFixed(1)} %
                                                                </TableCell>
                                                                <TableCell className={((item?.detail?.length - 1) === index) ? 'subTotal' : ""}>{(detail?.estimateCost)?.toFixed(1)} %</TableCell>
                                                                <TableCell>
                                                                </TableCell>
                                                            </>
                                                            :
                                                            <>
                                                                <TableCell className={((item?.detail?.length - 1) === index) ? 'subTotal' : ""}>$ {(detail?.actualCost)?.toFixed(2)}</TableCell>
                                                                <TableCell className={((item?.detail?.length - 1) === index) ? 'subTotal' : ""}>$ {(detail?.estimateCost)?.toFixed(2)}</TableCell>
                                                                <TableCell sx={{ color: 'red' }}>$ {detail?.variance}</TableCell>
                                                            </>}
                                                    </TableRow>
                                                        {rowIndex === index && costCode === detail.costCode ?
                                                            <TableRow>
                                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                                    <Collapse in={open} timeout="auto" unmountOnExit>
                                                                        <Box sx={{ margin: 1 }}>
                                                                            {/* <Typography variant="h6" gutterBottom component="div">
                                                                            History
                                                                        </Typography> */}
                                                                            <Table size="small" aria-label="purchases"
                                                                            >
                                                                                <TableHead>
                                                                                    <TableRow>
                                                                                        {tableHeader?.map((tableHead =>
                                                                                            <TableCell>{(tableHead).split(/(?=[A-Z])/).join(" ").replace(/^./, tableHead[0].toUpperCase())}
                                                                                            </TableCell>
                                                                                        ))}
                                                                                    </TableRow>
                                                                                </TableHead>
                                                                                <TableBody>
                                                                                    {jobCost?.map((job: any) => (
                                                                                        <TableRow>

                                                                                            <>
                                                                                                {tableHeader?.map((tableHead =>

                                                                                                    <TableCell>{["time", "amount"].includes(tableHead) ? job[tableHead]?.toFixed(2) : job[tableHead]}</TableCell>
                                                                                                ))}
                                                                                            </>
                                                                                        </TableRow>
                                                                                    ))}
                                                                                </TableBody>
                                                                            </Table>
                                                                        </Box>
                                                                    </Collapse>
                                                                </TableCell>
                                                            </TableRow>
                                                            : <></>}
                                                    </>
                                                ))}

                                            </React.Fragment>
                                        ))}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                            {/* <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={rows?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            /> */}
                        </Paper>
                        {/* <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            /> */}
                    </Box>
                </Grid>
            </Grid>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
            >
                <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                    <DialogContent >
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            Upload
                        </Typography>
                        <Typography textAlign={'center'}>
                            <form>
                                <input type="file" onChange={handleChange} />
                            </form>
                        </Typography>
                    </DialogContent>
                </Box>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={handleDialogClose} className="dangerButton">Cancel</Button>
                    <Button
                        onClick={importCustomer}
                        autoFocus className="primaryTonalButton">
                        Upload
                    </Button>
                </DialogActions>

            </Dialog>
            <CustomDialog
                open={openSuccess}
                onClose={closeDialog}
                success={success}
                error={error}
                Content={success ? "Imported  Successfully" : "Failed To Import"}
            />
        </>

    );
}

export default JobLedgerList;