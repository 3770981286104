/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 20-Nov-2023
* @author      : vijaykumar.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing of Bid added to Cart
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |    DATE    |    AUTHOR    |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*   1444         | 27-03-2024 | shiyamKumar  |   Implement Success or Error Dialog box and Resolve 
*   2174         |  12/06/24  | Vanitha      |  Pagination implementation for Resource Management
*   2399         | 06-08-2024 | shiyam kumar | made changes to fix pagination issue
*
*
*/

import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination,
    TableRow, TableSortLabel, Typography, Paper, Checkbox, IconButton
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputAdornment, SelectChangeEvent, Snackbar, TextField, useMediaQuery } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SearchIcon from "@mui/icons-material/Search";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import './ResourceManagement.scss'
import { useNavigate } from 'react-router-dom';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { addResourceSchedule, editResources, getResources, getResourcesCount, searchResources } from '../../services/ResourceService';
import { DateCalendar, LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { getdisabledDatesByResourceId } from '../../services/ProjectService';
import CustomDialog from '../../pages/Dialog';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useHandleUnauthorized } from '../../hooks/UserSession';




interface Data {
    id: number;
    ResourceName: string;
    Status: string;
    cost: number;
    ResourceType: string;
    // Availability: any;
}

function createData(
    id: number,
    ResourceName: string,
    Status: string,
    cost: number,
    ResourceType: string,
    // Availability: any,
): Data {
    return {
        id,
        ResourceName,
        Status,
        cost,
        ResourceType,
        // Availability,
    };
}


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'ResourceName',
        numeric: false,
        disablePadding: true,
        label: 'Resource Name',
    },
    {
        id: 'Status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'cost',
        numeric: true,
        disablePadding: false,
        label: 'cost',
    },
    {
        id: 'ResourceType',
        numeric: true,
        disablePadding: false,
        label: 'ResourceType',
    },
    // {
    //     id: 'Availability',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Availability',
    // },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

       
    return (
        <TableHead className='materialTableHead'>
            <TableRow className='tableCustomerRows tableHead'>
                <TableCell padding="checkbox">
                    {/* <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    /> */}
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell className='tableCustomerRows '
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
}


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


const ResourceList: React.FC = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('ResourceName');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [resourceData, setResourceData] = React.useState([] as any);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    // const [openToast, setOpenToast] = React.useState(false);
    const [searchValue, setSearchValue] = React.useState<any>("");
    const [searchParam, setSearchParam] = React.useState<any>([]);
    const [datePickerOpen, setDatePickerOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(dayjs(new Date()));
    const [selectedResource, SetSelectedResource] = React.useState({});
    // const [toastMessage, setToastMessage] = React.useState({
    //     message: '',
    //     security: ''
    // });
    const blockedDates = React.useRef<any>({ disableDates: [] });
    const [openDialog, setOpenDialog] = React.useState(false);
    const [updateSuccess, setUpdateSuccess] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const rows = [];
    const [updatedResourceData, setUpdatedResourceData] = React.useState<any>([]);
    const navigate = useNavigate();
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [data, setData] = React.useState<any>([]);
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [noChange, setNoChange] = React.useState(false);
    const [count,setCount]=React.useState(Number);
    const { handleUnauthorized } = useHandleUnauthorized();

    const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

        setNoChange(false)

    }
    React.useEffect(() => {
        getResources([]);

    }, [page,rowsPerPage]);

    const inputchange = (e: any) => {
        setSearchValue(e.target.value);
        let params = [{
            key: "name",
            value: e.target.value,
            operation: "cn",
            logicalOp: "or"
        }, {
            key: "designation",
            value: e.target.value,
            operation: "cn",
            logicalOp: "or"
        }];
        setSearchParam(params);
        if(params.length <= 2){
            getResources(params);   
        }
    };

    const getResources = async (searchParam) => {
        let searchCriteriaList = searchParam
        const resourceData = (searchParam.length != 0 ? { searchCriteriaList } : {})
        const res = await getResourcesCount(accessToken);     
        handleUnauthorized(res);  
            setCount(res.data)
        let result = await searchResources(accessToken, resourceData,page,rowsPerPage);
        handleUnauthorized(result);  
        setResourceData(result.data);
    }

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n: { id: any; }) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleCalendar = async () => {
        setOpenDialogs(true);
        let result = await addResourceSchedule(selectedResource, selectedDate.format('YYYY-MM-DDTHH:mm:ss[Z]'), accessToken);
        if (result.status === 200 || result.status === 201) {
            setCreateSuccess(true);
        }
        else {
            setCreateError(true);
        }
        setSelectedDate(dayjs(new Date()));
        //setDatePickerOpen(false);
        //handleClose();
    }

    const customerForm = () => {
        navigate("/customerregistration"); // Navigate to the "/task" route
    };

    const updateResource = async () => {
        setOpenDialog(true);
        if (updatedResourceData.length !== 0) {
            let result = await editResources(updatedResourceData, accessToken);
            if (result.status === 200 || result.status === 201) {
                // setOpenToast(true);
                // setToastMessage({ ...toastMessage, message: "updated Successfully", security: "success" });
                setUpdateSuccess(true);
                getResources([]);
                setUpdatedResourceData([]);
            }
            else {
                // setOpenToast(true);
                // setToastMessage({ ...toastMessage, message: "Not Updated", security: "error" });
                setUpdateError(true);
            }
        }
        else {
            // setOpenToast(true);
            // setToastMessage({ ...toastMessage, message: "No Change", security: "Error" });
            
            setNoChange(true);
        }

    }

    const onEdit = (e: any, row: any) => {
        row.cost = e.target.value;
        let newArr = [...resourceData];
        let index = newArr.findIndex(obj => obj.id === row.id);
        newArr[index] = row;
        setResourceData(newArr);
        let updatedIndex = updatedResourceData.findIndex(obj => obj.id === row.id);
        if (updatedIndex === -1) {
            updatedResourceData.push(row);
        }
        else {
            updatedResourceData[updatedIndex] = row;
        }
    }

    const bookLeave = async (row) => {
        //const result = await addResourceSchedule(row, new Date(), accessToken);
        row.createdAt = new Date();
        row.updatedAt = new Date();
        SetSelectedResource(row);
        setDatePickerOpen(true);
        const result = await getdisabledDatesByResourceId(row.id, accessToken);
        if (result.status == 200) {
            if (result.status == 200) {
                var dates = blockedDates.current.disableDates;
                if (result.data.length != 0 && result.data != '') {
                    result.data.map(dateString => {
                        if (!blockedDates.current.disableDates.includes(dayjs(dateString).format("YYYY-MM-DD"))) {
                            blockedDates.current.disableDates.push(dayjs(dateString).format("YYYY-MM-DD"))
                        }
                    })
                    //setDisabledDates(dates)
                    //blockedDates.current.dis = dates
                }
            }
        }
    }

    // const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }

    //     setOpenToast(false);
    // };

    const   handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const [trade, setTrade] = React.useState("");
    const [grade, setGrade] = React.useState("");
    const handleChangeTrade = (event: SelectChangeEvent) => {
        setTrade(event.target.value);
    };
    const handleChangeGrade = (event: SelectChangeEvent) => {
        setGrade(event.target.value);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const onHandleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setSelectedDate(dayjs(new Date()));
        setDatePickerOpen(false);
        setOpenDialogs(false);
    };

    const SearchBar = () => (

        <>
            {/* <div className='px-3 py-3'>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={{ xs: 1, sm: 2, md: 3 }}
            >
                <Grid item xs={12}>
                    <Typography className='filterHeader'>Search Filters</Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-select-small-label">Trade</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={trade}
                            label="Trade"
                            onChange={handleChangeTrade}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>Roofing</MenuItem>
                            <MenuItem value={20}>Siding</MenuItem>
                            <MenuItem value={30}>Guttering</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-select-small-label">Grade</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={grade}
                            label="Grade"
                            onChange={handleChangeTrade}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>Roofing</MenuItem>
                            <MenuItem value={20}>Siding</MenuItem>
                            <MenuItem value={30}>Guttering</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-select-small-label">Trade</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={trade}
                            label="Trade"
                            onChange={handleChangeTrade}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>Roofing</MenuItem>
                            <MenuItem value={20}>Siding</MenuItem>
                            <MenuItem value={30}>Guttering</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </div> */}
            {/* <Grid item xs={12}><Divider></Divider></Grid> */}
            <div className='px-3 py-3'>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={{ xs: 1, sm: 2, md: 3 }}
                >
                    <Grid item xs={3}>
                        <Button variant="contained" size="medium" startIcon={<FileUploadOutlinedIcon />}>
                            Export
                        </Button>
                    </Grid>
                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <TextField
                            placeholder="Search by name"
                            size='small'
                            name="searchValue"
                            value={searchValue}
                            onChange={(e: any) => {setSelectedDate(dayjs(new Date(e.$d))) }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Typography pl={2}>
                            {/* <Button variant="contained" size="medium" onClick={customerForm} startIcon={<AddOutlinedIcon fontSize='small' />} sx={{ padding: 1 }}>
                                Add New Resource
                            </Button> */}
                        </Typography>
                        <Typography pl={2}>
                            <Button variant="contained" size="medium" onClick={updateResource} sx={{ padding: 1 }}>
                                Update
                            </Button>

                        </Typography>
                    </Grid>
                </Grid>
            </div></>
    );

    const disabledDates = data && data.data
        ? data.data.map(dateString => dayjs(dateString).format("YYYY-MM-DD"))
        : [];

    const isDateDisabled = (date: Dayjs) => {
        const today = dayjs();  // Get the current date
        // Disable dates up to and including today
        if (date.isBefore(today, 'day') || date.isSame(today, 'day')) {
            return true;
        }
        // Additional disabling logic (if needed)
        if (blockedDates.current.disableDates.includes(date.format("YYYY-MM-DD"))) {
            return true;
        }
        return date.day() === 6 || date.day() === 0;
    };


    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={datePickerOpen}
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} >
                            <Typography className='cartheader'>Book Leave</Typography>
                        </Box>
                    </Box>
                </DialogTitle>
                <LocalizationProvider dateAdapter={AdapterDayjs}>

                    <DateCalendar
                        value={selectedDate}
                        onChange={(e: any) => { setSelectedDate(dayjs(new Date(e.$d))) }}
                        shouldDisableDate={isDateDisabled} />

                </LocalizationProvider>
                <DialogActions>
                    <Button onClick={() => handleClose()} color="error">Close</Button>
                    <Button onClick={() => handleCalendar()} color="primary">Book now</Button>
                    <CustomDialog
                        open={openDialogs}
                        onClose={() => handleClose()}
                        success={createSuccess}
                        error={createError}
                        Content={createSuccess ? "Booked Successfully" : " Error Booked Created "} />
                </DialogActions>
            </Dialog>
            {/* <Snackbar open={openToast} autoHideDuration={2000} onClose={handleToastClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ paddingTop: 6 }}>
                <Alert onClose={handleToastClose} security={toastMessage.security} sx={{ width: '100%' }}>
                    {toastMessage.message}
                </Alert>
            </Snackbar> */}
            <Grid container  rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={10}>
                    <Box sx={{ width: '100%', }}>
                        <Paper className='POCard' sx={{ width: '100%', mb: 2 }}>
                            <div className='px-3 py-3'>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={{ xs: 1, sm: 2, md: 3 }}
                                >
                                    <Grid item xs={3}>
                                        {/* <Button variant="contained" size="medium" startIcon={<FileUploadOutlinedIcon />}>
                                            Export
                                        </Button> */}
                                    </Grid>
                                    <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <TextField
                                            placeholder="Search by name"
                                            size='small'
                                            name="searchValue"
                                            value={searchValue}
                                            onChange={(e) => inputchange(e)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => getResources(searchParam)}>
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {/* <Typography pl={2}>
                                            <Button variant="contained" size="medium" onClick={customerForm} startIcon={<AddOutlinedIcon fontSize='small' />} sx={{ padding: 1 }}>
                                                Add New Resource
                                            </Button>
                                        </Typography> */}
                                        <Typography pl={2}>
                                            <Button variant="contained" size="medium" onClick={updateResource} sx={{ padding: 1 }}>
                                                Update
                                            </Button>
                                            <CustomDialog
                                                open={openDialog}
                                                onClose={() => setOpenDialog(false)}
                                                success={updateSuccess}
                                                error={updateError}
                                                Content={updateSuccess ? "Updated Successfully" : "Not Updated Successfully  "}
                                            />
                                             <Dialog open={noChange} >
                <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                        
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent >
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
                    </DialogActions>
                </Box>
            </Dialog>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                            <TableContainer >
                                <Table
                                    sx={{ minWidth: 750, border: 'none', }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />
                                    <TableBody>
                                        {resourceData.map((row: any, index: any) => {
                                            // const isItemSelected = isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                // hover
                                                // onClick={(event) => handleClick(event, row.id)}
                                                // role="checkbox"
                                                // aria-checked={isItemSelected}
                                                // tabIndex={-1}
                                                // key={row.id}
                                                // selected={isItemSelected}
                                                // sx={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell padding="checkbox" size='small'>
                                                        {/* <CustomCheckbox
                                                            className='check'
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        /> */}
                                                    </TableCell>
                                                    <TableCell
                                                        className='tableCustomerRow'
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        size='small'
                                                    >
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell size='small'>{row.status}</TableCell>
                                                    <TableCell size='small'>
                                                        <TextField

                                                            id="outlined-size-small"
                                                            defaultValue="Small"
                                                            size="small"
                                                            value={row.cost}
                                                            onChange={(e) => onEdit(e, row)}
                                                        />
                                                    </TableCell>
                                                    <TableCell size='small'>{row.designation}</TableCell>
                                                    {/* <TableCell size='small'><Button variant="contained" size='small' onClick={() => bookLeave(row)}>Book Leave</Button></TableCell> */}
                                                    {/* <TableCell size='small'>
                                                        <IconButton
                                                            aria-label="more"
                                                            id="long-button"
                                                            aria-controls={open ? 'long-menu' : undefined}
                                                            aria-expanded={open ? 'true' : undefined}
                                                            aria-haspopup="true"
                                                            onClick={onHandleClick}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            id="long-menu"
                                                            MenuListProps={{
                                                                'aria-labelledby': 'long-button',
                                                            }}
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={handleClose}
                                                        // PaperProps={{
                                                        //     style: {
                                                        //         maxHeight: ITEM_HEIGHT * 4.5,
                                                        //         width: '10ch',
                                                        //     },
                                                        // }}
                                                        >
                                                            <MenuItem>
                                                                <VisibilityOutlinedIcon fontSize='small' />
                                                                View
                                                            </MenuItem>
                                                            <MenuItem>
                                                                <ModeEditOutlinedIcon fontSize='small' />Edit
                                                            </MenuItem>
                                                            <MenuItem>
                                                                <DeleteOutlineOutlinedIcon fontSize='small' />
                                                                Delete
                                                            </MenuItem>

                                                        </Menu>
                                                    </TableCell> */}
                                                </TableRow>
                                            );
                                        })}
                                        {/* {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )} */}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Grid container spacing={2}>
                                <Grid item xs={6} >
                                    {/* <FormControlLabel sx={{ padding: theme.spacing(1) }}
                                        control={<Switch checked={dense} onChange={handleChangeDense} />}
                                        label="Dense padding"
                                    /> */}
                                </Grid>
                                <Grid item xs={6} className='px-2'>
                                    <TablePagination
                                        sx={{ border: 'none' }}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    ></TablePagination>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </>

    );
}




export default ResourceList;