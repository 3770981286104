/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : Trade set up 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*/


import * as React from 'react';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Checkbox, ListItemText, Divider, Stack, IconButton } from '@mui/material';
import { addTenantTrade, addTrade, getTrades, updateTrade } from '../../services/TradeConfigurationService';
import CustomDialog from '../Dialog';
import './TradeSetUp.scss';
import CloseIcon from '@mui/icons-material/Close';
import SelectAllTransferList from './TradeTransfer';
import { useHandleUnauthorized } from '../../hooks/UserSession';


const TradeSetUp: React.FC<any> = (props) => {

    const modalstyle1 = {
        width: 'lg', // Assuming 'sm' is a predefined width in your theme, otherwise set an appropriate width like '300px' or similar
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        // p: 3,
        maxHeight: '70vh', // Set the maximum height for scrolling
        overflowY: 'auto', // Enable vertical scrolling


    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const [tradeData, setTradeData] = React.useState<any>([]);

    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [trade, setTrade] = React.useState<any>();
    const [validated, setValidated] = React.useState<any>(false);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [updateSuccess, setUpdateSuccess] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const [addSuccess, setAddSuccess] = React.useState(false);
    const [addError, setAddError] = React.useState(false);
    const [noChange, setNoChange] = React.useState(false);

    const [trades, setTrades] = React.useState(["Roofing", "Siding", "Guttering"]);
    const codes = { "Roofing": "1101", "Siding": "1102", "Guttering": "1103" };
    const [materials, setMaterials] = React.useState(["Asphalt Shingles", "Vinyl Siding", "Aluminum"]);
    const [manufacturers, setManufacturers] = React.useState(["Owens Corning", "GAF", "Certain Teed", "Norandex", "James Hardie", "Smart Side", "ACM", "Berger", "US Aluminum Inc"]);
    const [data, setData] = React.useState<any>({
        name: undefined,
        // manufacturer: [],
        // materialType: undefined,
        description: undefined,
        code: undefined,
        type: undefined

    });
    const [leftTradeIds, setLeftTradeIds] = React.useState<number[]>([]);
    const [rightTradeIds, setRightTradeIds] = React.useState<number[]>([]);
    const { handleUnauthorized } = useHandleUnauthorized();
    const handleLeftChange = (left: number[]) => {
        setLeftTradeIds(left);
    };

    const handleRightChange = (right: number[]) => {
        setRightTradeIds(right);
        console.log(right)
    };
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);


    const handleClose = () => {
        props.function();
        // setData({
        //     name: undefined,
        //     // manufacturer: [],
        //     // materialType: undefined,
        //     description: undefined,
        //     code: undefined,
        //     type: undefined
        // });
        // props.setTradeName(data.name)
        setValidated(false);
    };
    const getTradeList = async () => {
        let tradenames: any = [];
        let result = await getTrades(accessToken);
        handleUnauthorized(result);
        setTradeData(result.data);
        ////console.log(result.data)

        result?.data?.map((data) => {
            tradenames.push(data?.name)
        })
        // setOpenItem(tradenames[tradenames.length - 1]);
        // setTradeNames(Array.from(new Set(tradenames)));
        // const response = await getTemplates(accessToken);
        // setTemplateList(response.data);
    }
    const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

        setNoChange(false)

    }

    const tradehandleChange = (event: any) => {
        let costCode = codes[event.target.value];
        //console.log(event)
        if (event.target.name === "type") {
            Object.assign(data, { type: event.target.value, code: costCode });
            //setData({ ...data, name: event.target.value, code: costCode});
        }
        // if (event.target.name === "manufacturer") {
        //     setData({ ...data, [event.target.name]: event.target.value });
        // }
        else {
            setData({ ...data, [event.target.name]: event.target.value });
        }
    };
    React.useEffect(() => {
        getTradeList();

    }, []);
    const handleChange = (event: any) => {
        setData({ ...data, [event.target.name]: event.target.value });
    };
    const handleAddTenantTrade = async () => {
        console.log("Type of rightTradeIds:", typeof rightTradeIds);
        console.log("Is rightTradeIds an array?", Array.isArray(rightTradeIds));
        console.log("Contents of rightTradeIds:", rightTradeIds);

        // Filter out non-number elements using setRightTradeIds
        setRightTradeIds((prev) => prev.filter(id => typeof id === 'number'));

        if (rightTradeIds.length > 0 || rightTradeIds.length === 0) {
            setOpenAddDialog(true);
            const result = await addTenantTrade(rightTradeIds, accessToken);
            console.log("addTenantTrade", result);

            if ((result.status === 200 || result.status === 201) && result.data === true) {
                setAddSuccess(true);
            } else {
                setAddError(true);
            }
        } else {
            setValidated(true);
        }
    };

    // const handleUpdateTrade = async () => {
    //     setOpenUpdateDialog(true);
    //     const result = await updateTrade(props.selected.id, data, accessToken);
    //     if (result.status === 200 || result.status === 201) {
    //         setUpdateSuccess(true);
    //     } else {
    //         setUpdateError(true);
    //         setNoChange(true);
    //     }
    // }

    const handleUpdateTrade = async () => {
        // if (props.selected.description === data.description || props.selected.name === data.name  ) {
        //     setNoChange(true);
        //     setOpenUpdateDialog(false);
        // } else {
        const result = await updateTrade(props.selected.id, data, accessToken);
        setUpdateSuccess(true);
        setOpenUpdateDialog(true);
        // }
    };

    const closeDialog = () => {
        //closeTemplate();
        handleClose();
        setValidated(false);
        setOpenAddDialog(false);
        setAddSuccess(false);
        setAddError(false);
        setOpenUpdateDialog(false);
        setUpdateSuccess(false);
        setUpdateError(false);
        props.handleShowQuestionTemplate();
if(rightTradeIds.length === 0){
    window.location.reload();
}

    }

    React.useEffect(() => {
        // setData({
        //     name: props.selected?.name,
        //     // manufacturer: props.selected?.manufacturer === undefined ? [] : props.selected?.manufacturer,
        //     // materialType: props.selected?.materialType,
        //     description: props.selected?.description,
        //     code: props.selected?.code,
        //     type: props.selected?.type
        // });
        // //let selectedtrade = trades.filter(data => data.name === props.selected?.name);
        // setTrade(props.selected?.type);
    }, []);

    return (
        <>

            <Modal
                open={props.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalstyle1}>
                    {/* Modal Header */}
                    <Typography
                        id="modal-modal-title"
                        variant="h4"
                        component="h2"
                        sx={{
                            backgroundColor: '#3c5bfa',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '8px 16px',
                        }}
                    >
                        <Typography
                            className='tradeSetUp'
                            sx={{ color: '#fff' }}
                        >
                            Trade SetUp
                        </Typography>

                        <IconButton onClick={handleClose} sx={{ color: '#fff' }}>
                            <CloseIcon />
                        </IconButton>
                    </Typography>

                    <Divider />

                    {/* Modal Content */}
                    <Grid
                        container
                        sx={{ pt: 4, pl: 0, mt: 1 }}
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                    >
                        {/* Your form elements, inputs, and other content here */}
                        <Grid item xs={12} m={3} >
                            <SelectAllTransferList
                                onLeftChange={handleLeftChange}
                                onRightChange={handleRightChange}
                            />
                        </Grid>
                    </Grid>

                    {/* Modal Footer */}
                    <Divider sx={{ mt: 2 }} />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            p: 2,

                        }}
                        className="modalFooter"
                    >
                        {/* <Button
                        sx={{ marginRight: 2 }}
                        variant="contained"
                        size="medium"
                        color="error"
                        onClick={handleClose}
                    >
                        Close
                    </Button> */}
                        {props.selected === undefined ? (
                            <Button
                                variant="contained"
                                size="medium"
                                onClick={handleAddTenantTrade}
                            >
                                Save
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                size="medium"
                                onClick={handleUpdateTrade}
                            >
                                Update
                            </Button>
                        )}
                    </Box>
                </Box>
            </Modal>

            <CustomDialog
                open={openAddDialog}
                onClose={closeDialog}
                success={addSuccess}
                error={addError}
                Content={addSuccess ? <span>Trade Added Successfully<br /></span> :
                    "Failed To Add"} />
            <CustomDialog
                open={openUpdateDialog}
                onClose={closeDialog}
                success={updateSuccess}
                error={updateError}

                Content={updateSuccess ? "Trade Updated Successfully" : "Failed To Update"} />
            <Dialog open={noChange}>
                <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} />
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent>
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
                            No Value has been Changed
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
                    </DialogActions>
                </Box>
            </Dialog>


        </>

    );
}

export default TradeSetUp;