import { Box, Button, Card, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getAllVendors, getVendorsAddressByName } from '../../services/VendorService';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddTool from './AddTool';
import VendorRegister from '../vendor/VendorRegister';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useHandleUnauthorized } from '../../hooks/UserSession';


const ToolForm: React.FC<any> = (props) => {
    const { accessToken } = useOidcAccessToken();
    const [vendorList, setVendorList] = React.useState<any>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [menuIndex, setMenuIndex] = React.useState<any>();
    const openMenu = Boolean(anchorEl);
    const [openTool, setOpenTool] = React.useState<boolean>(false);
    const [editData, setEditData] = React.useState<any>();
    const [editIndexvalue, setEditIndexValue] = React.useState<any>();
    const filter = createFilterOptions<any>();
    const [openVendorForm, setOpenVendorForm] = React.useState(false);
    const [vendorAddressList, setVendorAddressList] = React.useState<any>();
    const [count, setCount] = React.useState<any>(0);
    const [addedData, setAddedData] = React.useState<any[]>([]); // Options for the Autocomplete dropdown
    const { handleUnauthorized } = useHandleUnauthorized();

    const handleChange = (event: any, value: any, index: any) => {
        props.changeTaskData(event, value, index)
    };

    const handleCloseTool = () => {
        setOpenTool(false);
    }

    const removeRow = (index: any) => {
        props.remove(index);
    }


    const getVendors = async () => {
        let result = await getAllVendors(accessToken);
        handleUnauthorized(result);
        setVendorList(result.data);
    }

    const handleClick = (event: any, index: any) => {
        setMenuIndex(index);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeEditToolData = () => {
        setEditData(undefined);
    }

    const editToolData = (data: any) => {
        props.edit(editIndexvalue, data);
    }


    const editRow = (index: any, row: any) => {
        setEditIndexValue(index);
        setEditData(row);
        setAnchorEl(null);
        setAddedData(props?.task?.projectTools);
        setOpenTool(true);
    }

    const addNewTool = () => {
        if (props?.companyName === '' || props?.companyName === undefined || props?.address === undefined || props?.address === '') {
            // setAnchorEl(null);
            setOpenTool(false);
            setCount(count + 1);
        }
        else {
            setAddedData(props?.task?.projectTools)
            setAnchorEl(null);
            setOpenTool(true);
        }
    }

    const handleChangeAddress = (e: any) => {
        props.changeVendorAddress(e);
    }
    const handleChangeVendor = (e: any, value: any) => {
        props.changeVendorName(value?.companyName);
        if (value !== null) {
            getVendorAddressList(value?.companyName);
        }
    }

    const openVendorRegForm = () => {
        setOpenVendorForm(true);
    };

    const getVendorAddressList = async (vendorName: any) => {
        let result = await getVendorsAddressByName(vendorName, accessToken);
        handleUnauthorized(result);
        if (result.data !== undefined) {
            setVendorAddressList(result.data);
        }
    }
    React.useEffect(() => {
        // getToolNames();
        getVendors();
        if (props?.companyName !== undefined && (props?.companyName)?.length !== 0 && props?.companyName !== '' && props?.companyName !== null && props?.companyName !== "null") {
            getVendorAddressList(props?.companyName);
        }
    }, []);

    return (
        <>
            <Grid container spacing={2} mb={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth size="small">
                        <Autocomplete
                            value={props?.companyName}
                            onChange={(e, newValue) => {
                                if (typeof newValue === 'string') {
                                    handleChangeVendor(e, newValue);
                                } else if (newValue && newValue.inputValue) {
                                    openVendorRegForm();
                                } else {
                                    handleChangeVendor(e, newValue);
                                }
                            }}
                            // onChange={(e, newValue) => setVendorNameAndId(e, newValue,index)}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some((option) => inputValue === option?.companyName);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        inputValue,
                                        companyName: `Add Vendor`,
                                    });
                                }

                                return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="free-solo-with-text-demo"
                            options={vendorList}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option?.companyName;
                            }}

                            renderOption={(props, option) => <li {...props}>{option?.companyName}</li>}
                            // disabled={material?.name === '' || material?.grade === '' ? true : false}
                            freeSolo
                            renderInput={(params) => (
                                <TextField {...params} label="Vendor Name" size='small'
                                    error={(props?.companyName === '' && count !== 0)}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label"
                        // error={(material?.address === '' && count !== 0)} 
                        >Vendor Address</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={props?.address}
                            label="Vendor Address"
                            name="address"
                            onChange={(e) => handleChangeAddress(e)}
                            required
                            disabled={props?.companyName === '' || props?.companyName === undefined ? true : false}
                            error={(props?.address === '' && count !== 0)}
                        >
                            {vendorAddressList?.map((vendor) => (
                                <MenuItem value={vendor?.address}>{vendor?.address}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container className='alignCenter'>
                <Grid item xs={12}>

                    <Card variant='outlined' className='taskcard' sx={{ padding: 1, marginBottom: 1, height: '31vh', overflow: 'auto' }}>
                        {props?.task?.projectTools[0]?.companyName !== '' && props?.task?.projectTools[0]?.name !== '' &&
                            props?.task?.projectTools[0]?.quantity !== '' && props?.task?.projectTools[0]?.unit !== '' &&
                            props?.task?.projectTools[0]?.totalCost !== '' && props?.task?.projectTools[0]?.unitCost !== ''
                            && props?.task?.projectTools[0]?.vendorToolMapId !== '' ?
                            <>
                                {props?.task?.projectTools?.map((tool: any, index: any) =>
                                    <Card variant='outlined' className='taskcard' sx={{ padding: 1, marginBottom: 1, backgroundColor: '#F1F3FF' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Tool Name"
                                                        name="name"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={tool?.name}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Vendor Name"
                                                        name="companyName"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={tool?.companyName}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Quanitity"
                                                        name="quantity"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={tool?.quantity}
                                                        type="number" />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Unit"
                                                        name="unit"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={tool?.unit}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Sub Total"
                                                        name="subTotal"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={tool?.totalCost}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton
                                                    id="demo-positioned-button"
                                                    aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openMenu ? 'true' : undefined}
                                                    onClick={(e) => handleClick(e, index)}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id="demo-positioned-menu"
                                                    aria-labelledby="demo-positioned-button"
                                                    anchorEl={anchorEl}
                                                    open={openMenu === true && menuIndex === index ? true : false}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <MenuItem
                                                        onClick={() => editRow(index, tool)}
                                                    >
                                                        <Stack direction={'row'} spacing={1}>
                                                            <EditIcon fontSize='small'
                                                            />
                                                            <Typography>Edit</Typography>
                                                        </Stack>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => removeRow(index)}>
                                                        <Stack direction={'row'} spacing={1}>
                                                            <DeleteIcon fontSize='small' />
                                                            <Typography>Delete</Typography>
                                                        </Stack>
                                                    </MenuItem>
                                                    <MenuItem
                                                        onClick={() => addNewTool()}
                                                    >
                                                        <Stack direction={'row'} spacing={1}>
                                                            <AddIcon fontSize='small' />
                                                            <Typography>Add</Typography>
                                                        </Stack>
                                                    </MenuItem>
                                                </Menu>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                )}
                            </>
                            :
                            <Box className="alignCenter">
                                <Button onClick={() => addNewTool()} size="large" startIcon={<AddIcon fontSize='small' />}>Add Tool</Button>
                            </Box>
                        }
                    </Card>
                </Grid>
                <Grid xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={2} className='alignEnd'>
                            <Typography>Total:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>{props?.total}</Typography>
                        </Grid>
                        <Grid item xs={1}>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AddTool open={openTool} addedData={addedData} onClose={handleCloseTool} trade={props?.projectData?.trade}
                changeTool={handleChange} editToolData={editData} changeEditTool={changeEditToolData}
                editAddedTool={editToolData} companyName={props?.companyName} address={props?.address} />

            <VendorRegister
                open={openVendorForm}
                onClose={() => setOpenVendorForm(false)}
            />
        </>
    );
}

export default ToolForm;